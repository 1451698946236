<script>
export default {
    name: "Mode",
    data() {
        return {
            mixLayout: "light-only",
        };
    },
    methods: {
        customizeMixLayout(val) {
            this.mixLayout = val;
            this.$store.dispatch("layout/setLayout", { class: val });
        },
    },
};
</script>

<template>
    <div class="mode">
        <vue-feather
            type="moon"
            v-show="mixLayout == 'light-only'"
            @click="customizeMixLayout('dark-only')"
        ></vue-feather>
        <vue-feather
            type="sun"
            v-show="mixLayout == 'dark-only'"
            @click="customizeMixLayout('light-only')"
        ></vue-feather>
    </div>
</template>
