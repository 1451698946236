<template>
    <div>
        <div style="display: flex; align-items: center;">
            <button @click.prevent="decrement" class="btn btn-outline-secondary" style="padding: 0.5rem;">-</button>
            <div class="px-1"></div>
            <input v-model.number="internalValue" style="width: 100%; text-align: center;" class="form-control" type="number" :min="min" :max="max" disabled/>
            <div class="px-1"></div>
            <button @click.prevent="increment" class="btn btn-outline-secondary" style="padding: 0.5rem;">+</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            internalValue: this.value
        };
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
        },
        internalValue(newValue) {
            if (newValue < this.min) {
                this.internalValue = this.min;
            } else if (newValue > this.max) {
                this.internalValue = this.max;
            }
            this.$emit('input', this.internalValue);
        }
    },
    methods: {
        increment() {
            if (this.internalValue < this.max) {
                this.internalValue++;
            }
        },
        decrement() {
            if (this.internalValue > this.min) {
                this.internalValue--;
            }
        }
    }
};
</script>
