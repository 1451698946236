<style scoped>
 .font-not-active {
    color: #c5c5c5;
 }

 .font-active {
    color: #04318C;
 }
</style>
<template>
    <Breadcrumbs title="Client Profile" main="Users" />
    <div class="container-fluid ">
        <div class="user-profile">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card hovercard text-center">
                        <div class="cardheader" style="background-size: cover;background-position: 10%;height: 370px;" 
                        :style="{backgroundImage: 'url('+ require('@/assets/images/other-images/bg-profile.png') +')'}"></div>
                        <div class="user-image">
                            <div class="avatar"><img id="profile-tour" alt="" src="../../../assets/images/user/7.jpg" data-intro="This is Profile image"></div>
                            <div class="icon-wrapper"><i id="update-profile-tour" class="icofont icofont-pencil-alt-5" data-intro="Change Profile image here"></i></div>
                            <div style="height:10px"></div>
                            <div class="card p-1" style="width:20%;position:relative;left:40%">
                                <a target="_blank" href="" style="font-weight: 700;color: #303030;">Ikram Ismail</a>
                                <div class="desc mt-2">Programmer</div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="row" data-intro="This is the your details" id="info-bar-tour">
                                <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0" style="top: 10px;">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="ttl-info " align="center" style=" cursor:pointer">
                                                <a
                                                    class="nav-link fs-5" style="font-weight: 500;" 
                                                    :class="{ 'font-active': activeTab === 'sales', 'font-not-active': activeTab !== 'sales' }"
                                                    @click.prevent="setActiveTab('sales')"
                                                    href="#"
                                                    role="tab" 
                                                >Sales</a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="ttl-info  ttl-sm-mb-0">
                                                <a
                                                    class="nav-link fs-5" style="font-weight: 500;"
                                                    :class="{ 'font-active': activeTab === 'about', 'font-not-active': activeTab !== 'about' }"
                                                    @click.prevent="setActiveTab('about')"
                                                    href="#"
                                                    role="tab"
                                                >About</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                                    <div class="user-designation">
                                        <div class="title"><a target="_blank" href="">Ikram Ismail</a></div>
                                        <div class="desc mt-2">Programmer</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2" style="top: 10px;">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="ttl-info  ttl-xs-mt">
                                                <a
                                                    class="nav-link fs-5" style="font-weight: 500;"
                                                    :class="{ 'font-active': activeTab === 'progress', 'font-not-active': activeTab !== 'progress' }"
                                                    @click.prevent="setActiveTab('progress')"
                                                    href="#"
                                                    role="tab"
                                                >Progress</a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="ttl-info  ttl-sm-mb-0">
                                                <a
                                                    class="nav-link fs-5" style="font-weight: 500;"
                                                    :class="{ 'font-active': activeTab === 'photos', 'font-not-active': activeTab !== 'photos' }"
                                                    @click.prevent="setActiveTab('photos')"
                                                    href="#"
                                                    role="tab"
                                                >Photos</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="sales" v-show="activeTab === 'sales'">
                </div>
                <div class="tab-pane row" id="about" v-show="activeTab === 'about'">
                    <div class="col-md-8 row">
                        <!-- About Card -->
                        <div class="col-md-6 order-sm-1 order-xl-0">
                            <div class="card" id="about-card">
                                <div class="p-3"><h5>About</h5></div>
                                <div style="border-bottom: 1px solid #C5C5C5"></div>
                                <div class="p-3">
                                    <div class="fs-6 fw-bold">Date Joined: <span class="fw-normal">01-01-2021</span></div>
                                    <div class="fs-6 fw-bold">Date Renew: <span class="fw-normal">01-01-2021</span></div>
                                    <div class="fs-6 fw-bold">Company Name: </div>
                                    <div class="fs-6 fw-normal">Swift Tech</div>
                                    <div class="p-2"></div>
                                    <div class="fs-6 fw-bold">Business Industry: </div>
                                    <div class="fs-6 fw-normal">Training & Services</div>
                                    <div class="p-2"></div>
                                    <div class="fs-6 fw-bold">Package: </div>
                                    <div class="fs-6 fw-normal">The Icon</div>
                                    <div class="p-2"></div>
                                    <div class="fs-6 fw-bold">Social Media: </div>
                                    <div class="p-2"></div>
                                    <div>
                                        <button class="btn btn-primary w-100">Facebook</button>
                                        <div class="p-1"></div>
                                        <button class="btn btn-success w-100">Twitter</button>
                                        <div class="p-1"></div>
                                        <button class="btn btn-danger w-100">Dribble</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Growth Chronology Card -->
                        <div class="col-md-6 order-sm-1 order-xl-0">
                            <div class="notification">
                                <div class="card height-equal">
                                    <div class="card-header card-no-border">
                                        <div class="header-top">
                                            <h5 class="m-0">Activity</h5>
                                            <div class="card-header-right-icon">
                                                <DropDown2 />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body pt-0">
                                        <ul>
                                            <li class="d-flex" v-for="item in activities" :key="item">
                                                <div :class="item.class"></div>
                                                <div class="w-100 ms-3">
                                                    <p class="d-flex justify-content-between mb-2">
                                                        <span class="date-content light-background">{{ item.date }}</span>
                                                    </p>
                                                    <h6>
                                                        {{ item.title }}<span class="dot-notification"></span>
                                                    </h6>
                                                    <p class="f-light">
                                                        {{ item.decription }}
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Success Stories Card -->
                        <div class="col-md-6 order-sm-1 order-xl-0">
                            <div class="card" id="about-card">
                                <div class="p-3"><h5>Success Stories</h5></div>
                                <div style="border-bottom: 1px solid #C5C5C5"></div>
                                <div class="row">
                                    <div cass="col-md-4 p-2"></div>
                                    <div cass="col-md-4 p-2"></div>
                                    <div cass="col-md-4 p-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- Activity Log Card -->
                        <div class="col-md-12 order-sm-1 order-xl-0">
                            <div class="card" id="about-card">
                                <div class="p-3"><h5>About</h5></div>
                                <div style="border-bottom: 1px solid #C5C5C5"></div>
                                <div class="p-3">
                                    <div>
                                        <h6>Today</h6>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/4.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/5.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/10.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6>25 January 2024</h6>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/3.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/5.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/1.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6>20 January 2024</h6>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/4.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/6.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                        <div class="media">
                                            <img class="img-50 rounded-circle m-r-15" src="../../../assets/images/user/1.jpg" alt="">
                                            <div class="media-body">
                                                <router-link to="/users/profile"><a href="">
                                                    <span class="f-w-600 d-block">Andew Jon</span></a>
                                                </router-link>
                                            <p>Commented on Shaun Park's <a href="javascript:void(0)">Photo</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="progress" v-show="activeTab === 'progress'">
                </div>
                <div class="tab-pane" id="photos" v-show="activeTab === 'photos'">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { activities } from "@/data/dashboard/default";
import { apexDashboard } from "@/data/comon";
import DropDown2 from "@/pages/dashboard/base/dropdown/DropDown2.vue";

export default {
    
    components: {
        DropDown2
    },
    data() {
        return {
            activeTab: 'about', // Set the initial active tab
            apexDashboard: apexDashboard,
            activities: activities,
        };
    },
    methods: {
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
    },
}
</script>
