<style scoped>
.font-not-active {
    color: #c5c5c5;
}
.font-active {
    color: #04318c;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
}
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}

.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.image-container:hover .overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.overlay:hover {
    opacity: 1;
}

.icon {
    color: #fff;
    margin: 0 8px;
    cursor: pointer;
}
</style>

<template>
    <div style="height: 15px"></div>

    <div class="container-fluid">
        <div class="user-profile">
            <div class="row">
                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <div class="col-sm-12">
                    <div class="card hovercard text-center">
                        <!-- banner -->
                        <div class="cardheader" style=" background-size: cover; background-position: 10%; height: 370px; " :style="{ backgroundImage: 'url(' + require('@/assets/images/other-images/profile-style-img3.png') + ')', }"></div>

                        <!-- image & detail user -->
                        <div class="user-image">
                            <div class="avatar">
                                <img id="profile-tour" alt="" :src="this.clientDetailsData.profile_pic" data-intro="This is Profile image"/>
                            </div>
                            <div class="icon-wrapper" data-bs-toggle="modal" data-bs-target="#editProfilePic"><i id="update-profile-tour" class="icofont icofont-pencil-alt-5" data-intro="Change Profile image here"></i></div>
                            <div style="height: 10px"></div>
                            <div class="card p-1" style="width: 20%;position: relative;left: 40%;">
                                <div style="font-weight: 700;padding: 3px;color: #303030;">{{ this.clientDetailsData.full_name }}</div>
                                <div class="desc mt-2" style="padding: 3px">{{ this.clientDetailsData.current_position }}</div>
                            </div>
                        </div>

                        <!-- tab dashboard, sales, progress, photo -->
                        <div class="info">
                            <div data-intro="This is the your details" id="info-bar-tour">
                                <div class="d-flex">
                                    <div style="width: 25%">
                                        <div class="ttl-info" align="center" style="cursor: pointer" >
                                            <a class="nav-link fs-5"  style="font-weight: 500" :class="{ 'font-active': activeTab === 'dashboard', 'font-not-active': activeTab !== 'dashboard', }" 
                                                @click.prevent=" setActiveTab('dashboard') " href="#"  role="tab" >
                                                Dashboard
                                            </a>
                                        </div>
                                    </div>

                                    <div style="width: 25%">
                                        <div class="ttl-info ttl-sm-mb-0">
                                            <a class="nav-link fs-5" style="font-weight: 500" :class="{ 'font-active': activeTab === 'sales', 'font-not-active': activeTab !== 'sales', }"
                                                @click.prevent=" setActiveTab('sales') " href="#" role="tab">
                                                Sales
                                            </a>
                                        </div>
                                    </div>

                                    <div style="width: 25%">
                                        <div class="ttl-info ttl-xs-mt">
                                            <a class="nav-link fs-5" style="font-weight: 500" :class="{'font-active': activeTab === 'progress', 'font-not-active': activeTab !== 'progress', }"
                                                @click.prevent=" setActiveTab('progress') " href="#" role="tab" >
                                                Progress
                                            </a>
                                        </div>
                                    </div>

                                    <div style="width: 25%">
                                        <div class="ttl-info ttl-sm-mb-0">
                                            <a class="nav-link fs-5" style="font-weight: 500" :class="{ 'font-active': activeTab === 'photos', 'font-not-active': activeTab !== 'photos', }"
                                                @click.prevent=" setActiveTab('photos') " href="#" role="tab">
                                                Photos
                                            </a>
                                        </div>
                                    </div>

                                    <div style="width: 16.66%">
                                        <div class="ttl-info ttl-xs-mt">
                                            <a class="nav-link fs-5" style="font-weight: 500" :class="{ 'font-active': activeTab === 'attendance', 'font-not-active': activeTab !== 'attendance', }" 
                                            @click.prevent=" setActiveTab('attendance')" href="#" role="tab">
                                                Attendance
                                            </a>
                                        </div>
                                    </div>

                                    <div style="width: 16.66%">
                                        <div class="ttl-info ttl-sm-mb-0">
                                            <a class="nav-link fs-5" style="font-weight: 500" :class="{ 'font-active': activeTab === 'btm', 'font-not-active': activeTab !== 'btm', }"
                                                @click.prevent=" setActiveTab('btm') " href="#" role="tab" >
                                                BTM
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- Profile CARD (appear after profile tab clicked) -->
                <div class="tab-pane row" id="dashboard" v-show="activeTab === 'dashboard'" >
                    <div class="col-lg-12">
                        <!-- CURRENT PLAN CARD -->
                        <div class="card">
                            <div class="row">
                                <div class="col-lg-5 col-md-12" id="subscription" >
                                    <img id="profile-tour" alt="" :src="this.packageImgBannerPath" style=" height: 100%; width: 100%; border-top-left-radius: 20px; border-bottom-left-radius: 20px; "/>
                                    <div class="position-absolute start-50 translate-middle text-white" style="top: 75%" align="center" >
                                        <div style=" font-size: 1.5rem; font-weight: 500; " >
                                            {{ this.clientDetailsData.current_package }}
                                        </div>
                                        <div style=" font-size: 1rem; font-weight: 300; " >
                                            Current Plan
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6">
                                    <div class="py-5 px-2">
                                        <h6>Previous package:</h6>
                                        <div style="font-size: 1rem" v-for="(item, index) in this.packageHistory" :key="index" >
                                            {{ (result = this.packageAvailable.find( (item1) => item1.id === item.current_package ))  ? result.pack_name : "-" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6" style="align-items: center" >
                                    <div class="p-5" align="left">
                                        <div style="font-size: 1rem">
                                            <span style="font-weight: 500" >Date Joined: </span>{{ this.clientFirstJoin }}
                                        </div>
                                        <div style="font-size: 1rem" v-for="(item, index) in this.packageHistory" :key="index">
                                            <span style="font-weight: 500">Date Renewed: </span>
                                            {{ convertDateFormat( item.date_subscribe ) + " - " + convertDateFormat(item.date_end) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END CURRENT PLAN CARD -->
                    </div>

                    <!--  -->
                    <!--  -->
                    <!--  -->
                    <!--  -->
                    <!-- SALES GRAPH -->
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-header card-no-border">
                                <div class="row">
                                    <div class="col-8">
                                        <h5 class="m-0">Sales this  year<!-- <span class="f-14 f-w-500 ms-1 f-light">(75% activity growth)</span> --></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div class="subscriber-chart-container">
                                    <apexchart height="455" type="line" :options="graphOptions" :series="GraphSeries"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END SALES GRAPH -->
                    <!-- CASH RESERVE AND ACHIEVEMENT -->
                    <div class="col-lg-3">
                        <!-- CASH RESERVE -->
                        <div class="card p-3" style="height: 43%; justify-content: center">
                            <div class="row d-flex" style=" align-content: flex-start !important; justify-content: center !important;">
                                <div class="col-6" align="center" style="width: 40%; height: 100%">
                                    <div style="width:60px; height: 60px; border-radius: 60px; background-color: #74c76c;">
                                        <div style="position: relative; top: 22%">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50%" fill="white" class="bi bi-cash-stack" viewBox="0 0 16 16"  >
                                                <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                                <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" style="width: 60%; height: 100%" >
                                    <div style="position: relative">
                                        <h4> RM {{ this.clientDetailsData.total_reserved !== "" ? this.clientDetailsData.total_reserved : "0.00" }} </h4>
                                        <div style=" font-size: 1rem; color: #52526c;"> Cash Reserve</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ACHIEVEMENT -->
                        <div class="card p-3" style="height: 43%; justify-content: center">
                            <div class="row d-flex" style=" align-content: flex-start !important; justify-content: center !important;" >
                                <div class="col-6" align="center" style="width: 40%; height: 100%" >
                                    <div style=" width: 60px; height: 60px; border-radius: 60px; background-color: #74c76c;">
                                        <div style="position: relative; top: 22%">
                                            <svg mlns="http://www.w3.org/2000/svg"  width="50%" fill="white" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" style="width: 60%; height: 100%" >
                                    <div style="position: relative">
                                        <h4> {{ this.clientDetailsData.total_achievement !== "" ? this.clientDetailsData.total_achievement : "0" }} </h4>
                                        <div style="font-size: 1rem; color: #52526c; ">
                                            Achievement
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END CASH RESERVE AND ACHIEVEMENT -->

                    <!-- PROFILE CARD -->
                    <div class="card col-lg-12">
                        <div style="height: 15px"></div>
                        <div class="p-4"><h5>Profile</h5></div>
                        <div style="border-bottom: 1px solid #c5c5c5"></div>
                        <div class="row p-4" style="font-size: 1rem">
                            <div class="col-lg-4 col-md-6">
                                <div class="py-2">
                                    <div style="font-weight: 500">Name:</div>
                                    <div> {{ this.clientDetailsData.full_name }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500"> IC Number: </div>
                                    <div> {{ this.clientDetailsData.ic_number }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500">Contact Number:</div>
                                    <div>{{ this.clientDetailsData.contact_number }}</div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500">Address:</div>
                                    <div class="address1"> {{ this.clientDetailsData.address_detail }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="py-2">
                                    <div style="font-weight: 500"> Company Name: </div>
                                    <div> {{ this.clientDetailsData.company_name }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500"> SSM Number: </div>
                                    <div> {{ this.clientDetailsData.roc_number }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500"> Brand Name: </div>
                                    <div> {{ this.clientDetailsData.brand_name }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500"> Business Industry: </div>
                                    <div> {{ this.clientDetailsData.niche_market }} </div>
                                </div>
                                <div class="py-2">
                                    <div style="font-weight: 500"> Position: </div>
                                    <div> {{ this.clientDetailsData.current_position }} </div>
                                </div>
                            </div>
                            <!-- social media -->
                            <div class="col-lg-4 col-md-12">
                                <div>
                                    <div style="font-weight: 500"> Social Media: </div>
                                    <div class="py-3"></div>
                                    <div class="social-media">
                                        <ul class="list-inline">
                                            <li class="list-inline-item" v-if=" this.clientDetailsData.company_facebook ">
                                                <a :href=" 'https://www.facebook.com/' + this.clientDetailsData.company_facebook " target="_blank" >
                                                    <i class="fa-brands fa-facebook" style="color:#1877F2"></i>
                                                </a>
                                            </li>
                                            <li v-else class="list-inline-item">
                                                <a style=" pointer-events: none;">
                                                    <i class="fa-brands fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" v-if=" this.clientDetailsData.company_x " >
                                                <a :href="'https://www.twitter.com/' + this.clientDetailsData.company_x" target="_blank">
                                                    <i class="fa-brands fa-twitter" style="color:#1DA1F2"></i>
                                                </a>
                                            </li>
                                            <li v-else class="list-inline-item">
                                                <a style=" pointer-events: none;">
                                                    <i class="fa-brands fa-twitter"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" v-if=" this.clientDetailsData.company_instagram">
                                                <a :href="'https://www.instagram.com/' + this.clientDetailsData.company_instagram" target="_blank">
                                                    <i class="fa-brands fa-instagram" style="
                                                        color:#FEFEFE;
                                                        background: #f09433; 
                                                        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
                                                        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
                                                        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
                                                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );"
                                                    >
                                                    </i>
                                                </a>
                                            </li>
                                            <li v-else class="list-inline-item">
                                                <a style=" pointer-events: none;">
                                                    <i class="fa-brands fa-square-instagram"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item" v-if="this.clientDetailsData.company_tiktok">
                                                <a :href="'https://www.tiktok.com/@' + this.clientDetailsData.company_tiktok" target="_blank">
                                                    <i class="fa-brands fa-tiktok" style="color:#303030"></i>
                                                </a>
                                            </li>
                                            <li v-else class="list-inline-item">
                                                <a style=" pointer-events: none;">
                                                    <i class="fa-brands fa-tiktok"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END PROFILE CARD -->
                    <!-- PAYMENT JOURNEY CARD -->
                    <div class="card col-lg-8" v-if="this.clientDetailsData.payment_type=='0'">
                        <div class="p-4">
                            <h5>Payment Journey</h5>
                            <div style="height: 10px"></div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="p-3" style="color: #069c23;border-radius:8px;background-color: rgba( 66, 227, 97, 0.3 );">
                                        <h5>Sum Payment</h5>
                                        <h6>RM {{ sumPayment }}</h6>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="p-3" style="color: #04318C; border-radius:8px;background-color: rgba( 50, 108, 227, 0.3 );">
                                        <h5>Balance</h5>
                                        <h6>RM {{ paymentBalance }}</h6>
                                    </div>
                                </div>
                                <div class="py-2"></div>
                                <div class="col-12">
                                    <h6>Record</h6>
                                    <div class="p-3 card" style="margin-bottom: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; " v-for="(item, index) in this.clientDetailsData.payment_record" :key="index">
                                        <div class="row">
                                            <div class="col-7">
                                                <div>{{ item.payment_date_display }}</div>
                                                <div>{{ item.payment_remarks }}</div>
                                            </div>
                                            <div class="col-3" style="position:relative;top:12px;" align="right"><h6>RM {{ parseFloat(item.payment_amount).toFixed(2) }}</h6></div>
                                            <div class="col-2 d-flex" style="position:relative;top:12px;">
                                                <div style="width: 40%;cursor: pointer;">
                                                    <svg mlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#7366FF" class="bi bi-pen-fill" @click="editPayment(item)" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#editPaymentJourney">
                                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                    </svg>
                                                </div>
                                                <div style=" width: 40%; cursor: pointer;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" @click="deletePaymentJourney(item.id)" width="20" height="20" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-3 card" @click="addPayment()" data-bs-toggle="modal" data-bs-target="#editPaymentJourney" style="background-color:rgba(	115, 102, 255, 0.3);cursor:pointer;margin-bottom: 10px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
                                        <div class="row">
                                            <div class="col-8 row" style="color:#6152ff;font-weight:450">
                                                <div class="col-1" align="center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#6152ff" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                    </svg>
                                                </div>
                                                <div class="col-10">
                                                    Add Payment Record
                                                </div>
                                            </div>
                                            <div class="col-3" style="position:relative;top:12px;" align="right"></div>
                                            <div class="col-1" style="position:relative;top:12px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END PAYMENT JORUNEY CARD -->
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- Sales CARD (appear after sales tab clicked) -->
                <div class="tab-pane row" id="sales" v-show="activeTab === 'sales'">
                    <div class="col-md-12">
                        <div class="card p-4">
                            <!-- TITLE & ADD YEAR BUTTON -->
                            <div style=" display: flex; justify-content: space-between; " >
                                <div class="d-flex" style="width: 60%;font-size: 1.2rem;font-weight: 500;align-items: center;">
                                    <span>Sales</span>
                                </div>
                                <div>
                                    <button data-bs-toggle="modal" data-bs-target="#addYearSales" class="p-3"
                                        style=" border-radius: 5px;background-color: #04318c;color: #fefefe;width: 200px;border: none;display: flex;justify-content: space-evenly;">
                                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%;position: relative;top: 2px;" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                        </svg>
                                        Add Year
                                    </button>
                                </div>
                            </div>

                            <div v-if="this.yearListData" style="height: 30px"></div>

                            <!-- YEAR CARD -->
                            <div v-if="this.yearListData">
                                <div class="card yearCard" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; " v-for="(item, index) in this.yearListArray" :key="index">
                                    <div class="p-4" style="display: flex">
                                        <div style="display: flex; width: 95%">
                                            <div class="p-2">
                                                <h3>{{ item.year }}</h3>
                                            </div>
                                            <div class="px-2">
                                                <div style=" font-size: 1.1rem;font-weight: 450;"> Total Sales ($)</div>
                                                <h4 style="font-weight: 400;color: #a8a8b5;" class="totalSalesCard">
                                                    {{ "RM" + this.findSalesRecord(item.year,"sales")}}
                                                </h4>
                                            </div>
                                            <div class="px-2">
                                                <div style="font-size: 1.1rem;font-weight: 450;">
                                                    Cash Reserve (CR)
                                                </div>
                                                <h4 style="font-weight: 400;color: #a8a8b5;" class="totalCashReserveCard">
                                                    {{ "RM" + this.findSalesRecord(item.year,"cash_reserve") }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div style="display: flex; width: 5%">
                                            <div style="width: 40%;cursor: pointer;">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="editYearPopup(item.year)" width="100%" fill="#7366FF"
                                                    class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#edityearSalesModal">
                                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                </svg>
                                            </div>
                                            <div style="width: 2%"></div>
                                            <div class="deleteYearCard" style=" width: 40%; cursor: pointer;" @click="deleteYearCard(item.year) ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="border-bottom: 1px solid #c5c5c5"></div>
                                    <div class="row p-4">
                                        <div class="col-lg-1 col-md-4 col-sm-6" v-for="(month, index) in item.monthData" :key="index">
                                            <h5>{{ index }}</h5>
                                            <div class="" style="font-size: 0.8rem" >
                                                <div style="font-weight: 450">$ (RM)</div>
                                                <div style="color: #a8a8b5">
                                                    <span class="salesValue" style="font-weight: 400" > {{ month[0].sales !== 0 ? month[0].sales : "-" }}</span>
                                                </div>
                                            </div>
                                            <div class="" style="font-size: 0.7rem" >
                                                <div style="font-weight: 450">CR (RM)</div>
                                                <div style="color: #a8a8b5">
                                                    <span class="cashReserveValue" style="font-weight: 400" >
                                                        {{ month[0].cash_reserve !== 0 ? month[0].cash_reserve : "-" }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else align="center" style="height: 300px">
                                <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                                <h6>No Data</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- Progress CARD (appear after progress tab clicked) -->
                <div class="tab-pane" id="progress" v-show="activeTab === 'progress'" >
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card">
                                <div class="p-3 d-flex" style="justify-content: space-between">
                                    <div><h5>Client Progress</h5></div>
                                    <div>
                                        <button @click="addProgressPopup" data-bs-toggle="modal" data-bs-target="#editClientProgress" class="p-2" style=" border-radius: 5px; background-color: #04318c; color: #fefefe; width: 200px; border: none; display: flex; justify-content: space-evenly;">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16" >
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                            </svg>
                                            Add Progress
                                        </button>
                                    </div>
                                </div>

                                <div style="border-bottom: 1px solid #c5c5c5"></div>
                                <div class="p-3 notification" v-if="this.clientProgressData">
                                    <ul v-for="(item, index) in this.clientProgressData" :key="index" >
                                        <li class="d-flex">
                                            <div class="activity-dot-primary"></div>
                                            <div class="w-100 ms-3">
                                                <div class="d-flex justify-content-between" >
                                                    <p class="d-flex justify-content-between mb-2" >
                                                        <span class="date-content light-background" >
                                                            {{ item.date_display }}
                                                        </span>
                                                    </p>
                                                    <div style="display: flex;width: 7%;">
                                                        <div style=" width: 50%; cursor: pointer;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" @click="editProgress(item)" width="100%" fill="#7366FF" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#editClientProgress">
                                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                            </svg>
                                                        </div>
                                                        <div style="width: 10%"></div>
                                                        <div style="width: 45%;cursor: pointer;" >
                                                            <svg @click="deleteProgress(item.progress_id)" xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-3">
                                                    <div> Speaker: {{ item.role + " " + item.speaker }} </div>
                                                </div>
                                                <div class="card" style=" box-shadow: rgba( 100, 100, 111, 0.1 ) 0px 7px 29px 0px; ">
                                                    <h5 class="p-3">Details</h5>
                                                    <div style="border-bottom: 1px solid #c5c5c5; "></div>
                                                    <div class="p-3" style="font-size: 1rem" >
                                                        <div class="py-1" style="font-weight: 450;">Current Issues</div>
                                                        <div class="py-1">{{item.current_issues}}</div>
                                                        <div class="py-1" style="font-weight: 450;">Solution</div>
                                                        <div class="py-1" v-html="this.formatText(item.solution)"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div v-else align="center" style="height: 300px">
                                    <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                                    <h6>No Data</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card">
                                <div
                                    class="p-3 d-flex"
                                    style="justify-content: space-between"
                                >
                                    <div><h5>Success Story</h5></div>
                                    <div>
                                        <button
                                            @click="addSuccessStory()"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editSuccessStoryModal"
                                            class="p-2"
                                            style="
                                                border-radius: 5px;
                                                background-color: #04318c;
                                                color: #fefefe;
                                                width: 200px;
                                                border: none;
                                                display: flex;
                                                justify-content: space-evenly;
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                style="width: 10%"
                                                fill="currentColor"
                                                class="bi bi-plus-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                                />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                                />
                                            </svg>
                                            Add Story
                                        </button>
                                    </div>
                                </div>
                                <div
                                    style="border-bottom: 1px solid #c5c5c5"
                                ></div>
                                <div class="p-3" v-if="this.clientSuccessData">
                                    <div v-for="(success, index) in clientSuccessData" :key="index">
                                        <div class="p-2 d-flex" style="justify-content: space-between;">
                                            <div>
                                                <h6>{{ success.title }}</h6>
                                            </div>
                                            <div style="display: flex; width: 7%" >
                                                <div style=" width: 50%; cursor: pointer; ">
                                                    <svg @click="editSuccessStory(success)" xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#editSuccessStoryModal">
                                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                    </svg>
                                                </div>
                                                <div style="width: 10%"></div>
                                                <div class="deleteYearCard" style="width: 45%;cursor: pointer;">
                                                    <svg @click="deleteSuccessStory(success.story_id)" xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="px-3">
                                            <div class="py-1" style="color: #52526c" >{{ success.date_display }} </div>
                                            <div class="py-1" style="color: #52526c" v-html="this.formatText(success.details)"></div>
                                        </div>
                                        <div class="py-2"></div>
                                    </div>
                                </div>
                                <div v-else align="center" style="height: 300px">
                                    <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                                    <h6>No Data</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- Photos CARD (appear after photos tab clicked) -->
                <div class="tab-pane" id="photos" v-show="activeTab === 'photos'" >
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="p-4 d-flex" style="justify-content: space-between">
                                    <div><h5>Photos</h5></div>
                                    <div>
                                        <button data-bs-toggle="modal" data-bs-target="#addPhotos" class="p-2" style="border-radius: 5px; background-color: #04318c; color: #fefefe; width: 200px; border: none; display: flex; justify-content: space-evenly; ">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16" >
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                            </svg>
                                            Add Photos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="this.successPhotosData">
                            <div class="col-lg-4 col-md-12" v-for="(item, index) in this.successPhotosData" :key="index">
                                <div class="card">
                                    <div class="d-flex p-4" style="justify-content: space-between" >
                                        <div><h5>{{ item.photo_category }}</h5></div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" @click="popupEditPhotos(item)" width="20" height="20" fill="#7366FF" class="bi bi-pen-fill"  viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#editPhotos">
                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div style="border-bottom: 1px solid #c5c5c5" ></div>
                                    <div class="row p-4">
                                        <div class="col-4 py-2" align="center" v-for="(item, index) in item.image" :key="index" >
                                            <div class="image-container">
                                                <img style="border-radius: 5px; width: 100%; height: 100%;" alt="" :src="item.image_path"/>
                                                <div class="overlay">
                                                    <svg @click="zoomPhoto(item.image_path)" data-bs-toggle="modal" data-bs-target="#zoomImage" xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-zoom-in" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"/>
                                                        <path d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"/>
                                                        <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- EMPTY DATA DISPLAY -->
                        <div v-else align="center">
                            <img style=" border-radius: 5px; width: 35%; height: 70%; " alt="" src="../../assets/images/no_data_image.png" />
                            <h6>No Data</h6>
                        </div>
                    </div>
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- Attendance CARD (appear after photos tab clicked) -->
                <div class="tab-pane" id="attendance" v-show="activeTab === 'attendance'" >
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="col-xxl-6 col-md-6 col-sm-6 box-col-6">
                                <div class="appointment">
                                    <div class="card">
                                        <div class="card-header card-no-border">
                                            <div class="header-top">
                                                <h5 class="m-0">Attendance</h5>
                                                <h6 :style="{ color: textColor }">Attendance Rate {{ countAttendances }} %</h6>
                                            </div>
                                        </div>
                                        <div class="card-body pt-0">
                                            <div class="appointment-table customer-table table-responsive p-2" style="max-height:450px">
                                                <table class="table table-bordernone" >
                                                    <tbody>
                                                        <tr v-for="(item, index) in this.attendanceData" :key="index">
                                                            <td>
                                                                <img class="img-fluid img-40 rounded-circle me-2" src="../../assets/images/user/7.jpg" alt="user"/>
                                                            </td>
                                                            <td class="img-content-box" >
                                                                <div class="f-w-500" style=" font-size: 0.8rem; " >
                                                                    Class {{ item.class_name }}
                                                                </div>
                                                                <span class="f-light"  style=" font-size: 0.7rem; " >
                                                                    {{ item.date_display }} 
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div :style="{ color: item.status === 'Absent' ? 'red' : '#54ba4a' }">
                                                                    {{ item.status }}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- BTM CARD (appear after photos tab clicked) -->
                <div class="tab-pane" id="btm" v-show="activeTab === 'btm'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="card p-4">
                                <div class="row">
                                    <div class="col-9 p-2"><h5>Business Transformation Matrix</h5></div>
                                    <div class="col-3" align="right">
                                        <button data-bs-toggle="modal" data-bs-target="#editBtm" class="p-2"
                                            style=" border-radius: 5px; background-color: #04318c; color: #fefefe; width: 200px; border: none; display: flex; justify-content: space-evenly;">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width: 10%" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16" >
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                            </svg>
                                            Set BTM Value
                                        </button>
                                    </div>
                                </div>
                                <div class="p-3" align="center" >
                                    <div id="radarchart" >
                                        <apexchart height="550" type="radar" :options="chartOptions" :series="series"></apexchart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP LIST -->
            <!-- POPUP EDIT PROFILE PIC -->
            <div class="modal fade modal-bookmark" id="editProfilePic" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Change Profile Picture</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body row">
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Upload Profile Photo</div>
                                <input type="file" ref="fileInputProfile" single @change="handleFileChange1" />
                            </div>
                            <!-- Display selected files with previews -->
                            <div v-if="selectedFiles.length > 0" class="py-2">
                                <div style="font-weight: 450; font-size: 1rem"> Image Preview:</div>
                                <div v-for="(file, index) in selectedFiles" :key="index" class="mb-2" align="center" style=" width: 100%; max-height: 30vh; overflow-y: scroll; overflow-x: hidden;">
                                    <img :src="file.preview" alt="Preview" style="max-width: 80%; max-height: 80%">
                                </div>
                                <div class="py-2"><button class="btn btn-sm btn-danger ms-2" @click="removeFile(index)">Delete Image</button></div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button class="btn" @click="saveProfilePicture()" data-bs-dismiss="modal" type="button" style=" width: 40%;color:#fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP EDIT PAYMENT JOURNEY -->
            <div class="modal fade modal-bookmark" id="editPaymentJourney" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">PAYMENT JOURNEY</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Payment Amount (RM)</div>
                                <input v-model="this.editPaymentJourney.payment_amount" class="form-control" type="text" placeholder="Ahmad"/>
                            </div>
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Date</div>
                                <input v-model="this.editPaymentJourney.payment_date" class="form-control" type="date" placeholder="2024" required/>
                            </div>
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Payment Remarks</div>
                                <input v-model="this.editPaymentJourney.payment_remarks" class="form-control" type="text" placeholder="Ahmad"/>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button class="btn" type="button" data-bs-dismiss="modal" @click="savePaymentJourney()" style=" width: 40%;color:#fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP EDIT YEAR SALES -->
            <div class="modal fade modal-bookmark" id="edityearSalesModal" tabindex="-1" role="dialog" aria-hidden="true" >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="edityearSalesModal"> Year <span>{{ this.editPopupYear }}</span> </h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div style=" max-height: 50vh; overflow-y: scroll; overflow-x: hidden; " >
                            <div class="modal-body row">
                                <div class="col-md-6 col-sm-12 p-2" v-for="(item, index) in this.editPopupYearData" :key="index">
                                    <h5> {{ item[0].month }} </h5>
                                    <div class="" style="font-size: 0.8rem">
                                        <div style="font-weight: 450">Total Sales</div>
                                        <input v-model="this.editPopupYearData[index][0].sales" class="form-control" id="total_sales" type="number" placeholder="100.00"/>
                                    </div>
                                    <div class="" style="font-size: 0.8rem">
                                        <div style="font-weight: 450">Cash Reserve</div>
                                        <input v-model="this.editPopupYearData[index][0].cash_reserve" class="form-control" id="cash_reserve" type="number" placeholder="50.00"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row p-2">
                            <div class="col-12" align="right">
                                <button data-bs-dismiss="modal" @click="saveEditYearSales()" class="btn" type="button" style=" width: 40%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important; ">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP ADD YEAR SALES -->
            <div class="modal fade modal-bookmark" id="addYearSales" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Year</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body row">
                            <div>
                                <div style="font-size: 1rem">
                                    <div style="font-weight: 450">Select Year</div>
                                    <select v-model="addYearOption" class="form-select form-control-primary" name="select">
                                        <option value="" disabled selected>Select one</option>
                                        <option v-for="year in yearListAvailable" :key="year" :value="year">{{ year }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <!-- Use :disabled to conditionally disable the button -->
                                <button @click="addYear()" data-bs-dismiss="modal" class="btn" type="button"
                                    style="width: 40%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;"
                                    :disabled="!addYearOption"> <!-- Disable button if addYearOption is falsy -->
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP CLIENT PROGRESS -->
            <div class="modal fade modal-bookmark" id="editClientProgress" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Client Progress</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div class="modal-body row">
                            <div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Role</div>
                                    <select v-model=" this.editPopupProgressData.role " class="form-select form-control-primary" name="select" >
                                        <option value="" disabled selected> Select one </option>
                                        <option value="Trainer">Trainer</option>
                                        <option value="Director"> Director </option>
                                        <option value="CR">CR</option>
                                        <option value="All">All</option>
                                    </select>
                                </div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Date</div>
                                    <input v-model=" this.editPopupProgressData.date" class="datepicker-here form-control digits" type="date" data-language="en" placeholder="Date"/>
                                </div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Speaker</div>
                                    <input v-model=" this.editPopupProgressData.speaker " class="form-control" type="text" placeholder="Pn Fatimah"/>
                                </div>
                                <div class="py-2" style="border-bottom: 1px solid #c5c5c5"></div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Current Issues</div>
                                    <input v-model=" this.editPopupProgressData.current_issues " class="form-control" type="text" placeholder="Insert Detail"/>
                                </div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Solution</div>
                                    <textarea v-model="this.editPopupProgressData.solution " class="form-control" ype="text" placeholder="Insert Detail" ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button
                                    @click="saveProgress()"
                                    class="btn"
                                    data-bs-dismiss="modal"
                                    type="button"
                                    style="
                                        width: 40%;
                                        color: #fefefe;
                                        background-color: #04318c !important;
                                        border-color: #04318c !important;
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP EDIT SUCCESSS STORY -->
            <div class="modal fade modal-bookmark" id="editSuccessStoryModal" tabindex="-1" role="dialog" aria-hidden="true" >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Success Story</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div class="modal-body row">
                            <div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Date</div>
                                    <input v-model="this.editPopupSuccessData.date" class="datepicker-here form-control digits" type="date" data-language="en" placeholder="Date"/>
                                </div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Title</div>
                                    <input v-model=" this.editPopupSuccessData.title " class="form-control" type="text" placeholder="Title" />
                                </div>
                                <div class="py-2" style="font-size: 1rem">
                                    <div style="font-weight: 450">Details</div>
                                    <textarea v-model=" this.editPopupSuccessData.details " class="form-control" placeholder="Details" ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button
                                    @click="saveSuccessStory()"
                                    data-bs-dismiss="modal"
                                    class="btn"
                                    type="button"
                                    style="
                                        width: 40%;
                                        color: #fefefe;
                                        background-color: #04318c !important;
                                        border-color: #04318c !important;
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP PHOTOS -->
            <div class="modal fade modal-bookmark" id="addPhotos" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Add Photos</h3>
                            <button
                                class="btn-close"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body row">
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Category</div>
                                <select class="form-select form-control-primary" name="select" @change="resetNewCategoryVal()" v-model="this.selectedCategory">
                                    <option value="" disabled selected>Select one</option>
                                    <option value="successStories">Success Stories</option>
                                    <option value="seminarGyb">Seminar GYB</option>
                                    <option value="personal">Personal</option>
                                    <option value="addCategory">Add Category</option>
                                </select>
                            </div>
                            <div class="py-2" style="font-size: 1rem" v-if="this.selectedCategory == 'addCategory'">
                                <div style="font-weight: 450">New Category</div>
                                <input v-model="this.newCategory" class="form-control" type="text" placeholder="Title"/>
                            </div>
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Upload Photo</div>
                                <div style="font-weight: 250;font-size:15px;color:#ff0033">* Recommended size 700px * 700px</div>
                                <input type="file" ref="fileInput" multiple @change="handleFileChange"/>
                            </div>
                            <!-- Display selected files with previews -->
                            <div v-if="selectedFiles.length > 0" class="py-2">
                                <div style="font-weight: 450; font-size: 1rem"> Image Preview: </div>
                                <div v-for="(file, index) in selectedFiles" :key="index" class="mb-2" align="center" style=" width: 100%;max-height:30vh;overflow-y:scroll;overflow-x: hidden;">
                                    <img :src="file.preview" alt="Preview" style="max-width: 80%; max-height: 80%"/>
                                </div>
                                <div class="py-2"><button class="btn btn-sm btn-danger ms-2" @click="removeFile(index)">Delete Image</button></div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button data-bs-dismiss="modal" class="btn" @click="addSuccessPhotos()" type="button" style=" width: 40%;color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP EDIT PHOTOS -->
            <div class="modal fade modal-bookmark" id="editPhotos" tabindex="-1" role="dialog" aria-hidden="true" >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Edit Photos</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div class="modal-body row">
                            <div class="py-2" style=" display: flex; font-size: 1rem; justify-content: space-between; ">
                                <div class="d-flex" style="align-content: space-between; "> 
                                    <div style="font-weight: 450;">Category: </div>
                                    <div class="px-2"></div>
                                    <div>{{ this.editSuccessPhotosData.photo_category }}</div>
                                </div>
                            </div>
                            <div id="editPhotos" class="row p-4" style="max-height: 40vh; overflow: auto" >
                                <div class="py-2 row" align="center">
                                    <div class="col-4 p-1" v-for="(item, index) in this.editSuccessPhotosData.image" :key="index">
                                        <div class="image-container">
                                            <img style=" border-radius: 5px; width: 100%; height: 100%; " alt="" :src="item.image_path"/>
                                            <div class="overlay">
                                                <svg @click="deletePhotos(item.image_id)" data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="100%" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="py-2" style="font-size: 1rem">
                                <div style="font-weight: 450">Upload Photo</div>
                                <input type="file" ref="fileInput" multiple @change="handleFileChange"/>
                            </div>
                            <!-- Display selected files with previews -->
                            <div v-if="selectedFiles.length > 0" class="py-2">
                                <div style="font-weight: 450; font-size: 1rem"> Image Preview: </div>
                                <div v-for="(file, index) in selectedFiles" :key="index" class="mb-2" align="center" style=" width: 100%;max-height:30vh;overflow-y:scroll;overflow-x: hidden;">
                                    <img :src="file.preview" alt="Preview" style="max-width: 80%; max-height: 80%"/>
                                </div>
                                <div class="py-2"><button class="btn btn-sm btn-danger ms-2" @click="removeFile(index)">Delete Image</button></div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button class="btn" data-bs-dismiss="modal" @click="addSuccessPhotos(this.editSuccessPhotosData.photo_category )" type="button" style=" width: 40%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP ZOOM SUCCESS PHOTO -->
            <div class="modal fade modal-bookmark" id="zoomImage" tabindex="-1" role="dialog" aria-hidden="true" >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-body row">
                            <div style="width:100%;height:80vh;">
                                <img :src="this.zoomSuccessPhoto" alt="Preview" style="width: 100%; height: 100%"/>
                            </div>
                        </div>
                        <div class="p-2" align="center">
                            <button class="btn" data-bs-dismiss="modal" type="button" style=" width: 60%;color:#fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!-- POPUP EDIT BTM -->
            <div class="modal fade modal-bookmark" id="editBtm" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">BTM VALUE</h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="py-1 row" style="font-size: 1rem">
                                <div class="col-6"></div>
                                <div class="col-4">
                                    <h6>Level</h6>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-2"></div>
                                <div class="row col-8" >
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Branding</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <LabeledInput :value="this.value1" @input="updatedValue1"/>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Paid Marketing</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value2" @input="updatedValue2"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Content Marketing</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value3" @input="updatedValue3"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Sales</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value4" @input="updatedValue4"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Customer Service</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value5" @input="updatedValue5"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Operation Process</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value6" @input="updatedValue6"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Human Resources</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value7" @input="updatedValue7"/>
                                        </div>
                                    </div>
                                    <div class="p-1"></div>
                                    <div class="col-6" style="font-weight: 450;position:relative;top:5px;" align="left">Account Finance</div>
                                    <div class="col-6" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center;">
                                            <LabeledInput :value="this.value8" @input="updatedValue8"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12" align="right">
                                <button @click="setBtmValue()" data-bs-dismiss="modal" class="btn" type="button" style=" width: 40%;color:#fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { activities } from "@/data/dashboard/default";
import { apexDashboard } from "@/data/comon";
import DropDown2 from "@/pages/dashboard/base/dropdown/DropDown2.vue";
import LabeledInput from '@/components/plusMinusInput.vue';
import {
    clientProfileDetail,
    clientProfileSales,
    uploadProfilePic,
    addPaymentJourney,
    editPaymentJourney,
    deletePaymentJourney,
    //
    //
    //
    //
    addYear,
    editYear,
    deleteYear,
    clientProgress,
    addProgress,
    editProgress,
    deleteProgress,
    successStory,
    addSuccessStory,
    editSuccessStory,
    deleteSuccessStory,
    successPhoto,
    addSuccessPhoto,
    deleteSuccessPhoto,
    attendanceRecord,
    btmValue,
    addBtmValue
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    name: "Client Profile",

    components: {
        DropDown2,
        LabeledInput,
    },
    
    data() {
        return {
            activeTab: "dashboard",
            //dashboard
            clientDetailsData: [],
            editPaymentJourney: [],
            totalPaymentRecord: null,
            sumPaymentRecord: null,
            balancePaymentRecord: null,
            packageImgBannerPath: require("@/assets/images/package/error400x800.png"),
            packageHistory: [],
            packageFirstJoin: [],
            clientFirstJoin: null,
            packageAvailable: [],
            graphData: null,
            // year
            addYearOption: "",
            yearListArray: null,
            yearListData: null,
            yearListConst: [ "2024","2023", "2022","2021","2020","2019","2018","2017","2016","2015","2014","2013","2012","2011","2010"],
            yearListAvailable: [],
            salesAndCashRecord: [],
            editPopupYearData: [],
            editPopupYear: null,
            //
            //
            //
            //
            apexDashboard: apexDashboard,
            activities: activities,

            // bar graph Sales & cash reserve Value
            graphOptions: {
                chart: {
                    height: 265,
                    type: "line",
                    toolbar: {
                        show: true,
                        tools: {
                            download: true, // Enable the download button
                            selection: false, // Disable the selection tool
                            zoom: false, // Disable the zoom tool
                            zoomin: false, // Enable the zoom-in tool
                            zoomout: false, // Enable the zoom-out tool
                            pan: false, // Disable the pan tool
                            reset: false, // Enable the reset button
                        },
                    },
                    dropShadow: {
                        enabled: true,
                        top: 8,
                        left: 0,
                        blur: 2,
                        color: ["#FFA941", "#7366FF"],
                        opacity: 0.1,
                    },
                },
                grid: {
                    show: true,
                    borderColor: "var(--chart-border)",
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                colors: ["#FFA941", "#7366FF"],
                stroke: {
                    width: 2,
                    curve: "smooth",
                    opacity: 1,
                },
                markers: {
                    discrete: [
                        {
                            seriesIndex: 1,
                            dataPointIndex: 4,
                            fillColor: "#7064F5",
                            strokeColor: "var(--white)",
                            size: 6,
                        },
                    ],
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    marker: {
                        width: 5,
                        height: 5,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: [ "Jan", "Feb", "Mac", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    tickAmount: 12,
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        style: {
                            colors: "var(--chart-text-color)",
                            fontSize: "12px",
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: 400,
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                fill: {
                    opacity: 1,
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        type: "horizontal",
                        shadeIntensity: 1,
                        opacityFrom: 0.95,
                        opacityTo: 1,
                        stops: [0, 90, 100],
                    },
                },
                yaxis: {
                    min: 0,
                    // max: 500,
                    tickAmount: 5,
                    labels: {
                        style: {
                            colors: "var(--chart-text-color)",
                            fontSize: "12px",
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: 400,
                        },
                    },
                },
                legend: {
                    show: true,
                },
                responsive: [
                    {
                        breakpoint: 1694,
                        options: {
                            chart: {
                                height: 240,
                            },
                        },
                    },
                    {
                        breakpoint: 1661,
                        options: {
                            chart: {
                                height: 265,
                            },
                        },
                    },
                    {
                        breakpoint: 1412,
                        options: {
                            chart: {
                                height: 240,
                            },
                        },
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 260,
                            },
                        },
                    },
                    {
                        breakpoint: 1040,
                        options: {
                            chart: {
                                height: 240,
                            },
                        },
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 255,
                            },
                        },
                    },
                ],
                title: {
                    text: 'Sales & Cash Reserve Over the Year',
                    align: 'center',
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Rubik, sans-serif',
                        fontWeight: 'bold',
                        color: 'var(--chart-text-color)'
                    }
                }
            },
            GraphSeries: [
                {
                    name: "sales",
                    type: "line",
                    // data: [12, 10, 25, 12, 30, 10, 55, 45, 60],
                },
                {
                    name: "cash reserve",
                    type: "line",
                    // data: [10, 15, 20, 18, 38, 25, 55, 35, 60],
                },
            ],
            //
            //
            //
            //
            //btm VALUES
            value1: 0,
            value2: 0,
            value3: 0,
            value4: 0,
            value5: 0,
            value6: 0,
            value7: 0,
            value8: 0,
            //radar chart
            series: [{
                name: 'Percentage',
                data: [0,0,0,0,0,0,0,0],
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'radar',
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    radar: {
                        size: 250,
                        polygons: {
                            strokeColor: '#303030',
                            fill: {
                                colors: ['#f8f8f8'],
                            }
                        }
                    }
                },
                title: {
                    text: ''
                },
                colors: ['#04318C'],
                markers: {
                    size: 5,
                    colors: ['#fff'],
                    strokeColor: '#04318C',
                    strokeWidth: 2,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                },
                xaxis: {
                    categories: [
                                    "Branding", 
                                    "Paid Marketing", 
                                    "Content Marketing", 
                                    "Sales", 
                                    "Customer Service", 
                                    "Operation Process", 
                                    "Human Resources",
                                    "Account Finance"
                                ],
                    labels: {
                        show: true,
                        style: {
                            colors: ['#762CA7', '#FE98BF', '#CC0001', '#FB940B', '#EBBE1D', '#01CC00', '#03C0C6', '#0000FE'],
                            fontSize: "14px",
                            fontFamily: 'Sans-serif',
                            fontWeight: '550'
                        }
                    }
                },
                yaxis: {
                    tickAmount: 10,
                    max: 5,
                    labels: {
                        formatter: function (val, i) {
                            if (i % 2 === 0) {
                                return val;
                            } else {
                                return '';
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    background: {
                        enabled: true,
                        borderRadius:2,
                    }
                },
            },
            //
            //
            //
            //
            formData: [],
            clientProgressData: null,
            zoomSuccessPhoto: null,
            successPhotosData: null,
            editSuccessPhotosData: [],
            selectedCategory: "",
            newCategory: null,
            editPopupProgressData: [],
            clientSuccessData: null,
            editPopupSuccessData: [],
            clientDetail: null,
            clientId: null,
            attendanceData: [],
            //
            //
            //
            //picture DATA
            profilePicture: [],
            selectedFiles: [],
        };
    },

    created() {
        this.clientId = this.$route.query.id;
        //dashboard
        this.loadClientDetails();
        //year
        this.loadSalesList();
        //
        //
        //
        //
        this.progressList();
        this.successStoryList();
        this.successPhotoList();
        this.loadBtmValue();
        this.loadAttendance();
        // this.updateGraphSeriesData();
    },

    computed: {
        countAttendances() {
            // Use array filter to count 'attend' occurrences in item.status
            const attendCount = this.attendanceData.filter(item => item.status === 'Attend').length;
            const attendRate=parseInt((attendCount/this.attendanceData.length)*100);

            return attendRate;
        },
        sumPayment() {
            let sumPayment = 0;
            this.clientDetailsData.payment_record.forEach((item) => {
                sumPayment += parseFloat(item.payment_amount);
            });
            
            this.sumPaymentRecord=sumPayment;
            //ADJUST FORMAT NUMBER DISPLAY FOR CURRENCY STYLE
            const formatter = new Intl.NumberFormat('en-NZ', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                trailingZeroDisplay: 'stripIfInteger'
            });

            return formatter.format(sumPayment);
        },
        paymentBalance() {
            let sumPayment = 0;
            let paymentBalance = 0;

            this.clientDetailsData.payment_record.forEach((item) => {
                sumPayment += parseFloat(item.payment_amount);
            });

            paymentBalance=parseFloat(this.clientDetailsData.package_amount)-parseFloat(sumPayment);

            //ADJUST FORMAT NUMBER DISPLAY FOR CURRENCY STYLE
            const formatter = new Intl.NumberFormat('en-NZ', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                trailingZeroDisplay: 'stripIfInteger'
            });

            return formatter.format(paymentBalance);
        },
        textColor() {
            // Determine text color based on attendance rate
            if (this.countAttendances < 40) {
                return 'red'; // Set text color to red for attendance rate < 40%
            } else if (this.countAttendances >= 40 && this.countAttendances < 80) {
                return 'yellow'; // Set text color to yellow for attendance rate >= 40% and < 80%
            } else {
                return 'green'; // Set text color to green for attendance rate >= 80%
            }
        },
        totalSales() {
            const totalSalesMap = {};
            this.yearListData.forEach((item) => {
                const total = item.monthSales.reduce(
                    (acc, month) => acc + month.sales, 0
                );
                totalSalesMap[item.year] = total;
            });
            return totalSalesMap;
        },
        totalCashReserve() {
            const totalCashReserveMap = {};
            this.yearListData.forEach((item) => {
                const total = item.monthSales.reduce(
                    (acc, month) => acc + month.cash_reserve, 0
                );
                totalCashReserveMap[item.year] = total;
            });
            return totalCashReserveMap;
        },
    },

    methods: {
        createYearObject(yearData) {
            const startYear = new Date().getFullYear(); // Starting year
            const endYear = 2010; // Ending year
            const yearObject = []; // Initialize an empty object to store the years
            // Loop through each year from startYear to endYear
            for (let year = startYear, i = 0; year >= endYear; year--) {
                if (yearData[i] != year) {
                    yearObject.push(year); // Store the year as a key-value pair in the object
                }
                i++;
            }

            return yearObject; // Return the populated year object
        },
        //RESET VALUE NEWCATEGORY
        resetNewCategoryVal() {
            if (this.selectedCategory != "addCategory") {
                this.newCategory = null;
            }
        },
        formatText(text) {
            return text.split('\n').map(item => `<p>${item.trim()}</p>`).join('');
        },

        //API CLIENT DETAILS TAB
        //dashboard
        async loadClientDetails() {
            const dataPass = {
                client_id: this.clientId,
            };

            try {
                await clientProfileDetail(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clientDetailsData = response.data.data;
                        this.totalPaymentRecord= response.data.data.payment_record.length;
                        this.packageHistory = JSON.parse(response.data.data.history_package);
                        this.packageImgBannerPath = this.clientDetailsData.package_banner_img;
                        this.packageAvailable = response.data.package;
                        const keys = Object.keys(this.packageHistory);
                        this.packageFirstJoin = keys.sort();

                        const dateSubscribe = this.packageHistory[this.packageFirstJoin[0]]["date_subscribe"];
                        const dateEnd = this.packageHistory[this.packageFirstJoin[0]]["date_end"];

                        this.clientFirstJoin = this.convertDateFormat(dateSubscribe) + " - " + this.convertDateFormat(dateEnd);

                        delete this.packageHistory[this.packageFirstJoin[0]];
                    } else {
                        this.notificationError("Something went wrong1!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong1.5!");
            }
        },
        editPayment(paymentDetail) {
            this.editPaymentJourney=paymentDetail;
        },
        addPayment() {
            this.editPaymentJourney= [];
        },
        async savePaymentJourney() {
            this.formData = [];
            this.formData = { ...this.editPaymentJourney };

            // Add clientId to formData
            this.formData.client_id = this.clientId;
            this.formData.payment_iteration = this.totalPaymentRecord+1;

            const dataPass = this.formData;
            if (this.editPaymentJourney.id == null) {
                try {
                    await addPaymentJourney(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.loadClientDetails();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await editPaymentJourney(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.successStoryList();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
        async deletePaymentJourney(paymentId) {
            const dataPass = { payment_id: paymentId };

            try {
                await deletePaymentJourney(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.loadClientDetails();
                        this.notificationSuccess("Data deleted!");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        //edit Profile pic
        handleFileChange1(event) {
            // Clear existing files
            this.profilePicture = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.profilePicture.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                        this.packageImgIcon = this.profilePicture[0].preview;
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },
        async saveProfilePicture() {
            this.formData = new FormData();
            this.formData.profile_pic = this.profilePicture;
            this.formData.client_id = this.clientId;

            try {
                await uploadProfilePic(this.formData).then((response) => {
                    if (response.data.message === "success") {
                        this.notificationSuccess("Profile Picture Updated!");
                        this.loadClientDetails();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        editYearPopup(year) {
            const arrayRecord = Object.keys(this.yearListData).map((key) => [
                key,
                this.yearListData[key],
            ]);

            const searchRecord = arrayRecord.find((arr) => arr[0] === year);
            this.editPopupYearData = searchRecord[1];
            this.editPopupYear = year;
        },

        //PROGRESS TAB
        addProgressPopup() {
            this.editPopupProgressData = [];
        },
        editProgress(progressDataEdit) {
            this.editPopupProgressData = progressDataEdit;
        },
        //API PROGRESS TAB
        async progressList() {
            this.clientProgressData = null;

            const dataPass = {
                client_id: this.clientId,
            };

            try {
                await clientProgress(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        if (response.data.data.length>0) {
                            this.clientProgressData = response.data.data;
                        }
                    } else {
                        this.notificationError("Something went wrong2!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong2!");
            }
        },
        async saveProgress() {
            this.formData = [];
            // Make a copy of editPopupProgressData to avoid modifying the original object
            this.formData = { ...this.editPopupProgressData };

            // Add clientId to formData
            this.formData.client_id = this.clientId;

            const dataPass = this.formData;

            if (this.editPopupProgressData.progress_id == null) {
                // add api
                try {
                    await addProgress(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.progressList();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                // edit api
                try {
                    await editProgress(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.progressList();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
        async deleteProgress(progressId) {
            const dataPass = {
                progress_id: progressId,
            };

            try {
                await deleteProgress(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.progressList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        //SUCCESS STORY TAB
        editSuccessStory(storyDataEdit) {
            this.editPopupSuccessData = storyDataEdit;
        },
        addSuccessStory() {
            this.editPopupSuccessData = [];
        },
        //API SUCCESS STORY TAB
        async successStoryList() {
            this.clientSuccessData = null;

            const dataPass = {
                client_id: this.clientId,
            };

            try {
                await successStory(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        if (response.data.data.length>0) {
                            this.clientSuccessData = response.data.data;
                        }
                    } else {
                        this.notificationError("Something went wrong3!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong3!");
            }
        },
        async saveSuccessStory() {
            this.formData = [];
            this.formData = { ...this.editPopupSuccessData };

            // Add clientId to formData
            this.formData.client_id = this.clientId;

            const dataPass = this.formData;
            if (this.editPopupSuccessData.story_id == null) {
                try {
                    await addSuccessStory(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.successStoryList();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await editSuccessStory(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.successStoryList();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
        async deleteSuccessStory(storyId) {
            const dataPass = {
                client_id: this.clientId,
                story_id: storyId,
            };

            try {
                await deleteSuccessStory(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.successStoryList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        //ADD PHOTOS SUCCESS_STORIES
        handleFileChange(event) {
            // Clear existing files
            this.selectedFiles = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.selectedFiles.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },
        removeFile(index) {
            this.selectedFiles.splice(index, 1); // Remove file from selectedFiles array
            this.selectedFiles=[];
        },
        
        //EDIT PHOTOS
        zoomPhoto(imageSrc) {
            this.zoomSuccessPhoto = imageSrc;
            console.log(this.zoomSuccessPhoto);
        },
        popupEditPhotos(photoPopup) {
            this.editSuccessPhotosData=photoPopup;
            console.log(this.editSuccessPhotosData);
        },
        
        async successPhotoList() {
            this.successPhotosData = null;

            const dataPass = {
                client_id: this.clientId,
            };

            try {
                await successPhoto(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        if (response.data.data.length>0) {
                            this.successPhotosData = response.data.data;
                        }
                    } else {
                        this.notificationError("Something went wrong4!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong4!");
            }
        },

        async addSuccessPhotos(category) {
            this.formData = new FormData();
            if (category==null) {
                this.formData.category = this.selectedCategory;
            } else {
                this.formData.category = category;
            }
            this.formData.photo = this.selectedFiles;
            this.formData.new_category = this.newCategory;
            this.formData.clientId = this.clientId;

            try {
                await addSuccessPhoto(this.formData).then((response) => {
                    if (response.data.message === "success") {
                        this.successPhotoList();
                        this.removeFile(index);
                        this.notificationSuccess("Photo Added!");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deletePhotos(photoId) {
            const dataPass = { photo_id: photoId };

            try {
                await deleteSuccessPhoto(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.notificationSuccess("Photo Deleted!");
                        this.successPhotoList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },


        //UPDATE GRAPH VALUE
        // updateGraphSeriesData() {
        //     //UPDATE X AXIS
        //     this.graphOptions.xaxis.categories = [ "Jan", "Feb", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        //     //PLOT GRAPH
        //     this.GraphSeries[0].data = [10, 20, 40, 40, 55, 70, 80, 100, 100, 80, 20, 35]; //LINE 1
        //     this.GraphSeries[1].data = [30, 70, 50, 35, 15, 90, 80, 45, 30, 70, 50, 40]; //LINE2

        //     console.log(this.graphOptions.xaxis.categories);
        //     console.log(this.GraphSeries[0].data);
        //     console.log(this.GraphSeries[1].data);
        // },

        setActiveTab(tabName) {
            this.activeTab = tabName;
        },

        convertDateFormat(originalDate) {
            const parsedDate = new Date(originalDate);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            const formattedDate = parsedDate.toLocaleDateString(
                "en-GB",
                options
            );

            return formattedDate;
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        //year
        async addYear() {
            const dataPass = {
                client_id: this.clientId,
                year: this.addYearOption,
            };

            try {
                await addYear(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.addYearOption='';
                        this.loadSalesList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async saveEditYearSales() {
            this.formData = {};
            this.formData = { 
                year: this.editPopupYear,
                months: this.editPopupYearData,
                clientId: this.clientId
            }; 
            const dataPass = this.formData;

            try {
                await editYear(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.loadSalesList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deleteYearCard(yearSelected) {
            const dataPass = {
                clientId: this.clientId,
                year: yearSelected,
            };
            
            try {
                await deleteYear(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.loadSalesList();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async loadSalesList() {
            this.yearListData = [];
            this.yearListAvailable = [];
            this.yearListArray = [];

            const dataPass = {
                client_id: this.clientId,
            };

            try {
                await clientProfileSales(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.yearListData = response.data.data;

                        const arrayYear = Object.entries(response.data.data).map((arr) => ({
                            year: arr[0],
                            monthData: arr[1],
                        }));
                        
                        this.yearListArray=arrayYear.slice().reverse();
                        this.salesAndCashRecord = [];
                        for (const year in this.yearListData) {
                            const dataForYear = Object.values(
                                this.yearListData[year]
                            );

                            const currentYear = new Date().getFullYear();
                            // find current year data to assign values to graph

                            if (year == currentYear) {
                                const datax=[];
                                const plot1=[];
                                const plot2=[];

                                dataForYear.forEach((data) => {
                                    datax.push(Object.values(data)[0].month);
                                    plot1.push(Object.values(data)[0].sales);
                                    plot2.push(Object.values(data)[0].cash_reserve);
                                });

                                //GRAPH HANDLING
                                this.graphOptions.xaxis.categories =[];
                                this.GraphSeries[0].data =[];
                                this.GraphSeries[1].data =[];

                                //UPDATE X AXIS
                                this.graphOptions.xaxis.categories = datax;

                                //PLOT GRAPH
                                this.GraphSeries[0].data = plot1; //LINE 1
                                this.GraphSeries[1].data = plot2; //LINE2

                                this.graphOptions = {
                                    ...this.graphOptions,
                                    title: {
                                        ...this.graphOptions.title,
                                        text: 'Sales & Cash Reserve For Year ' + currentYear,
                                    },
                                };
                            }

                            let totalMonthSales = 0;
                            let totalCashReserveSales = 0;

                            dataForYear.forEach((data) => {
                                totalMonthSales += parseFloat(Object.values(data)[0].sales);
                                totalCashReserveSales += parseFloat(Object.values(data)[0].cash_reserve);
                            });

                            this.salesAndCashRecord.push({
                                year: year,
                                data: {
                                    totalMonthSales: totalMonthSales,
                                    totalCashReserveSales:
                                        totalCashReserveSales,
                                },
                            });
                        }

                        const listOfYear = Object.keys(this.yearListData);
                        const updatedYearListSelected =this.yearListConst.filter(
                                (year) => !listOfYear.includes(year)
                            );

                        this.yearListAvailable = updatedYearListSelected;
                    } else {
                        this.notificationError("Something went wrong5!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong5!");
            }
        },

        findSalesRecord(yearToFind, mode) {
            const foundRecord = this.salesAndCashRecord.find(
                (item) => item.year === yearToFind
            );

            if (mode === "sales") {
                return foundRecord.data.totalMonthSales;
            } else {
                return foundRecord.data.totalCashReserveSales;
            }
        },

        reverseMonthValue(monthData) {
            console.log(monthData);
            const newMonthData = Object.entries(monthData).map((arr) => ({
                month: arr[0],
                monthData: arr[1],
            }));
            console.log(typeof(newMonthData[0].monthData));
            return newMonthData.slice().reverse(); // Reverse the array of keys
        },

        //attendance 
        async loadAttendance() {
            const dataPass = {
                client_id: this.clientId
            };

            try {
                await attendanceRecord(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.attendanceData=response.data.data
                    } else {
                        this.notificationError("Something went wrong6!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong6!");
            }
        },
        //btm
        updatedValue1(value) {
            this.value1=value;
        },
        updatedValue2(value) {
            this.value2=value;
        },
        updatedValue3(value) {
            this.value3=value;
        },
        updatedValue4(value) {
            this.value4=value;
        },
        updatedValue5(value) {
            this.value5=value;
        },
        updatedValue6(value) {
            this.value6=value;
        },
        updatedValue7(value) {
            this.value7=value;
        },
        updatedValue8(value) {
            this.value8=value;
        },
        async loadBtmValue() {
            const dataPass = {
                client_id: this.clientId
            };

            try {
                await btmValue(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        if (response.data.plot_radar.length>0) {
                            this.value1=response.data.data.value1;
                            this.value2=response.data.data.value2;
                            this.value3=response.data.data.value3;
                            this.value4=response.data.data.value4;
                            this.value5=response.data.data.value5;
                            this.value6=response.data.data.value6;
                            this.value7=response.data.data.value7;
                            this.value8=response.data.data.value8;
                            this.series = [{
                                name: 'Level',
                                data: response.data.plot_radar,  // Example: Updated data values
                            }];
                        }
                    } else {
                        this.notificationError("Something went wrong7!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong7!");
            }
        },
        async setBtmValue() {
            const dataPass = {
                client_id: this.clientId,
                value1: this.value1,
                value2: this.value2,
                value3: this.value3,
                value4: this.value4,
                value5: this.value5,
                value6: this.value6,
                value7: this.value7,
                value8: this.value8,
            };

            try {
                await addBtmValue(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.loadBtmValue();
                        this.notificationSuccess("Data Updated!");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
