    export const list=[ {
    id:0,
    title:document.getElementById("text")
},
{
    id:1,
    title:document.getElementById("password")
},
{
    id:2,
    title:document.getElementById("search")
},
{
    id:3,
    title:document.getElementById("pre")
},
{
    id:4,
    title:document.getElementById("ape")
},
{
    id:5,
    title:document.getElementById("pcheck")
},
{
    id:6,
    title:document.getElementById("acheck")
},
{
    id:7,
    title:document.getElementById("button")
},
{
    id:8,
    title:document.getElementById("area")
},
{
    id:9,
    title:document.getElementById("multiple")
},
{
    id:10,
    title:document.getElementById("inline")
},
{
    id:11,
    title:document.getElementById("cmultiple")
},
{
    id:12,
    title:document.getElementById("cinline")
},
{
    id:13,
    title:document.getElementById("basic")
},
{
    id:14,
    title:document.getElementById("smultiple")
},
{
    id:15,
    title:document.getElementById("file")
},
{
    id:16,
    title:document.getElementById("single")
},
{
    id:17,
    title:document.getElementById("double")
}
]
export const events=[ {
    id:0,
    title:document.getElementById("birthday")
},
{
    id:1,
    title:document.getElementById("meeting")
},
{
    id:2,
    title:document.getElementById("tour")
},
{
    id:3,
    title:document.getElementById("reporting")
},
{
    id:4,
    title:document.getElementById("lunch")
},

]