import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
////
////
////
//// dashboard
import Calendar from "../pages/calenderView.vue";
import commingSoonQuickStatus from "../pages/comingsoon/comingsoon_quick_status";
////
////
////
////
//// setting
import settingUserPermission from "../pages/setting/settingUserPermission.vue";
import settingScheduler from "../pages/setting/settingScheduler.vue";
import commingSoonAds from "../pages/comingsoon/comingsoon_ads";
import commingSoonEvent from "../pages/comingsoon/comingsoon_event";
import settingPackage from "../pages/setting/settingPackage.vue";
import settingClass from "../pages/setting/settingClass.vue";
////
////
////
////
//// client
import listClient from "../pages/clientManage/listClient.vue";
import clientProfile from "../pages/clientManage/clientProfile.vue";
import listParticipant from "../pages/clientManage/listParticipant.vue";
import commingSoonMonitoring from "../pages/comingsoon/comingsoon_monitoring";
////
////
////
////
//// front desk
import frontAttendance from "../pages/frontDesk/attendance.vue";
////
////
////
////
//// auth
import login from "../auth/login";
////
////
////
////
//// reporting
import commingSoonReportEvent from "../pages/comingsoon/comingsoon_report_event";
import commingSoonReportAds from "../pages/comingsoon/comingsoon_report_ads";
import commingSoonReportAttendance from "../pages/comingsoon/comingsoon_report_attendance";
////
////
////
////
////

import ForgetPassword from "../pages/authentication/forget_password";
import userProfile from "../pages/users/profile/userProfile.vue";

import Error404 from "../pages/error/error404";

const routes = [
    {
        path: "/dashboard",
        component: Body,
        children: [
            {
                path: "schedule",
                name: "schedule",
                component: Calendar,
                meta: {
                    title: "Schedule Dashboard | CRM Hasbul Brothers",
                },
            },
            {
                path: "quick-status",
                name: "quick-status",
                component: commingSoonQuickStatus,
                meta: {
                    title: "Quick Status Dashboard | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/setting",
        component: Body,
        children: [
            {
                path: "user-permission",
                name: "user-permission",
                component: settingUserPermission,
                meta: {
                    title: "User & Permission | CRM Hasbul Brothers",
                },
            },
            {
                path: "scheduler",
                name: "scheduler",
                component: settingScheduler,
                meta: {
                    title: "Setting Scheduler | CRM Hasbul Brothers",
                },
            },
            {
                path: "sads",
                name: "sads",
                component: commingSoonAds,
                meta: {
                    title: "Setting Ads | CRM Hasbul Brothers",
                },
            },
            {
                path: "sevent",
                name: "sevent",
                component: commingSoonEvent,
                meta: {
                    title: "Setting Event | CRM Hasbul Brothers",
                },
            },
            {
                path: "package",
                name: "package",
                component: settingPackage,
                meta: {
                    title: "Setting Package | CRM Hasbul Brothers",
                },
            },
            {
                path: "class",
                name: "class",
                component: settingClass,
                meta: {
                    title: "Setting Class | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/client",
        component: Body,
        children: [
            {
                path: "detail",
                name: "detail",
                component: listClient,
                meta: {
                    title: "List of Client | CRM Hasbul Brothers",
                },
            },
            {
                path: "participant",
                name: "participant",
                component: listParticipant,
                meta: {
                    title: "List of Participant | CRM Hasbul Brothers",
                },
            },
            {
                path: "monitoring",
                name: "monitoring",
                component: commingSoonMonitoring,
                meta: {
                    title: "Monitoring Client | CRM Hasbul Brothers",
                },
            },
            {
                path: "cprofile",
                name: "cprofile",
                component: clientProfile,
                meta: {
                    title: "Client Profile | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/front-desk",
        component: Body,
        children: [
            {
                path: "attendance",
                name: "attendance",
                component: frontAttendance,
                meta: {
                    title: "Front Desk | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/report",
        component: Body,
        children: [
            {
                path: "event",
                name: "event",
                component: commingSoonReportEvent,
                meta: {
                    title: "Event Performance | CRM Hasbul Brothers",
                },
            },
            {
                path: "ads",
                name: "ads",
                component: commingSoonReportAds,
                meta: {
                    title: "Ads Performance | CRM Hasbul Brothers",
                },
            },
            {
                path: "attendances",
                name: "attendances",
                component: commingSoonReportAttendance,
                meta: {
                    title: "Attendances Report | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/user",
        component: Body,
        children: [
            {
                path: "profile",
                name: "profile",
                component: userProfile,
                meta: {
                    title: "Users Profile | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/auth",
        children: [
            {
                path: "login",
                name: "login",
                component: login,
                meta: {
                    title: "Login | CRM Hasbul Brothers",
                },
            },
            {
                path: "forget-password",
                name: "forget-password",
                component: ForgetPassword,
                meta: {
                    title: "Forget Password | CRM Hasbul Brothers",
                },
            },
        ],
    },
    {
        path: "/error",
        children: [
            {
                path: "404",
                name: "404",
                component: Error404,
                meta: {
                    title: "Not Found | CRM Hasbul Brothers",
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    const path1 = ["/", "/auth/login", "/auth/forget-password"];
    const path2 = ["/auth/login", "/auth/forget-password"];
    const path3 = ["/"];

    const now = new Date();
    const nowTime =
        now.getFullYear() +
        "-" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(now.getDate()).padStart(2, "0") +
        " " +
        String(now.getHours()).padStart(2, "0") +
        ":" +
        String(now.getMinutes()).padStart(2, "0") +
        ":" +
        String(now.getSeconds()).padStart(2, "0");
    if (
        path1.includes(to.path) &&
        localStorage.getItem("token_credential") &&
        localStorage.getItem("token_expired") >= nowTime
    ) {
        return next("/dashboard/schedule");
    } else if (
        path3.includes(to.path) &&
        localStorage.getItem("token_expired") <= nowTime
    ) {
        localStorage.removeItem("token_expired");
        localStorage.removeItem("token_credential");
        return next("/auth/login");
    } else if (
        !path2.includes(to.path) &&
        (!localStorage.getItem("token_credential") ||
            localStorage.getItem("token_credential") === undefined) &&
        (!localStorage.getItem("token_expired") ||
            localStorage.getItem("token_expired") === undefined)
    ) {
        return next("/auth/login");
    } else if (to.path) {
        function collectPaths(routes) {
            let paths = [];
            routes.forEach((route) => {
                if (route.children) {
                    route.children
                        .map((child) => child.path)
                        .forEach(function (path) {
                            paths.push(route.path.concat("/").concat(path));
                        });
                }
            });
            return paths;
        }

        const allowedPaths = collectPaths(routes);

        if (!allowedPaths.includes(to.path)) {
            next("/error/404");
        } else {
            next();
        }
    }
});

export default router;
