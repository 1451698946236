<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>Class</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <button
                                @click="clearModalClass()"
                                data-bs-toggle="modal"
                                data-bs-target="#addClass"
                                class="p-2"
                                style="
                                    border-radius: 5px;
                                    background-color: #04318c;
                                    color: #fefefe;
                                    width: 44%;
                                    border: none;
                                    display: flex;
                                    justify-content: space-evenly;
                                    align-items: center;
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 10%"
                                    fill="currentColor"
                                    class="bi bi-plus-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                    />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                    />
                                </svg>
                                Create Class
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="this.tableloader === false">
                    <div class="card">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div
                                class="col-2 d-flex"
                                style="
                                    font-size: 1rem;
                                    font-weight: 500;
                                    align-items: center;
                                "
                            >
                                <span>List of Class</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input
                                        class="form-control"
                                        v-model="inputText"
                                        @input="FilterHandling(inputText)"
                                        type="text"
                                        placeholder="Search name.."
                                    /><vue-feather
                                        class="search-icon"
                                        type="search"
                                    ></vue-feather>
                                </div>
                            </div>
                            <!-- <div
                                class="col-1 d-flex"
                                style="cursor: pointer; align-items: center"
                            >
                                <div
                                    style="
                                        font-size: 1rem;
                                        font-weight: 450;
                                        padding-right: 10px;
                                    "
                                >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#7366FF"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                        />
                                    </svg>
                                </div>
                            </div> -->
                        </div>

                        <!-- DATA Table -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '',
                                        }"
                                        @click="changeTab('')"
                                    >
                                        All
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '0',
                                        }"
                                        @click="changeTab('0')"
                                    >
                                        Active
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '1',
                                        }"
                                        @click="changeTab('1')"
                                    >
                                        Inactive
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '2',
                                        }"
                                        @click="changeTab('2')"
                                    >
                                        Suspend
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="p-2">
                            <div class="d-flex">
                                <div class="p-1" style="width: 13%">
                                    <div
                                        class="p-1"
                                        align="center"
                                        style="
                                            width: 100%;
                                            background-color: #54ba4a;
                                            border-radius: 8px;
                                            color: #fefefe;
                                        "
                                    >
                                        Attend
                                    </div>
                                </div>
                                <div class="p-1" style="width: 13%">
                                    <div
                                        class="p-1"
                                        align="center"
                                        style="
                                            width: 100%;
                                            background-color: #dc3545;
                                            border-radius: 8px;
                                            color: #fefefe;
                                        "
                                    >
                                        Invoke
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="border-bottom: 1px solid #cccccc"></div> -->

                        <!-- DATA Table -->
                        <div v-if="this.clientTableDataResult">
                            <div class="search-table-outter wrapper p-4">
                                <table class="search-table inner">
                                    <tr>
                                        <th style="min-width: 50px;">#</th>
                                        <th style="min-width: 250px;">Class Name</th>
                                        <th style="min-width: 250px;">Badge</th>
                                        <th style="min-width: 150px;text-align: center;">Capacity</th>
                                        <th style="min-width: 300px;">Detail</th>
                                        <th style="min-width: 200px;">Date Start</th>
                                        <th style="min-width: 200px;">Date End</th>
                                        <th style="min-width: 150px;">Trainer</th>
                                        <th style="min-width: 150px;">Location</th>
                                        <th style="min-width: 150px;text-align: center;">Status</th>
                                        <th style="min-width: 100px;text-align: center;">Action</th>
                                    </tr>
                                    <tr v-for="(item, index) in this.clientTableDataResult" :key="index">
                                        <td> {{ (currentPage - 1) * itemsPerPage + index + 1 }}. </td>
                                        <td> {{ item.class_name !== null ? item.class_name : "-" }} </td>
                                        <td> {{ item.class_badge !== null ? item.class_badge : "-" }} </td>
                                        <td align="center"> {{ item.class_cap !== "" ? item.class_cap : "-" }} </td>
                                        <td> {{ item.class_detail !== null ? item.class_detail : "-" }} </td>
                                        <td> {{ item.class_date_start ? formatDateTime(item.class_date_start) : "-" }}</td>
                                        <td> {{ item.class_date_end ? formatDateTime(item.class_date_end) : "-" }}</td>
                                        <td> {{(result = this.trainerAvailable.find( (item1) => item1.usr_main_id === item.class_trainer)) ? result.first_name + " " + result.last_name : "-"}}</td>
                                        <td> {{ item.class_location !== null ? item.class_location : "-" }}</td>
                                        <td style="min-width: 150px">
                                            <select :value="item.class_status" class="form-select form-control-primary" name="select" @change="setStatus(item,$event.target.value)">
                                                <option value="" disabled selected>Select Status</option>
                                                <option value="0">Active</option>
                                                <option value="1">Inactive</option>
                                                <option value="2">Suspend</option>
                                            </select>
                                        </td>
                                        <td style="min-width: 100px">
                                            <div
                                                style="
                                                    display: flex;
                                                    justify-content: center;
                                                "
                                            >
                                                <div style="cursor: pointer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        @click="
                                                            intializeEditClass(
                                                                item
                                                            )
                                                        "
                                                        width="20"
                                                        height="20"
                                                        fill="#7366FF"
                                                        class="bi bi-pen-fill"
                                                        viewBox="0 0 16 16"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addClass"
                                                    >
                                                        <path
                                                            d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"
                                                        />
                                                    </svg>
                                                </div>
                                                <div style="width: 20px"></div>
                                                <div
                                                    class="deleteYearCard"
                                                    style="cursor: pointer"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        @click="
                                                            deleteClass(item.id)
                                                        "
                                                        width="20"
                                                        height="20"
                                                        fill="#7366FF"
                                                        class="bi bi-trash-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <!-- EMPTY DATA DISPLAY -->
                        <div v-else align="center" style="height: 300px">
                            <img
                                style="
                                    border-radius: 5px;
                                    width: 240px;
                                    height: 220px;
                                "
                                alt=""
                                src="../../assets/images/no_data_image.png"
                            />
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div
                                    class="inactivePage"
                                    style="padding-left: 30px"
                                >
                                    {{
                                        "Page " +
                                        this.currentPage +
                                        " out of " +
                                        this.maxPage
                                    }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div
                                    class="d-flex"
                                    style="
                                        padding-right: 30px;
                                        justify-content: flex-end;
                                    "
                                >
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                this.currentPage != '1',
                                            inactiveButtonPage:
                                                this.currentPage == '1',
                                        }"
                                        @click="prevPage()"
                                        style="
                                            width: 30%;
                                            border-top-left-radius: 8px;
                                            border-bottom-left-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Previous
                                    </button>
                                    <div
                                        @click="setFirstPage()"
                                        v-if="this.currentPage != 1"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <div
                                        @click="goToPage(pageNumber)"
                                        v-for="pageNumber in limitedPages"
                                        :key="pageNumber"
                                        :class="{
                                            activePage:
                                                currentPage === pageNumber,
                                            inactivePage:
                                                currentPage !== pageNumber,
                                        }"
                                        class="p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        {{ pageNumber }}
                                    </div>
                                    <div
                                        @click="setLastPage()"
                                        v-if="this.currentPage != this.maxPage"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                currentPage === '1' &&
                                                currentPage !== this.maxPage,
                                            inactiveButtonPage:
                                                currentPage === this.maxPage,
                                        }"
                                        @click="nextPage()"
                                        style="
                                            width: 30%;
                                            border-top-right-radius: 8px;
                                            border-bottom-right-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box"><div class="loader-3"></div></div>
                </div>

                <!-- POPUP ADD CLIENT -->
                <div class="modal fade modal-bookmark" id="addClass" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title"> <span v-if="this.classId === null">Create</span><span v-else>Edit</span> Class</h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style="max-height: 60vh;overflow: scroll;overflow-x: hidden;">
                                <div class="modal-body row" style="justify-content: space-between">
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Class Name</div>
                                        <input v-model="this.className" class="form-control" type="text" placeholder="Tiktok Master Class"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Class Capacity</div>
                                        <input v-model="this.classCapacity" class="form-control" type="text" placeholder="150"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Trainer</div>
                                        <select v-model="this.classTrainer" class="form-select form-control-primary" name="select" required>
                                            <option value="" disabled selected required>Select Trainer</option>
                                            <option v-for="item in this.trainerAvailable" :key="item.usr_main_id" :value="item.usr_main_id">
                                                {{ item.first_name + " " + item.last_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Location</div>
                                        <input v-model="this.classLocation" class="form-control" type="text" placeholder="Training Center" required/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Status</div>
                                        <select v-model="this.classStatus" class="form-select form-control-primary" name="select" required>
                                            <option value="" disabled selected>Select Status</option>
                                            <option value="0">Active</option>
                                            <option value="1">Inactive</option>
                                            <option value="2">Suspend</option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Badge</div>
                                        <input v-model="this.classBadge" class="form-control" type="text" placeholder="Class Badge" required/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem">
                                        <div style="font-weight: 450">Date & Time Start</div>
                                        <input v-model="this.classDateStart" class="datepicker-here form-control digits" type="datetime-local" placeholder="Date & Time"/>
                                    </div>
                                    <div class="py-2 col-6" style="font-size: 1rem" >
                                        <div style="font-weight: 450">Date & Time End</div>
                                        <input v-model="this.classDateEnd" class="datepicker-here form-control digits" type="datetime-local" placeholder="Date & Time"/>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Class Details</div>
                                        <textarea v-model="this.classDetails" class="form-control" style="height: 150px" type="text" placeholder="Detail about class.."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12 d-flex" style="justify-content: flex-end">
                                    <div v-if="this.classId !== null">
                                        <button @click="deleteClass(this.classId)" data-bs-dismiss="modal" class="btn btn-danger" type="button" style="width: 100%">Delete</button>
                                    </div>
                                    <div style="padding-right: 20px"></div>
                                    <div>
                                        <button @click="saveClass()" data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%;color: #fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { listClass,classAdd,classEdit,classDelete} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setting Class",

    data() {
        return {
            searchText: "",
            selectedTab: "",
            clientTableData: null,
            clientTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,

            trainerAvailable: [],
            selectedFiles: [],
            classId: null,
            className: null,
            classCapacity: null,
            classDetails: null,
            classBadge: null,
            classDateStart: null,
            classDateEnd: null,
            classTrainer: "",
            classLocation: null,
            classStatus: "",
        };
    },

    beforeMount() {
        this.loadListClass();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.class_status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.clientTableDataResult = this.clientTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult =
                        this.clientTableDataResult.filter(
                            (item) =>
                                (item.class_detail &&
                                    item.class_detail
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.class_location &&
                                    item.class_location
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.class_name &&
                                    item.class_name
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.class_status === this.selectedTab
                );
            } else {
                this.clientTableDataResult = this.clientTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.clientTableDataResult = this.clientTableDataResult.filter(
                    (item) =>
                        (item.class_detail &&
                            item.class_detail
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.class_location &&
                            item.class_location
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.class_name &&
                            item.class_name.toLowerCase().includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.clientTableDataResult = null;
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        handleFileChange(event) {
            // Clear existing files
            this.selectedFiles = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.selectedFiles.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },

        formatDateTime(inputDateStr) {
            // Create a new Date object from the input string
            const inputDate = new Date(inputDateStr);

            // Extract year, month, and day components from the Date object
            const year = inputDate.getFullYear();
            const month = inputDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 = January)
            const day = inputDate.getDate();

            // Extract hours, minutes, and AM/PM notation
            let hours = inputDate.getHours();
            const minutes = inputDate.getMinutes();
            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            // Convert hours from 24-hour to 12-hour format
            hours = hours % 12 || 12; // Convert 0 hours to 12 in 12-hour format

            // Pad single-digit month, day, and minutes values with leading zeros
            const formattedMonth = month.toString().padStart(2, '0');
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            // Construct the formatted time string in hh:mm am/pm format
            const formattedTime = `${hours}:${formattedMinutes} ${amOrPm}`;

            // Construct the formatted date string in dd/mm/yyyy format
            const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedTime}`;

            return formattedDate;
        },

        clearModalClass() {
            this.selectedFiles = [];
            this.classId = null;
            this.className = null;
            this.classCapacity = null;
            this.classDetails = null;
            this.classBadge = null;
            this.classDateStart = null;
            this.classDateEnd = null;
            this.classTrainer = "";
            this.classLocation = null;
            this.classStatus = "";
        },

        intializeEditClass(dataTable) {
            this.classId = dataTable.id;
            this.className = dataTable.class_name;
            this.classCapacity = dataTable.class_cap;
            this.classDetails = dataTable.class_detail;
            this.classBadge = dataTable.class_badge;
            this.classDateStart = dataTable.class_date_start;
            this.classDateEnd = dataTable.class_date_end;
            this.classTrainer = dataTable.class_trainer;
            this.classLocation = dataTable.class_location;
            this.classStatus = dataTable.class_status;
        },

        async loadListClass() {
            this.tableloader = true;

            try {
                await listClass().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData = response.data.data;
                        this.trainerAvailable = response.data.trainer;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                        this.FilterHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deleteClass(id) {
            const dataPass = {
                classId: id,
            };

            try {
                await classDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalClass();
                        this.loadListClass();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async setStatus(classDetail,newStatus){
            console.log(classDetail);
            this.formData = new FormData();
            this.formData.photo1 = classDetail.class_img;
            this.formData.classId = classDetail.id;
            this.formData.className = classDetail.class_name;
            this.formData.classBadge = classDetail.class_badge;
            this.formData.classCapacity = classDetail.class_cap;
            this.formData.classDetails = classDetail.class_detail;
            this.formData.classDateStart = classDetail.class_date_start;
            this.formData.classDateEnd = classDetail.class_date_end;
            this.formData.classTrainer = classDetail.class_trainer;
            this.formData.classLocation = classDetail.class_location;
            this.formData.classStatus = newStatus;

            try {
                await classEdit(this.formData).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalClass();
                        this.loadListClass();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async saveClass() {
            this.formData = new FormData();
            this.formData.photo1 = this.selectedFiles1;
            this.formData.classId = this.classId;
            this.formData.className = this.className;
            this.formData.classBadge = this.classBadge;
            this.formData.classCapacity = this.classCapacity;
            this.formData.classDetails = this.classDetails;
            this.formData.classDateStart = this.classDateStart;
            this.formData.classDateEnd = this.classDateEnd;
            this.formData.classTrainer = this.classTrainer;
            this.formData.classLocation = this.classLocation;
            this.formData.classStatus = this.classStatus;

            if (this.classId === null) {
                try {
                    await classAdd(this.formData).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalClass();
                            this.loadListClass();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await classEdit(this.formData).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalClass();
                            this.loadListClass();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
    },
};
</script>
