<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img
                                        height="1000"
                                        class="img-fluid for-light"
                                        src="../assets/images/logo/logo_login.png"
                                        alt="loginpage"
                                    />
                                    <img
                                        class="img-fluid for-dark"
                                        src="../assets/images/logo/logo_dark.png"
                                        alt="loginpage"
                                    />
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <h4>Sign in to account</h4>
                                    <p>Enter your email & password to login</p>
                                    <div class="form-group">
                                        <label class="col-form-label"
                                            >Email Address</label
                                        >
                                        <input
                                            class="form-control"
                                            type="email"
                                            required=""
                                            placeholder="youremail@gmail.com"
                                            v-model="user.email.value"
                                        />
                                        <span
                                            class="validate-error"
                                            v-if="
                                                !user.email.value ||
                                                !validEmail(user.email.value)
                                            "
                                            >{{ user.email.errormsg }}</span
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label"
                                            >Password</label
                                        >
                                        <div
                                            class="form-input position-relative"
                                        >
                                            <input
                                                class="form-control"
                                                :type="passwordFieldType"
                                                name="login[password]"
                                                required=""
                                                placeholder="*********"
                                                v-model="user.password.value"
                                            />
                                            <span
                                                class="validate-error"
                                                v-if="
                                                    user.password.value.length <
                                                    7
                                                "
                                                >{{
                                                    user.password.errormsg
                                                }}</span
                                            >

                                            <div
                                                class="show-hide"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            >
                                                <span class="show">{{
                                                    showPasswordText
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="checkbox p-0">
                                            <input
                                                id="checkbox1"
                                                type="checkbox"
                                                v-model="user.remember.value"
                                            />
                                            <label
                                                class="text-muted"
                                                for="checkbox1"
                                                >Remember me</label
                                            >
                                        </div>
                                        <!-- <router-link
                                            class="link"
                                            to="/authentication/forgetpassword"
                                        >
                                            Forgot password?</router-link
                                        > -->
                                        <div class="text-end mt-3">
                                            <button
                                                class="btn btn-primary btn-block w-100"
                                                type="submit"
                                                @click.prevent="loginSubmit"
                                            >
                                                Sign in
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { login } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "login",

    data() {
        return {
            showPassword: false,
            result: {
                email: "",
                password: "",
            },
            user: {
                remember: {
                    value: false,
                    errormsg: "",
                },
                email: {
                    value: "",
                    errormsg: "",
                },
                password: {
                    value: "",
                    errormsg: "",
                },
            },
        };
    },

    created() {},

    computed: {
        passwordFieldType() {
            return this.showPassword ? "text" : "password";
        },
        showPasswordText() {
            return this.showPassword ? "Hide" : "Show";
        },
    },

    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },

        validEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        async loginSubmit() {
            if (
                !this.user.password.value ||
                this.user.password.value.length < 7
            ) {
                this.user.password.errormsg = "min length 7";
            } else {
                this.user.password.errormsg = "";
            }

            if (!this.user.email.value) {
                this.user.email.errormsg = "Invalid email address";
            } else if (!this.validEmail(this.user.email.value)) {
                this.user.email.errormsg = "Valid email required.";
            } else {
                this.user.email.errormsg = "";
            }

            if (!this.user.email.errormsg && !this.user.password.errormsg) {
                const dataPass = {
                    email: this.user.email.value,
                    password: this.user.password.value,
                    remember: this.user.remember.value,
                };

                try {
                    await login(dataPass).then((response) => {
                        if (response.data.message === "user not found") {
                            this.notificationWarning("wrong credentials!");
                        } else if (response.data.message === "success") {
                            localStorage.setItem(
                                "token_credential",
                                response.data.token
                            );
                            localStorage.setItem(
                                "token_expired",
                                response.data.expired
                            );
                            localStorage.setItem(
                                "user_detail",
                                response.data.user_detail
                            );

                            const removeEscapedString =
                                response.data.user_detail.access_privilege.replace(
                                    /\\\"/g,
                                    '"'
                                );

                            const accessPrivilege =
                                JSON.parse(removeEscapedString);

                            localStorage.setItem(
                                "access_privilege",
                                JSON.stringify(accessPrivilege)
                            );

                            localStorage.setItem(
                                "access_role",
                                response.data.user_detail.access_name
                            );

                            localStorage.setItem(
                                "first_name",
                                response.data.user_detail.usr_fname
                            );

                            this.$router.push("/dashboard/schedule");
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
    },
};
</script>
