<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>Leads</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <button
                                @click="clearModalParticipant()"
                                data-bs-toggle="modal"
                                data-bs-target="#addParticipant"
                                class="p-2"
                                style="
                                    border-radius: 5px;
                                    background-color: #04318c;
                                    color: #fefefe;
                                    width: 44%;
                                    border: none;
                                    display: flex;
                                    justify-content: space-evenly;
                                    align-items: center;
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 10%"
                                    fill="currentColor"
                                    class="bi bi-plus-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                    />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                    />
                                </svg>
                                Add Lead
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="this.tableloader === false">
                    <div class="card">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div class="col-2 d-flex" style=" font-size: 1rem; font-weight: 500; align-items: center; ">
                                <span>List of Leads</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.."/>
                                    <vue-feather class="search-icon" type="search"></vue-feather>
                                </div>
                            </div>
                            <!-- <div class="col-1 d-flex" style="cursor: pointer; align-items: center" >
                                <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px; " >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#7366FF" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </div>
                            </div> -->
                        </div>

                        <!-- FILTER TAB STATUS -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')" >
                                        All
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '0', }" @click="changeTab('0')" >
                                        Unmarked
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '1', }" @click="changeTab('1')" >
                                        Engage
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '2', }" @click="changeTab('2')" >
                                        Not Engage
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '3', }" @click="changeTab('3')" >
                                        Prospect
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '4', }" @click="changeTab('4')" >
                                        Client
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="p-2">
                        <div class="d-flex">
                            <div class="p-1" style="width: 13%">
                                <div
                                    class="p-1"
                                    align="center"
                                    style="
                                        width: 100%;
                                        background-color: #54ba4a;
                                        border-radius: 8px;
                                        color: #fefefe;
                                    "
                                >
                                    Engage
                                </div>
                            </div>
                            <div class="p-1" style="width: 13%">
                                <div
                                    class="p-1"
                                    align="center"
                                    style="
                                        width: 100%;
                                        background-color: #ffaa05;
                                        border-radius: 8px;
                                        color: #fefefe;
                                    "
                                >
                                    Not Engage
                                </div>
                            </div>
                            <div class="p-1" style="width: 13%">
                                <div
                                    class="p-1"
                                    align="center"
                                    style="
                                        width: 100%;
                                        background-color: #04318c;
                                        border-radius: 8px;
                                        color: #fefefe;
                                    "
                                >
                                    Prospect
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="border-bottom: 1px solid #cccccc"></div> -->

                        <!-- DATA Table -->
                        <div v-if="this.clientTableDataResult">
                            <div class="search-table-outter wrapper p-4" style="height: 420px">
                                <table class="search-table inner">
                                    <tr style="height: 20px">
                                        <th style="min-width: 50px;">#</th>
                                        <th style="min-width: 150px;">Event Name</th>
                                        <th style="min-width: 250px;">Full Name</th>
                                        <th style="min-width: 130px;">Contact</th>
                                        <th style="min-width: 250px;">Brand Name</th>
                                        <th style="min-width: 150px;">Sales Average</th>
                                        <th style="text-align: center;">Status </th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                    <tr style="height: 60px" v-for="(item, index) in this.clientTableDataResult" :key="index">
                                        <td> {{ (currentPage - 1) * itemsPerPage + index + 1 }}.</td>
                                        <td> {{ 
                                                (result = this.eventAvailable.find( (item1) =>
                                                    item1.id === item.event
                                                )) ? result.event_name : "-"
                                            }}
                                        </td>
                                        <td> {{ item.full_name !== "" ? item.full_name : "-" }}</td>
                                        <td> {{ item.contact_number !== null ? item.contact_number : "-" }}</td>
                                        <td> {{ item.brand_name !== null ? item.brand_name : "-" }} </td>
                                        <td>
                                            {{
                                                item.sales_avg !== null
                                                    ? item.sales_avg === "0"
                                                        ? "RM0 - RM5k (Super Early)"
                                                        : item.sales_avg === "1"
                                                        ? "RM6k - RM30k (Early)"
                                                        : item.sales_avg === "2"
                                                        ? "RM30k - RM100k (Growth)"
                                                        : item.sales_avg === "3"
                                                        ? "RM100k and above (Matured)"
                                                        : "Invalid"
                                                    : "-"
                                            }}
                                        </td>
                                        <td style="min-width: 150px">
                                            <select :value="item.prospect_status" class="form-select form-control-primary" name="select" required @change="setStatus(item, $event.target.value)">
                                                <option value="" disabled selected> Select Status </option>
                                                <option value="0">Unmarked</option>
                                                <option value="1">Engage</option>
                                                <option value="2"> Not Engage </option>
                                                <option value="3">Prospect</option>
                                                <option value="4">Client</option>
                                            </select>
                                        </td>
                                        <td style="min-width: 100px">
                                            <div style=" display: flex; justify-content: center;">
                                                <div style="cursor: pointer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        @click="
                                                            intializeEditParticipant(
                                                                item
                                                            )
                                                        "
                                                        width="20"
                                                        height="20"
                                                        fill="#7366FF"
                                                        class="bi bi-pen-fill"
                                                        viewBox="0 0 16 16"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addParticipant"
                                                    >
                                                        <path
                                                            d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"
                                                        />
                                                    </svg>
                                                </div>
                                                <div style="width: 20px"></div>
                                                <div
                                                    class="deleteYearCard"
                                                    style="cursor: pointer"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        @click="
                                                            deleteParticipant(
                                                                item.id
                                                            )
                                                        "
                                                        width="20"
                                                        height="20"
                                                        fill="#7366FF"
                                                        class="bi bi-trash-fill"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div v-else align="center" style="height: 300px">
                            <img
                                style="
                                    border-radius: 5px;
                                    width: 240px;
                                    height: 220px;
                                "
                                alt=""
                                src="../../assets/images/no_data_image.png"
                            />
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div
                                    class="inactivePage"
                                    style="padding-left: 30px"
                                >
                                    {{
                                        "Page " +
                                        this.currentPage +
                                        " out of " +
                                        this.maxPage
                                    }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div
                                    class="d-flex"
                                    style="
                                        padding-right: 30px;
                                        justify-content: flex-end;
                                    "
                                >
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                this.currentPage != '1',
                                            inactiveButtonPage:
                                                this.currentPage == '1',
                                        }"
                                        @click="prevPage()"
                                        style="
                                            width: 30%;
                                            border-top-left-radius: 8px;
                                            border-bottom-left-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Previous
                                    </button>
                                    <div
                                        @click="setFirstPage()"
                                        v-if="this.currentPage != 1"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <div
                                        @click="goToPage(pageNumber)"
                                        v-for="pageNumber in limitedPages"
                                        :key="pageNumber"
                                        :class="{
                                            activePage:
                                                currentPage === pageNumber,
                                            inactivePage:
                                                currentPage !== pageNumber,
                                        }"
                                        class="p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        {{ pageNumber }}
                                    </div>
                                    <div
                                        @click="setLastPage()"
                                        v-if="this.currentPage != this.maxPage"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                currentPage === '1' &&
                                                currentPage !== this.maxPage,
                                            inactiveButtonPage:
                                                currentPage === this.maxPage,
                                        }"
                                        @click="nextPage()"
                                        style="
                                            width: 30%;
                                            border-top-right-radius: 8px;
                                            border-bottom-right-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- POPUP ADD CLIENT -->
                <div
                    class="modal fade modal-bookmark"
                    id="addParticipant"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <span v-if="this.pId === null">Add</span
                                    ><span v-else>Update</span> Lead
                                </h5>
                                <button
                                    class="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div
                                style="
                                    max-height: 60vh;
                                    overflow: scroll;
                                    overflow-x: hidden;
                                "
                            >
                                <div
                                    class="modal-body row"
                                    style="justify-content: space-between"
                                >
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Event Name
                                        </div>

                                        <select
                                            v-model="this.pEventName"
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                required
                                            >
                                                Select Event
                                            </option>
                                            <option
                                                v-for="item in this
                                                    .eventAvailable"
                                                :key="item.id"
                                                :value="item.id"
                                            >
                                                {{ item.event_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-8"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Full Name
                                        </div>
                                        <input
                                            v-model="this.pFullName"
                                            class="form-control"
                                            type="text"
                                            placeholder="Ahmad"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-4"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Contact Number
                                        </div>
                                        <input
                                            v-model="this.pContactNumber"
                                            class="form-control"
                                            type="text"
                                            placeholder="0148876776"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Brand Name
                                        </div>
                                        <input
                                            v-model="this.pBrandName"
                                            class="form-control"
                                            type="text"
                                            placeholder="Keropok Terhad"
                                            required
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Business Industry
                                        </div>
                                        <select
                                            v-model="this.pBusinessIndustry"
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                        >
                                            <option value="" disabled selected>
                                                Select Business Industry
                                            </option>
                                            <option value="FASHION">
                                                FASHION
                                            </option>
                                            <option value="BEAUTY">
                                                BEAUTY
                                            </option>
                                            <option value="BEAUTY PRODUCT">
                                                BEAUTY PRODUCT
                                            </option>
                                            <option value="HEALTH & BEAUTY">
                                                HEALTH & BEAUTY
                                            </option>
                                            <option value="HEALTH SUPPLEMENT">
                                                HEALTH SUPPLEMENT
                                            </option>
                                            <option value="HEALTH">
                                                HEALTH
                                            </option>
                                            <option value="HEALTHCARE">
                                                HEALTHCARE
                                            </option>
                                            <option value="PRINTING">
                                                PRINTING
                                            </option>
                                            <option value="SERVICE PRINTING">
                                                SERVICE PRINTING
                                            </option>
                                            <option value="SERVICE">
                                                SERVICE
                                            </option>
                                            <option value="SERVICE CONSULTANT">
                                                SERVICE CONSULTANT
                                            </option>
                                            <option value="MARKETING SERVICE">
                                                MARKETING SERVICE
                                            </option>
                                            <option value="ENTERTAINMENT">
                                                ENTERTAINMENT
                                            </option>
                                            <option value="FOODS & BEVERAGES">
                                                FOODS & BEVERAGES
                                            </option>
                                            <option value="PERFUME">
                                                PERFUME
                                            </option>
                                            <option value="PRODUCT">
                                                PRODUCT
                                            </option>
                                            <option value="PRODUCT HOME">
                                                PRODUCT HOME
                                            </option>
                                            <option value="BABY PRODUCT">
                                                BABY PRODUCT
                                            </option>
                                            <option value="GADGET">
                                                GADGET
                                            </option>
                                            <option value="IT">IT</option>
                                            <option value="BUSINESS CONSULTING">
                                                BUSINESS CONSULTING
                                            </option>
                                            <option
                                                value="FINANCIAL CONSULTANCY"
                                            >
                                                FINANCIAL CONSULTANCY
                                            </option>
                                            <option value="EDUCATION">
                                                EDUCATION
                                            </option>
                                            <option value="HOUSEHOLD">
                                                HOUSEHOLD
                                            </option>
                                            <option value="NOVEL PUBLISHER">
                                                NOVEL PUBLISHER
                                            </option>
                                            <option value="CONSTRUCTION">
                                                CONSTRUCTION
                                            </option>
                                            <option value="AUTOMOTIVE">
                                                AUTOMOTIVE
                                            </option>
                                            <option value="OTHER">OTHER</option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Sales Average
                                        </div>
                                        <select
                                            v-model="this.pSalesAverage"
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                        >
                                            <option value="" disabled selected>
                                                Select Sales Average
                                            </option>
                                            <option value="0">
                                                RM0 - RM5k (Super Early)
                                            </option>
                                            <option value="1">
                                                RM6k - RM30k (Early)
                                            </option>
                                            <option value="2">
                                                RM30k - RM100k (Growth)
                                            </option>
                                            <option value="3">
                                                RM100k and above (Matured)
                                            </option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Status
                                        </div>
                                        <select v-model="this.pStatus" class="form-select form-control-primary" name="select" required >
                                            <option value="" disabled selected> Select Status </option>
                                            <option value="0">Unmarked</option>
                                            <option value="1">Engage</option>
                                            <option value="2"> Not Engage </option>
                                            <option value="3">Prospect</option>
                                            <option value="4">Client</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12" align="right">
                                    <div
                                        class="col-12 d-flex"
                                        style="justify-content: flex-end"
                                    >
                                        <div v-if="this.pId !== null">
                                            <button
                                                @click="
                                                    deleteParticipant(this.pId)
                                                "
                                                data-bs-dismiss="modal"
                                                class="btn btn-danger"
                                                type="button"
                                                style="width: 100%"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                        <div style="padding-right: 20px"></div>
                                        <div>
                                            <button
                                                @click="saveParticipant()"
                                                data-bs-dismiss="modal"
                                                class="btn btn-color"
                                                type="button"
                                                style="
                                                    width: 100%;
                                                    color: #fefefe;
                                                    background-color: #04318c !important;
                                                    border-color: #04318c !important;
                                                "
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    listParticipant,
    participantAdd,
    participantEdit,
    participantDelete,
    LeadStatusUpdate,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "List Participant",

    data() {
        return {
            searchText: "",
            selectedTab: "",
            clientTableData: null,
            clientTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,

            eventAvailable: [],
            tablePackage: null,
            pId: null,
            pEventName: "",
            pFullName: null,
            pContactNumber: null,
            pSalesAverage: "",
            pBusinessIndustry: "",
            pBrandName: null,
            pStatus: "",
        };
    },

    beforeMount() {
        this.loadListParticipant();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3;
            const threshold = 3;

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.prospect_status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.clientTableDataResult = this.clientTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult =
                        this.clientTableDataResult.filter(
                            (item) =>
                                (item.brand_name &&
                                    item.brand_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.contact_number &&
                                    item.contact_number
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.full_name &&
                                    item.full_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.niche_market &&
                                    item.niche_market
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.prospect_status === this.selectedTab
                );
            } else {
                this.clientTableDataResult = this.clientTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.clientTableDataResult = this.clientTableDataResult.filter(
                    (item) =>
                        (item.brand_name &&
                            item.brand_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.contact_number &&
                            item.contact_number
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.full_name &&
                            item.full_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.niche_market &&
                            item.niche_market
                                .toLowerCase()
                                .includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.clientTableDataResult = null;
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        clearModalParticipant() {
            this.pId = null;
            this.pEventName = "";
            this.pFullName = null;
            this.pContactNumber = null;
            this.pSalesAverage = "";
            this.pBusinessIndustry = "";
            this.pBrandName = null;
            this.pStatus = "";
        },

        intializeEditParticipant(dataItems) {
            this.pId = dataItems.id;
            this.pEventName = dataItems.event;
            this.pFullName = dataItems.full_name;
            this.pContactNumber = dataItems.contact_number;
            this.pSalesAverage = dataItems.sales_avg;
            this.pBusinessIndustry = dataItems.niche_market;
            this.pBrandName = dataItems.brand_name;
            this.pStatus = dataItems.prospect_status;
        },
        async setStatus(leadDetail, newStatus) {
            const dataPass = {
                pId: leadDetail.id,
                pEventName: leadDetail.event,
                pFullName: leadDetail.full_name,
                pContactNumber: leadDetail.contact_number,
                pSalesAverage: leadDetail.sales_avg,
                pBusinessIndustry: leadDetail.niche_market,
                pBrandName: leadDetail.brand_name,
                pStatus: newStatus,
            };

            try {
                await participantEdit(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalParticipant();
                        this.loadListParticipant();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async loadListParticipant() {
            this.tableloader = true;

            try {
                await listParticipant().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData = response.data.data;
                        this.eventAvailable = response.data.events;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                        this.FilterHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deleteParticipant(id) {
            const dataPass = {
                pId: id,
            };

            try {
                await participantDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalParticipant();
                        this.loadListParticipant();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async saveParticipant() {
            const dataPass = {
                pId: this.pId,
                pEventName: this.pEventName,
                pFullName: this.pFullName,
                pContactNumber: this.pContactNumber,
                pSalesAverage: this.pSalesAverage,
                pBusinessIndustry: this.pBusinessIndustry,
                pBrandName: this.pBrandName,
                pStatus: this.pStatus,
            };

            if (dataPass.pId === null) {
                try {
                    await participantAdd(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalParticipant();
                            this.loadListParticipant();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await participantEdit(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalParticipant();
                            this.loadListParticipant();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },
    },
};
</script>
