<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
th,
td {
    min-width: 200px;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>Attendance</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <select
                                v-model="this.classSelected"
                                class="form-select form-control-primary"
                                name="select"
                                style="width: 100%"
                                @change="loadListAttendance()"
                            >
                                <option value="" disabled selected required>
                                    Select Class
                                </option>
                                <option
                                    v-for="item in this.classAvailable"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    {{ item.class_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="this.classSelected !== ''">
                    <div v-if="this.tableloader === false">
                        <div class="card">
                            <div class="p-4 d-flex" style="align-items: center">
                                <div
                                    class="col-2 d-flex"
                                    style="
                                        font-size: 1rem;
                                        font-weight: 500;
                                        align-items: center;
                                    "
                                >
                                    <span>Participant List</span>
                                </div>
                                <div class="col-9 d-flex">
                                    <div class="faq-form" style="width: 70%">
                                        <input
                                            class="form-control"
                                            v-model="inputText"
                                            @input="FilterHandling(inputText)"
                                            type="text"
                                            placeholder="Search name.."
                                        />
                                        <vue-feather
                                            class="search-icon"
                                            type="search"
                                        ></vue-feather>
                                    </div>
                                </div>
                                <!-- <div
                                class="col-1 d-flex"
                                style="cursor: pointer; align-items: center"
                            >
                                <div
                                    style="
                                        font-size: 1rem;
                                        font-weight: 450;
                                        padding-right: 10px;
                                    "
                                >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#7366FF"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                        />
                                    </svg>
                                </div>
                            </div> -->
                            </div>

                            <!-- DATA Table -->
                            <div>
                                <div class="row py-1 px-4">
                                    <div
                                        style="border-bottom: 1px solid #cccccc"
                                    >
                                        <div class="col-sm-10 row">
                                            <div
                                                style="cursor: pointer"
                                                class="p-3 col-sm-2"
                                                align="center"
                                                :class="{
                                                    selected:
                                                        selectedTab === '',
                                                }"
                                                @click="changeTab('')"
                                            >
                                                All
                                            </div>
                                            <div
                                                style="cursor: pointer"
                                                class="p-3 col-sm-2"
                                                align="center"
                                                :class="{
                                                    selected:
                                                        selectedTab === '1',
                                                }"
                                                @click="changeTab('1')"
                                            >
                                                Not Attend
                                            </div>
                                            <div
                                                style="cursor: pointer"
                                                class="p-3 col-sm-2"
                                                align="center"
                                                :class="{
                                                    selected:
                                                        selectedTab === '0',
                                                }"
                                                @click="changeTab('0')"
                                            >
                                                Attend
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="search-table-outter wrapper p-4"
                                    v-if="this.clientTableDataResult"
                                >
                                    <table
                                        class="search-table inner"
                                        style="width: 100%"
                                    >
                                        <tr>
                                            <th style="width: 5%">#</th>
                                            <th style="width: 10%"></th>
                                            <th style="width: 15%">Name</th>
                                            <th style="width: 10%">Contact</th>
                                            <th style="width: 10%">Position</th>
                                            <th style="width: 10%">
                                                T-Shirt Size
                                            </th>
                                            <th style="width: 10%">Company</th>
                                            <th style="width: 10%">Brand</th>
                                            <th
                                                style="
                                                    width: 10%;
                                                    text-align: center;
                                                "
                                            >
                                                Action
                                            </th>
                                        </tr>
                                        <tr
                                            v-for="(item, index) in this
                                                .clientTableDataResult"
                                            :key="index"
                                        >
                                            <td>{{ index + 1 }}.</td>
                                            <td>
                                                <div style="text-align: center">
                                                    <img
                                                        style="height: 40px"
                                                        class="img-fluid for-light"
                                                        :src="
                                                            item.pack_img_icon
                                                        "
                                                        alt="packagepage"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {{
                                                    item.full_name !== null
                                                        ? item.full_name
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    item.contact_number !== ""
                                                        ? item.contact_number
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    item.position !== ""
                                                        ? item.position
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    item.tshirt_size !== ""
                                                        ? item.tshirt_size
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    item.company !== null
                                                        ? item.company
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    item.brand_name !== null
                                                        ? item.brand_name
                                                        : "-"
                                                }}
                                            </td>
                                            <td style="min-width: 100px">
                                                <div
                                                    style="
                                                        display: flex;
                                                        justify-content: center;
                                                    "
                                                >
                                                    <div
                                                        v-if="
                                                            item.attendance_status ===
                                                            '1'
                                                        "
                                                        class="p-1"
                                                        align="center"
                                                        @click="
                                                            intializeEditAttendance(
                                                                item,
                                                                'attend'
                                                            )
                                                        "
                                                        style="
                                                            cursor: pointer;
                                                            width: 80%;
                                                            background-color: #54ba4a;
                                                            border-radius: 5px;
                                                            color: #fefefe;
                                                        "
                                                    >
                                                        Attend
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="p-1"
                                                        align="center"
                                                        @click="
                                                            intializeEditAttendance(
                                                                item,
                                                                'cancel'
                                                            )
                                                        "
                                                        style="
                                                            cursor: pointer;
                                                            width: 80%;
                                                            background-color: #dc3545;
                                                            border-radius: 5px;
                                                            color: #fefefe;
                                                        "
                                                    >
                                                        Cancel
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <!-- EMPTY DATA -->
                                <div
                                    v-else
                                    align="center"
                                    style="height: 300px"
                                >
                                    <img
                                        style="
                                            border-radius: 5px;
                                            width: 240px;
                                            height: 220px;
                                        "
                                        alt=""
                                        src="../../assets/images/no_data_image.png"
                                    />
                                    <h6>No Data</h6>
                                </div>
                            </div>

                            <!-- TABLE PAGINATION -->
                            <div class="row pagination" v-if="maxPage > 1">
                                <div class="col-md-7 col-sm-0 p-3">
                                    <div
                                        class="inactivePage"
                                        style="padding-left: 30px"
                                    >
                                        {{
                                            "Page " +
                                            this.currentPage +
                                            " out of " +
                                            this.maxPage
                                        }}
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-12 p-3">
                                    <div
                                        class="d-flex"
                                        style="
                                            padding-right: 30px;
                                            justify-content: flex-end;
                                        "
                                    >
                                        <button
                                            class="p-1"
                                            :class="{
                                                activeButtonPage:
                                                    this.currentPage != '1',
                                                inactiveButtonPage:
                                                    this.currentPage == '1',
                                            }"
                                            @click="prevPage()"
                                            style="
                                                width: 30%;
                                                border-top-left-radius: 8px;
                                                border-bottom-left-radius: 8px;
                                                border: 1px solid #dee2e6;
                                            "
                                        >
                                            Previous
                                        </button>
                                        <div
                                            @click="setFirstPage()"
                                            v-if="this.currentPage != 1"
                                            class="inactivePage p-1"
                                            align="center"
                                            style="
                                                cursor: pointer;
                                                width: 10%;
                                                border: 1px solid #dee2e6;
                                            "
                                        >
                                            ...
                                        </div>
                                        <div
                                            @click="goToPage(pageNumber)"
                                            v-for="pageNumber in limitedPages"
                                            :key="pageNumber"
                                            :class="{
                                                activePage:
                                                    currentPage === pageNumber,
                                                inactivePage:
                                                    currentPage !== pageNumber,
                                            }"
                                            class="p-1"
                                            align="center"
                                            style="
                                                cursor: pointer;
                                                width: 10%;
                                                border: 1px solid #dee2e6;
                                            "
                                        >
                                            {{ pageNumber }}
                                        </div>
                                        <div
                                            @click="setLastPage()"
                                            v-if="
                                                this.currentPage != this.maxPage
                                            "
                                            class="inactivePage p-1"
                                            align="center"
                                            style="
                                                cursor: pointer;
                                                width: 10%;
                                                border: 1px solid #dee2e6;
                                            "
                                        >
                                            ...
                                        </div>
                                        <button
                                            class="p-1"
                                            :class="{
                                                activeButtonPage:
                                                    currentPage === '1' &&
                                                    currentPage !==
                                                        this.maxPage,
                                                inactiveButtonPage:
                                                    currentPage ===
                                                    this.maxPage,
                                            }"
                                            @click="nextPage()"
                                            style="
                                                width: 30%;
                                                border-top-right-radius: 8px;
                                                border-bottom-right-radius: 8px;
                                                border: 1px solid #dee2e6;
                                            "
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else style="padding-top: 30px">
                        <h6 class="mb-0 text-center">Please Wait...</h6>
                        <div class="loader-box">
                            <div class="loader-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listClass,
    listAttendance,
    attendanceAdd,
    attendanceEdit,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Front Desk",

    data() {
        return {
            searchText: "",
            selectedTab: "",
            clientTableData: null,
            clientTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,

            classAvailable: [],
            classSelected: "",
            clientId: null,
        };
    },

    beforeMount() {
        this.loadClassAvailable();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift
            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);
            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }
            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.attendance_status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.clientTableDataResult = this.clientTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult =
                        this.clientTableDataResult.filter(
                            (item) =>
                                (item.full_name &&
                                    item.full_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.contact_number &&
                                    item.contact_number
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.company &&
                                    item.company
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.brand_name &&
                                    item.brand_name
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.attendance_status === this.selectedTab
                );
            } else {
                this.clientTableDataResult = this.clientTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.clientTableDataResult = this.clientTableDataResult.filter(
                    (item) =>
                        (item.full_name &&
                            item.full_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.contact_number &&
                            item.contact_number
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.company &&
                            item.company.toLowerCase().includes(searchText)) || // Check if full_name is not null
                        (item.brand_name &&
                            item.brand_name.toLowerCase().includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.clientTableDataResult = null;
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        clearModalAttendance() {
            this.clientId = null;
        },

        intializeEditAttendance(dataTable, status) {
            this.clientId = dataTable.client_id;
            if (status === "attend") {
                this.updateAttendance();
            } else {
                this.deleteAttendance(dataTable.attend_id);
            }
        },

        async loadClassAvailable() {
            try {
                await listClass().then((response) => {
                    if (response.data.message === "success") {
                        this.classAvailable = response.data.data;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async loadListAttendance() {
            this.tableloader = true;
            const dataPass = {
                classId: this.classSelected,
            };

            try {
                await listAttendance(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData = response.data.list;
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                        this.FilterHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async updateAttendance() {
            const dataPass = {
                classId: this.classSelected,
                clientId: this.clientId,
            };

            try {
                await attendanceAdd(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalAttendance();
                        this.loadListAttendance();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async deleteAttendance(id) {
            const dataPass = {
                attendId: id,
            };

            try {
                await attendanceEdit(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalAttendance();
                        this.loadListAttendance();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
