export var menuItems = {
    data: [
        //
        //
        //
        // Dashboard
        {
            headTitle1: "General",
            headTitle2: "Dashboard Menu",
            type: "headtitle",
        },
        {
            title: "Dashboard",
            icon: "stroke-home",
            iconf: "fill-home",
            type: "sub",
            badgeType: "light-primary",
            active: false,
            children: [
                {
                    title: "Schedule",
                    path: "/dashboard/schedule",
                    type: "link",
                    active: false,
                },
                {
                    title: "Quick Status*",
                    path: "/dashboard/quick-status",
                    type: "link",
                    active: false,
                },
            ],
        },
        //
        //
        //
        //
        // SETTING MENU
        {
            headTitle1: "Setting",
            headTitle2: "setting menu",
            type: "headtitle",
        },
        {
            title: "User & Permission",
            path: "/setting/user-permission",
            icon: "stroke-user",
            iconf: "fill-user",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Scheduler",
            path: "/setting/scheduler",
            icon: "stroke-calendar",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Ads*",
            path: "/setting/sads",
            icon: "stroke-social",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Event*",
            path: "/setting/sevent",
            icon: "stroke-email",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Package",
            path: "/setting/package",
            icon: "stroke-animation",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Class",
            path: "/setting/class",
            icon: "stroke-to-do",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        //
        //
        //
        //
        // Client
        {
            headTitle1: "CLIENT",
            headTitle2: "client menu",
            type: "headtitle",
        },
        {
            title: "Client",
            path: "/client/detail",
            icon: "stroke-builders",
            iconf: "fill-social",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Participant",
            path: "/client/participant",
            icon: "stroke-ui-kits",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        {
            title: "Monitoring*",
            path: "/client/monitoring",
            icon: "stroke-charts",
            iconf: "fill-form",
            type: "link",
            badgeType: "light-primary",
            active: false,
        },
        //
        //
        //
        //
        // Front desk
        {
            headTitle1: "FRONT DESK",
            headTitle2: "front desk menu",
            type: "headtitle",
        },
        {
            title: "Attendance",
            path: "/front-desk/attendance",
            icon: "stroke-table",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        //
        //
        //
        //
        // Reporting
        {
            headTitle1: "REPORTING",
            headTitle2: "reporting menu",
            type: "headtitle",
        },
        {
            title: "Event Performance*",
            path: "/report/event",
            icon: "stroke-sample-page",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Ads Performance*",
            path: "/report/ads",
            icon: "stroke-blog",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        {
            title: "Attendances*",
            path: "/report/attendances",
            icon: "stroke-internationalization",
            iconf: "fill-file",
            type: "link",
            active: false,
        },
        //
        //
        //
        //
        // Spacer
        {
            headTitle1: "",
            headTitle2: "",
            type: "headtitle",
        },
        {
            headTitle1: "",
            headTitle2: "",
            type: "headtitle",
        },
    ],
};
