<template>
    <Breadcrumbs title="Event Performance" />
    <div class="container-fluid calendar-basic">
        <div class="">
            <div class="comingsoon-inner text-center">
                <img
                    height="300"
                    style="padding-bottom: 20px"
                    class="img-fluid for-light"
                    src="../../assets/images/logo/logo.png"
                    alt="looginpage"
                />
                <h5>COMING SOON...</h5>
            </div>
        </div>
    </div>
</template>

<script></script>
