<template>
  <select class="form-select form-control-primary" name="select" v-model="selectedState" @change="emitState" :data-selected="selectedOption">
    <option value="" disabled>Select State</option>
    <option v-for="(state, index) in states" :key="index" :value="state">{{ state }}</option>
  </select>
</template>

<script>
export default {
    props: ['selectedOption'],
    data() {
    return {
        selectedState: '',
        states: [
        'Johor',
        'Kedah',
        'Kelantan',
        'Melaka',
        'Negeri Sembilan',
        'Pahang',
        'Perak',
        'Perlis',
        'Pulau Pinang',
        'Sabah',
        'Sarawak',
        'Selangor',
        'Terengganu',
        'Wilayah Persekutuan Putrajaya',
        'Wilayah Persekutuan Kuala Lumpur',
        'Wilayah Persekutuan Labuan',
        ]
    };
    },
    updated: function() {
        if(this.selectedOption) {
            this.selectedState = this.selectedOption
        }
    },
    methods: {
        emitState() {
            this.$emit('state-selected', this.selectedState);
        }
    }
};
</script>
