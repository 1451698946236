<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
} */

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid calendar-basic">
        <div class="p-2" align="right">
            <button
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addEvent"
            >
                <i class="fa fa-plus"></i> Add Event
            </button>
            <!-- POPUP ADD SCHEDULER -->
            <div class="modal fade modal-bookmark" id="addEvent" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title"> <span>Add</span> </h3>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div style=" max-height: 60vh; overflow: scroll; overflow-x: hidden;">
                            <div class="modal-body row">
                                <!-- FILTERING TAB POPUP-->
                                <div class="col-sm-12 row" >
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{selected: selectedTab2 === '0'}" @click="changeTab2('0')">
                                        Seminar
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{selected: selectedTab2 === '1'}" @click="changeTab2('1')">
                                        Class
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{selected: selectedTab2 === '2'}" @click="changeTab2('2')">
                                        One On One
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{selected: selectedTab2 === '3'}" @click="changeTab2('3')">
                                        Event
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{selected: selectedTab2 === '4'}" @click="changeTab2('4')">
                                        Marketing Planning
                                    </div>
                                </div>
                                <div style="height: 10px"></div>
                                <div align="left">
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Date Start</div>
                                        <input v-model="this.schedulerDateStart" class="form-control" type="datetime-local" placeholder="01/06/2024"/>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Date End</div>
                                        <input  v-model="this.schedulerDateEnd" class="form-control" type="datetime-local" placeholder="01/06/2024"/>
                                    </div>
                                    <div v-if="this.selectedTab2 == '2'" class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Client</div>
                                        <select v-model="this.schedulerClient" class="form-select form-control-primary" name="select" required>
                                            <option value="" disabled selected required>Select Client</option>
                                            <option v-for="item in this.clientAvailable" :key="item.client_id" :value="item.client_id">
                                                {{ item.company }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Title</div>
                                        <input v-model="this.schedulerTitle" class="form-control" type="text" placeholder="Insert Title"/>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450">
                                            <span v-if="this.selectedTab2 == '4'" >Talent</span><span v-else>Speaker</span>
                                        </div> 
                                        <select v-model="this.schedulerSpeaker" class="form-select form-control-primary" name="select" required>
                                            <option value="" disabled selected required>
                                                Select
                                                <span  v-if=" this.selectedTab2 == '4'">Talent</span><span v-else>Speaker</span>
                                            </option>
                                            <option v-for="item in this.speakerAvailable" :key="item.usr_main_id" :value="item.usr_main_id">
                                                {{ item.first_name + " " + item.last_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450">Location</div>
                                        <input v-model="this.schedulerLocation" class="form-control" type="text" placeholder="Dewan Gemilang Taman Pasuk"/>
                                    </div>
                                    <div v-if="this.selectedTab2 != '4'" class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Details</div>
                                        <textarea v-model="this.schedulerDetail" class="form-control" type="text" placeholder="Insert Details"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row p-3">
                            <div class="col-12 d-flex" style="justify-content: flex-end">
                                <div>
                                    <button @click="saveSchedulerData()" data-bs-dismiss="modal" class="btn btn-color" type="button"
                                        style=" width: 100%;color: #fefefe;background-color: #04318c !important;border-color: #04318c !important;">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- POPUP EVENT DETAILS -->
            <div id="myModal" class="modal2">
                <div class="modal-content2">
                    <div class="modal-header">
                        <h3 class="modal-title"> <span>Event details</span></h3>
                        <button class="btn-close" type="button" @click="closePopup()" aria-label="Close"></button>
                    </div>
                    <div style=" max-height: 60vh; overflow: scroll; overflow-x: hidden;" >
                        <div class="modal-body row" align="center">
                            <div style="height: 10px"></div>
                            <div align="left">
                                <div class="py-2 col-12 d-flex" style="font-size: 1rem">
                                    <div style="font-weight: 450; width: 20%;">Title </div>
                                    <div style="width: 80%;" align="left">{{ this.popup_title || '-' }}</div>
                                </div>
                                <div class="py-2 col-12 d-flex" style="font-size: 1rem">
                                    <div style="font-weight: 450; width: 20%;">Location </div>
                                    <div style="width: 80%;" align="left">{{ this.popup_location || '-' }}</div>
                                </div>
                                <div class="py-2 col-12 d-flex" style="font-size: 1rem">
                                    <div style="font-weight: 450; width: 20%;">Date & time </div>
                                    <div style="width: 80%;" align="left">{{ (this.popup_start && this.popup_end) ? (this.popup_start + ' - ' + this.popup_end) : '-' }}</div>
                                </div>
                                <div class="py-2 col-12 d-flex" style="font-size: 1rem">
                                    <div style="font-weight: 450; width: 20%;">Details </div>
                                    <div style="width: 80%;" align="left">{{ this.popup_detail || '-' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12" align="center">
                            <button @click="clearPopupDetail(),closePopup()" class="btn btn-color" type="button" style=" width: 60%; color: #fefefe; background-color: #04318c !important;border-color: #04318c !important;">Got It!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" id="wrap">
            <div class="col-xxl-3" style="height: auto">
                <div class="card">
                    <div class="p-3 fs-5">List</div>
                    <div style="border-bottom: 1px solid #c5c5c5"></div>
                    <div class="p-3">
                        <div class="btn btn-primary mb-2 w-100 text-start d-flex flex-row"  style="padding: 7px">
                            <div style="width: 90%">Seminar</div>
                            <div style="width: 10%">
                                <div class="round">
                                    <input type="checkbox" id="0" v-model="filteredCids" value="0" @change="filterEvents()" />
                                    <label for="0"></label>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-danger mb-2 w-100 text-start d-flex flex-row" style="padding: 7px">
                            <div style="width: 90%">Class</div>
                            <div style="width: 10%">
                                <div class="round">
                                    <input type="checkbox" id="1" v-model="filteredCids" value="1" @change="filterEvents()" />
                                    <label for="1"></label>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-success mb-2 w-100 text-start d-flex flex-row" style="padding: 7px">
                            <div style="width: 90%">One On One</div>
                            <div style="width: 10%">
                                <div class="round">
                                    <input type="checkbox" id="2" v-model="filteredCids" value="2" @change="filterEvents()" />
                                    <label for="2"></label>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-info mb-2 w-100 text-start d-flex flex-row" style="padding: 7px" >
                            <div style="width: 90%">Event</div>
                            <div style="width: 10%">
                                <div class="round">
                                    <input type="checkbox" id="3" v-model="filteredCids" value="3" @change="filterEvents()" />
                                    <label for="3"></label>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-warning mb-2 w-100 text-start d-flex flex-row" style="padding: 7px" >
                            <div style="width: 90%">Marketing Planning</div>
                            <div style="width: 10%">
                                <div class="round">
                                    <input type="checkbox" id="4" v-model="filteredCids" value="4" @change="filterEvents()" />
                                    <label for="4"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-9">
                <div class="card">
                    <div class="card-body">
                        <div class="row" id="wrap">
                            <div class="w-full">
                                <div class="calendar-default" id="calendar-container" >
                                <FullCalendar ref='fullCalendar'  id="calendarFull" :options="calendarOptionsFull" @eventClick="handleEventClick" style="cursor:pointer;"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { events } from "../data/draggable";
import { schedulerAdd, listClient, listScheduler } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import { ref } from "vue";

export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            selectedTab2: "0",
            clientAvailable: null,
            speakerAvailable: null,
            calendarOptionsSmall: {
                plugins: [dayGridPlugin],
                initialView: "dayGridMonth",
                headerToolbar: {
                    left: "title",
                    center: "",
                    right: "prev,next",
                },
                height: 280,
                fixedWeekCount: false,
                editable: false,
            },
            calendarOptionsFull: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                initialView: "dayGridMonth",
                dateClick: this.handleDateClick,
                initialEvents: [],
                editable: false,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                events: events,
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                },
                eventClick: function(info) {
                    this.popup_title= info.event.title,
                    this.popup_start= this.formatDate(info.event.extendedProps.eventStart),
                    this.popup_end= this.formatDate(info.event.extendedProps.eventEnd),
                    this.popup_detail= info.event.extendedProps.details,
                    this.popup_location = info.event.extendedProps.eventLocation,

                    document.getElementById("myModal").style.display = "block";
                    console.log(info.event.title);
                    console.log(info.event.extendedProps.details);
                    console.log(this.formatDate(info.event.extendedProps.eventStart));
                    console.log(this.formatDate(info.event.extendedProps.eventEnd));
                }.bind(this)
            },
            lista: events,
            modalShow: false,
            filteredCids: ['0', '1', '2', '3', '4'],
            filteredScheduleData: [],
            selectedEvent: null,

            scheduleData: null,
            schedulerDetail: null,
            schedulerLocation: null,
            schedulerSpeaker: "",
            schedulerClient: "",
            schedulerTitle: null,
            schedulerBrand: null,
            schedulerName: null,
            schedulerDateStart: null,
            schedulerDateEnd: null,

            popup_title: null,
            popup_start: null,
            popup_end: null,
            popup_detail: null,
            popup_location: null,
        };
    },
    computed: {
        filteredEvents() {
            return this.lista.filter((event) => this.filteredCids[event.cid]);
        },
    },
    mounted() {
        this.loadScheduleData();
    },
    created() {
        console.log(this.calendarOptionsFull.initialEvents);
    },
    methods: {
        //FILTER CHANGE TAB
        changeTab2(tabProgressName) {
            this.selectedTab2 = tabProgressName;
        },
        handleDatesSet(info) {
            this.calendarOptionsSmall.initialDate = info.start;
        },
        filterEvents() {
            const filteredCidsArray = this.filteredCids.map(Number);
            // Filter scheduleData based on filteredCidsArray
            this.filteredScheduleData = this.scheduleData.filter((item) => {
                return filteredCidsArray.includes(item.groupId);
            });
            this.calendarOptionsFull.events = this.filteredScheduleData;
        },
        formatDate(inputDateStr) {
            // Create a new Date object from the input string
            const inputDate = new Date(inputDateStr);

            // Extract year, month, and day components from the Date object
            const year = inputDate.getFullYear();
            const month = inputDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 = January)
            const day = inputDate.getDate();

            // Extract hours, minutes, and AM/PM notation
            let hours = inputDate.getHours();
            const minutes = inputDate.getMinutes();
            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            // Convert hours from 24-hour to 12-hour format
            hours = hours % 12 || 12; // Convert 0 hours to 12 in 12-hour format

            // Pad single-digit month, day, and minutes values with leading zeros
            const formattedMonth = month.toString().padStart(2, '0');
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            // Construct the formatted time string in hh:mm am/pm format
            const formattedTime = `${hours}:${formattedMinutes} ${amOrPm}`;

            // Construct the formatted date string in dd/mm/yyyy format
            const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedTime}`;

            return formattedDate;
        },
        clearPopupDetail() {
            this.popup_title= null;
            this.popup_start= null;
            this.popup_end= null;
            this.popup_detail= null;
            this.popup_location = null;
        },
        closePopup(){
            document.getElementById("myModal").style.display = "none";
        },

        // API SCHEDULER
        clearModalEvent() {
            this.schedulerDetail = null;
            this.schedulerLocation = null;
            this.schedulerSpeaker = "";
            this.schedulerClient = "";
            this.schedulerTitle = null;
            this.schedulerBrand = null;
            this.schedulerName = null;
            this.selectedTab2 = "0";
            this.schedulerDateStart = null;
            this.schedulerDateEnd = null;
        },
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
        async loadScheduleData() {
            try {
                await listClient().then((response) => {
                    this.clientAvailable = response.data.data;
                });
                await listScheduler().then((response) => {
                    if (response.data.message === "success") {
                        this.speakerAvailable = response.data.speaker;
                        this.scheduleData = response.data.data.map((event) => {
                            // Define a mapping of status values to background colors
                            const statusColorMap = {
                                0: "#7366ff",
                                1: "#dc3545",
                                2: "#198754",
                                3: "#0dcaf0",
                                4: "#ffc107",
                            };

                            return {
                                id: event.scheduler_id,
                                title: event.event_title,
                                start: event.event_time_start,
                                end: event.event_time_end,
                                backgroundColor: statusColorMap[event.schedule_status],
                                details: event.event_detail,
                                eventStart: event.event_time_start,
                                eventEnd: event.event_time_end,
                                eventLocation: event.event_location,
                                // convert groupId value from string to integer for Data filtering comparison 
                                groupId: parseInt(event.schedule_status),
                            };
                        });
                        this.calendarOptionsFull.events = this.scheduleData;
                        let calendarApi = this.$refs.fullCalendar.getApi();

                        console.log(calendarApi.getEvents())
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async saveSchedulerData() {
            const dataPass = {
                schedulerId: this.schedulerId,
                eventDetail: this.schedulerDetail,
                eventLocation: this.schedulerLocation,
                speakerId: this.schedulerSpeaker,
                eventTitle: this.schedulerTitle,
                schedulerBrand: this.schedulerBrand,
                schedulerName: this.schedulerName,
                scheduleStatus: this.selectedTab2,
                eventTimeStart: this.schedulerDateStart,
                eventTimeEnd: this.schedulerDateEnd,
            };

            try {
                await schedulerAdd(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalEvent();
                        this.notificationSuccess("Data Added!");
                        this.loadScheduleData();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
