import routers from "../../router/index";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://api.hasbulbrothers.com.my/sys",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
        "Content-Type": "application/json",
        referrerPolicy: "no-referrer",
        Accept: "*/*",
    },
});
//
//
//
//
//
//
//
//
//
// auth
export const login = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/login/auth", {
            email: dataPass.email,
            password: dataPass.password,
            remember: dataPass.remember,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const logout = async () => {
    try {
        const $response = await axiosInstance.post(
            "/logout/auth",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// permission
export const listPermission = async () => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/add",
            {
                permissionName: dataPass.permissionName,
                permissionPrivilege: dataPass.permissionPrivilege,
                permissionStatus: dataPass.permissionStatus,
                permissionTrainerStatus: dataPass.permissionTrainerStatus,
                permissionSellerStatus: dataPass.permissionSellerStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/edit",
            {
                permissionId: dataPass.permissionId,
                permissionName: dataPass.permissionName,
                permissionPrivilege: dataPass.permissionPrivilege,
                permissionStatus: dataPass.permissionStatus,
                permissionTrainerStatus: dataPass.permissionTrainerStatus,
                permissionSellerStatus: dataPass.permissionSellerStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/delete",
            {
                permissionId: dataPass.permissionId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// user
export const listUser = async () => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/add",
            {
                permissionFirstName: dataPass.permissionFirstName,
                permissionLastName: dataPass.permissionLastName,
                permissionPassword: dataPass.permissionPassword,
                permissionEmail: dataPass.permissionEmail,
                permissionBrithday: dataPass.permissionBrithday,
                permissionContactNumber: dataPass.permissionContactNumber,
                permissionUserPermission: dataPass.permissionUserPermission,
                permissionUserStatus: dataPass.permissionUserStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/edit",
            {
                permissionUserMainId: dataPass.permissionUserMainId,
                permissionUserDetailId: dataPass.permissionUserDetailId,
                permissionUserLoginId: dataPass.permissionUserLoginId,
                permissionFirstName: dataPass.permissionFirstName,
                permissionLastName: dataPass.permissionLastName,
                permissionPassword: dataPass.permissionPassword,
                permissionEmail: dataPass.permissionEmail,
                permissionBrithday: dataPass.permissionBrithday,
                permissionContactNumber: dataPass.permissionContactNumber,
                permissionUserPermission: dataPass.permissionUserPermission,
                permissionUserStatus: dataPass.permissionUserStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/delete",
            {
                userId: dataPass.userId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// scheduler
export const listScheduler = async () => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/add",
            {
                eventDetail: dataPass.eventDetail,
                eventLocation: dataPass.eventLocation,
                speakerId: dataPass.speakerId,
                eventTitle: dataPass.eventTitle,
                schedulerBrand: dataPass.schedulerBrand,
                schedulerName: dataPass.schedulerName,
                scheduleStatus: dataPass.scheduleStatus,
                eventTimeStart: dataPass.eventTimeStart,
                eventTimeEnd: dataPass.eventTimeEnd,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/edit",
            {
                schedulerId: dataPass.schedulerId,
                eventDetail: dataPass.eventDetail,
                eventLocation: dataPass.eventLocation,
                speakerId: dataPass.speakerId,
                eventTitle: dataPass.eventTitle,
                schedulerBrand: dataPass.schedulerBrand,
                schedulerName: dataPass.schedulerName,
                scheduleStatus: dataPass.scheduleStatus,
                eventTimeStart: dataPass.eventTimeStart,
                eventTimeEnd: dataPass.eventTimeEnd,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/delete",
            {
                schedulerId: dataPass.schedulerId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// package
export const listPackage = async () => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const packageAdd = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/add",
            {
                photo1: formData.photo1,
                photo2: formData.photo2,
                packageName: formData.packageName,
                packagePrice: formData.packagePrice,
                packageDetail: formData.packageDetail,
                packageClass: formData.packageClass,
                packageIntakeStart: formData.packageIntakeStart,
                packageIntakeEnd: formData.packageIntakeEnd,
                packageStatus: formData.packageStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const packageEdit = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/edit",
            {
                photo1: formData.photo1,
                photo2: formData.photo2,
                packageId: formData.packageId,
                packageName: formData.packageName,
                packagePrice: formData.packagePrice,
                packageDetail: formData.packageDetail,
                packageClass: formData.packageClass,
                packageIntakeStart: formData.packageIntakeStart,
                packageIntakeEnd: formData.packageIntakeEnd,
                packageStatus: formData.packageStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const packageDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/delete",
            {
                packageId: dataPass.packageId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// class
export const listClass = async () => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classAdd = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/add",
            {
                className: formData.className,
                classBadge: formData.classBadge,
                classCapacity: formData.classCapacity,
                classDetails: formData.classDetails,
                classDateStart: formData.classDateStart,
                classDateEnd: formData.classDateEnd,
                classTrainer: formData.classTrainer,
                classLocation: formData.classLocation,
                classStatus: formData.classStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classEdit = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/edit",
            {
                classId: formData.classId,
                className: formData.className,
                classBadge: formData.classBadge,
                classCapacity: formData.classCapacity,
                classDetails: formData.classDetails,
                classDateStart: formData.classDateStart,
                classDateEnd: formData.classDateEnd,
                classTrainer: formData.classTrainer,
                classLocation: formData.classLocation,
                classStatus: formData.classStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classDelete = async (classId) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/delete",
            {
                classId: classId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// participant
export const listParticipant = async () => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/add",
            {
                pEventName: dataPass.pEventName,
                pFullName: dataPass.pFullName,
                pContactNumber: dataPass.pContactNumber,
                pSalesAverage: dataPass.pSalesAverage,
                pBusinessIndustry: dataPass.pBusinessIndustry,
                pBrandName: dataPass.pBrandName,
                pStatus: dataPass.pStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/edit",
            {
                pId: dataPass.pId,
                pEventName: dataPass.pEventName,
                pFullName: dataPass.pFullName,
                pContactNumber: dataPass.pContactNumber,
                pSalesAverage: dataPass.pSalesAverage,
                pBusinessIndustry: dataPass.pBusinessIndustry,
                pBrandName: dataPass.pBrandName,
                pStatus: dataPass.pStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/delete",
            {
                pId: dataPass.pId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
//
//
//
//
//
// front desk
export const listAttendance = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/view",
            {
                classId: dataPass.classId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const attendanceAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/add",
            {
                classId: dataPass.classId,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const attendanceEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/edit",
            {
                attendId: dataPass.attendId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// client list
export const listClient = async () => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/add",
            {
                participantId: dataPass.participantId,
                personal_detail: {
                    full_name: dataPass.personal_detail.full_name,
                    ic_number: dataPass.personal_detail.ic_number,
                    contact_number: dataPass.personal_detail.contact_number,
                },
                company: {
                    company_name: dataPass.company.company_name,
                    position: dataPass.company.position,
                    business_industry: dataPass.company.business_industry,
                    brand_name: dataPass.company.brand_name,
                    ssm_no: dataPass.company.ssm_no,
                    address_detail: dataPass.company.address_detail,
                    postcode: dataPass.company.postcode,
                    state: dataPass.company.state,
                    city: dataPass.company.city,
                    url_soc_twitter: dataPass.company.url_soc_twitter,
                    url_soc_fb: dataPass.company.url_soc_fb,
                    url_soc_instagram: dataPass.company.url_soc_instagram,
                    url_soc_tiktok: dataPass.company.url_soc_tiktok,
                },
                package: {
                    package_name: dataPass.package.package_name,
                    period_intake: dataPass.package.period_intake,
                    period_end: dataPass.package.period_end,
                    client_status: dataPass.package.client_status,
                    sales_person: dataPass.package.sales_person,
                    payment_status: dataPass.package.payment_status,
                    payment_amount: dataPass.package.payment_amount,
                    shirt_size: dataPass.package.shirt_size,
                    deposit_remarks: dataPass.package.deposit_remarks,
                    deposit_date: dataPass.package.deposit_date,
                    deposit_amount: dataPass.package.deposit_amount,
                },
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/edit",
            {
                clientId: dataPass.clientId,
                participantId: dataPass.participantId,
                personal_detail: {
                    full_name: dataPass.personal_detail.full_name,
                    ic_number: dataPass.personal_detail.ic_number,
                    contact_number: dataPass.personal_detail.contact_number,
                },
                company: {
                    company_name: dataPass.company.company_name,
                    position: dataPass.company.position,
                    business_industry: dataPass.company.business_industry,
                    brand_name: dataPass.company.brand_name,
                    ssm_no: dataPass.company.ssm_no,
                    address_detail: dataPass.company.address_detail,
                    postcode: dataPass.company.postcode,
                    state: dataPass.company.state,
                    city: dataPass.company.city,
                    url_soc_twitter: dataPass.company.url_soc_twitter,
                    url_soc_fb: dataPass.company.url_soc_fb,
                    url_soc_instagram: dataPass.company.url_soc_instagram,
                    url_soc_tiktok: dataPass.company.url_soc_tiktok,
                },
                package: {
                    package_name: dataPass.package.package_name,
                    period_intake: dataPass.package.period_intake,
                    period_end: dataPass.package.period_end,
                    client_status: dataPass.package.client_status,
                    sales_person: dataPass.package.sales_person,
                    payment_status: dataPass.package.payment_status,
                    payment_amount: dataPass.package.payment_amount,
                    shirt_size: dataPass.package.shirt_size,
                    deposit_remarks: dataPass.package.deposit_remarks,
                    deposit_date: dataPass.package.deposit_date,
                    deposit_amount: dataPass.package.deposit_amount,
                },
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/delete",
            {
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const uploadProgressCSV = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/import/progress",
            {
                csv: formData.csv,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const uploadSalesCSV = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/import/sales",
            {
                csv: formData.csv,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

// Instant update status
// export const clientStatusUpdate = async (dataPass) => {
//     try {
//         const $response = await axiosInstance.post(
//             "/client-management/list/status",
//             {
//                 client_id: dataPass.clientId,
//                 client_status: dataPass.clientStatus,
//             },
//             {
//                 headers: {
//                     authorization: localStorage.getItem("token_credential"),
//                 },
//             }
//         );
//         return $response;
//     } catch (error) {
//         if (error.response.data.message === "Unauthorized") {
//             localStorage.removeItem("token_expired");
//             localStorage.removeItem("token_credential");
//             routers.push("/auth/login");
//         }
//     }
// };
//
//
//
//
//
//
//
//
//
// client profile
export const clientProfileDetail = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/dashboard/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const uploadProfilePic = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/profile_pic/edit",
            {
                profile_pic: formData.profile_pic,
                client_id: formData.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const addPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/add",
            {
                client_id: dataPass.client_id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks,
                payment_iteration: dataPass.payment_iteration,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const editPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/edit",
            {
                client_id: dataPass.client_id,
                payment_id: dataPass.id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const deletePaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/delete",
            {
                payment_id: dataPass.payment_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// sales tab client profile
export const clientProfileSales = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addYear = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/add",
            {
                client_id: dataPass.client_id,
                year: dataPass.year,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editYear = async (dataPass) => {
    console.log(dataPass);
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/edit",
            {
                monthSales: dataPass.months,
                year: dataPass.year,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteYear = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/delete",
            {
                clientId: dataPass.clientId,
                year: dataPass.year,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/add",
            {
                client_id: dataPass.client_id,
                current_issues: dataPass.current_issues,
                date: dataPass.date,
                role: dataPass.role,
                solution: dataPass.solution,
                speaker: dataPass.speaker,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/edit",
            {
                progress_id: dataPass.progress_id,
                client_id: dataPass.client_id,
                current_issues: dataPass.current_issues,
                date: dataPass.date,
                role: dataPass.role,
                solution: dataPass.solution,
                speaker: dataPass.speaker,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/delete",
            {
                progress_id: dataPass.progress_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const successStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/add",
            {
                client_id: dataPass.client_id,
                date: dataPass.date,
                details: dataPass.details,
                title: dataPass.title,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/edit",
            {
                client_id: dataPass.client_id,
                date: dataPass.date,
                details: dataPass.details,
                story_id: dataPass.story_id,
                title: dataPass.title,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/delete",
            {
                client_id: dataPass.client_id,
                story_id: dataPass.story_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


//
//
//
//
//
//
//
//
//
// photo tab client profile
export const successPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addSuccessPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/add",
            {
                category: dataPass.category,
                new_category: dataPass.new_category,
                photo: dataPass.photo,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteSuccessPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/delete",
            {
                photoId: dataPass.photo_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// photo tab client profile
export const attendanceRecord = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/attendance/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// photo tab client profile
export const btmValue = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/btm/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addBtmValue = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/btm/edit",
            {
                client_id: dataPass.client_id,
                value1: dataPass.value1,
                value2: dataPass.value2,
                value3: dataPass.value3,
                value4: dataPass.value4,
                value5: dataPass.value5,
                value6: dataPass.value6,
                value7: dataPass.value7,
                value8: dataPass.value8,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
