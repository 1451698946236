<template>
    <li class="profile-nav onhover-dropdown pe-0 py-0">
        <div class="media profile-media">
            <img
                class="b-r-10"
                style="height: 35px; width: 35px"
                src="@/assets/images/dashboard/cartoon.svg"
                alt=""
            />
            <div class="media-body">
                <span>{{ this.user.name.value }}</span>
                <p class="mb-0">
                    {{ this.user.role.value }}
                    <i class="middle fa fa-angle-down"></i>
                </p>
            </div>
        </div>
        <ul class="profile-dropdown onhover-show-div">
            <!-- <li>
                <router-link :to="'/users/profile'"
                    ><vue-feather type="user"></vue-feather
                    ><span>Account </span></router-link
                >
            </li> -->
            <li>
                <a @click="userLogout()"
                    ><vue-feather type="log-in"></vue-feather
                    ><span>Log out</span></a
                >
            </li>
        </ul>
    </li>
</template>

<script>
import { logout } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Profile",

    data() {
        return {
            user: {
                name: {
                    value: localStorage.getItem("first_name"),
                },
                role: {
                    value: localStorage.getItem("access_role"),
                },
            },
        };
    },

    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },

        async userLogout() {
            try {
                await logout().then((response) => {
                    if (response.data.message === "success") {
                        localStorage.removeItem("token_expired");
                        localStorage.removeItem("token_credential");
                        this.$router.push("/auth/login");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
