<script>
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";

import Notification_Bar from "./notification_bar.vue";
import Language from "./language";
import Mode from "./mode";
import Notifications from "./notifications.vue";
import Setting from "./setting.vue";
import Profile from "./profile";

export default {
    components: {
        Notification_Bar,
        Language,
        Mode,
        Setting,
        Notifications,
        Profile,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({}),
    },
    methods: {},
    setup() {
        return {
            modules: [Autoplay],
        };
    },
};
</script>

<template>
    <div class="header-wrapper row m-0">
        <Notification_Bar />
        <div
            class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0"
        >
            <!-- <swiper
                :slidesPerView="1"
                :autoplay="{ delay: 2500, disableOnInteraction: false }"
                :modules="modules"
                class="notification-slider"
                direction="vertical"
            >
                <swiper-slide class="h-100">
                    <div class="d-flex h-100">
                        <img src="@/assets/images/giftools.gif" alt="gif" />
                        <h6 class="mb-0 f-w-400">
                            <span class="font-primary">Don't Miss Out! </span
                            ><span class="f-light"
                                >Out new update has been release.</span
                            >
                        </h6>
                        <i class="icon-arrow-top-right f-light"></i>
                    </div>
                </swiper-slide>
                <swiper-slide class="h-100">
                    <div class="d-flex h-100">
                        <img src="@/assets/images/giftools.gif" alt="gif" />
                        <h6 class="mb-0 f-w-400">
                            <span class="f-light"
                                >Something you love is now on sale!
                            </span>
                        </h6>
                        <a
                            class="ms-1"
                            href="https://1.envato.market/3GVzd"
                            target="_blank"
                            >Buy now !</a
                        >
                    </div>
                </swiper-slide>
            </swiper> -->
        </div>
        <div
            class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
        >
            <ul class="nav-menus">
                <!-- <li class="language-nav">
                    <Language />
                </li>
                <li>
                    <Mode />
                </li>
                <li>
                    <Setting />
                </li>
                <Notifications /> -->
                <Profile />
            </ul>
        </div>
    </div>
</template>
