<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}
th,
td {
    min-width: 200px;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>Scheduler</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <button
                                @click="clearModalEvent()"
                                data-bs-toggle="modal"
                                data-bs-target="#addEvent"
                                class="p-2"
                                style="
                                    border-radius: 5px;
                                    background-color: #04318c;
                                    color: #fefefe;
                                    width: 44%;
                                    border: none;
                                    display: flex;
                                    justify-content: space-evenly;
                                    align-items: center;
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 10%"
                                    fill="currentColor"
                                    class="bi bi-plus-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                    />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                    />
                                </svg>
                                Add Event
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="this.tableloader === false">
                    <div class="card" v-if="this.clientTableData">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div
                                class="col-2 d-flex"
                                style="
                                    font-size: 1rem;
                                    font-weight: 500;
                                    align-items: center;
                                "
                            >
                                <span>List of Events</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input
                                        class="form-control"
                                        v-model="inputText"
                                        @input="FilterHandling(inputText)"
                                        type="text"
                                        placeholder="Search.."
                                    /><vue-feather
                                        class="search-icon"
                                        type="search"
                                    ></vue-feather>
                                </div>
                            </div>
                            <!-- <div
                                    class="col-1 d-flex"
                                    style="cursor: pointer; align-items: center"
                                >
                                    <div
                                        style="
                                            font-size: 1rem;
                                            font-weight: 450;
                                            padding-right: 10px;
                                        "
                                    >
                                        Filter
                                    </div>
                                    <div style="position: relative; top: 2px">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            fill="#7366FF"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                            />
                                        </svg>
                                    </div>
                                </div> -->
                        </div>

                        <!-- FILTER TAB STATUS -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '',
                                        }"
                                        @click="changeTab('')"
                                    >
                                        All
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '0',
                                        }"
                                        @click="changeTab('0')"
                                    >
                                        Seminar
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '1',
                                        }"
                                        @click="changeTab('1')"
                                    >
                                        Class
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '2',
                                        }"
                                        @click="changeTab('2')"
                                    >
                                        One On One
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '3',
                                        }"
                                        @click="changeTab('3')"
                                    >
                                        Event
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab === '4',
                                        }"
                                        @click="changeTab('4')"
                                    >
                                        Marketing Planning
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- DATA Table -->
                        <div
                            v-if="this.clientTableDataResult"
                            class="search-table-outter wrapper p-4"
                        >
                            <table
                                class="search-table inner"
                                style="width: 100%"
                            >
                                <tr>
                                    <th style="width: 5%">#</th>
                                    <th style="width: 10%">Date Start</th>
                                    <th style="width: 10%">Date End</th>
                                    <th style="width: 15%">Speaker</th>
                                    <th style="width: 15%">Title</th>
                                    <th style="width: 10%">Location</th>
                                    <th style="width: 15%">Details</th>
                                    <th style="width: 10%">Tags</th>
                                    <th style="width: 10%">Action</th>
                                </tr>
                                <tr style="height: 60px" v-for="(item, index) in this.clientTableDataResult" :key="index">
                                    <td>{{ index + 1 }}.</td>
                                    <td> {{ item.event_time !== null ? formatDateTime( item.event_time_start ) : "-" }} </td>
                                    <td> {{ item.event_time !== "" ? formatDateTime( item.event_time_end ) : "-" }} </td>
                                    <td>
                                        {{
                                            (result = this.speakerAvailable.find(
                                                (item1) => item1.usr_main_id === item.speaker_id
                                            ))
                                                ? result.first_name + " " + result.last_name : "-"
                                        }}
                                    </td>
                                    <td> {{ item.event_title !== null ? item.event_title : "-" }} </td>
                                    <td> {{ item.event_location !== null ? item.event_location : "-" }} </td>
                                    <td> {{ item.event_detail !== null ? item.event_detail : "-" }} </td>
                                    <td>
                                        {{
                                            item.schedule_status !== null
                                                ? item.schedule_status == 0
                                                    ? "Seminar"
                                                    : item.schedule_status == 1
                                                    ? "Class"
                                                    : item.schedule_status == 2
                                                    ? "One on One"
                                                    : item.schedule_status == 3
                                                    ? "Event"
                                                    : item.schedule_status == 4
                                                    ? "Marketing Planning"
                                                    : "-"
                                                : "-"
                                        }}
                                    </td>
                                    <td style="min-width: 100px">
                                        <div style=" display: flex; justify-content: center; " >
                                            <div style="cursor: pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg"  @click=" intializeEditEvent(item) " width="20" height="20" fill="#7366FF" class="bi bi-pen-fill" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#addEvent" >
                                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                                </svg>
                                            </div>
                                            <div style="width: 20px"></div>
                                            <div class="deleteYearCard" style="cursor: pointer" >
                                                <svg xmlns="http://www.w3.org/2000/svg" @click=" deleteScheduler(item.scheduler_id ) " width="20" height="20" fill="#7366FF" class="bi bi-trash-fill" viewBox="0 0 16 16" >
                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- EMPTY DATA -->
                        <div v-else align="center" style="height: 300px">
                            <img
                                style="
                                    border-radius: 5px;
                                    width: 240px;
                                    height: 220px;
                                "
                                alt=""
                                src="../../assets/images/no_data_image.png"
                            />
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div class="inactivePage" style="padding-left: 30px">{{ "Page " + this.currentPage + " out of " + this.maxPage }}</div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div class="d-flex" style=" padding-right: 30px; justify-content: flex-end; " >
                                    <button
                                        class="p-1"
                                        :class="{ activeButtonPage: this.currentPage != '1', inactiveButtonPage: this.currentPage == '1', }" @click="prevPage()"
                                        style=" width: 30%; border-top-left-radius: 8px; border-bottom-left-radius: 8px; border: 1px solid #dee2e6; " >
                                        Previous
                                    </button>
                                    <div @click="setFirstPage()" v-if="this.currentPage != 1" class="inactivePage p-1" align="center" style=" cursor: pointer; width: 10%;  border: 1px solid #dee2e6; " >
                                        ...
                                    </div>
                                    <div
                                        @click="goToPage(pageNumber)"
                                        v-for="pageNumber in limitedPages"
                                        :key="pageNumber"
                                        :class="{
                                            activePage:
                                                currentPage === pageNumber,
                                            inactivePage:
                                                currentPage !== pageNumber,
                                        }"
                                        class="p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        {{ pageNumber }}
                                    </div>
                                    <div
                                        @click="setLastPage()"
                                        v-if="this.currentPage != this.maxPage"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                currentPage === '1' &&
                                                currentPage !== this.maxPage,
                                            inactiveButtonPage:
                                                currentPage === this.maxPage,
                                        }"
                                        @click="nextPage()"
                                        style="
                                            width: 30%;
                                            border-top-right-radius: 8px;
                                            border-bottom-right-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- EMPTY DATA DISPLAY -->
                    <div v-else align="center" style="height: 300px">
                        <img
                            style="
                                border-radius: 5px;
                                width: 240px;
                                height: 220px;
                            "
                            alt=""
                            src="../../assets/images/no_data_image.png"
                        />
                        <h6>No Data</h6>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- POPUP ADD SCHEDULER -->
                <div
                    class="modal fade modal-bookmark"
                    id="addEvent"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title">
                                    <span v-if="this.schedulerId === null"
                                        >Add</span
                                    ><span v-else>Update</span> Event
                                </h3>
                                <button
                                    class="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div
                                style="
                                    max-height: 60vh;
                                    overflow: scroll;
                                    overflow-x: hidden;
                                "
                            >
                                <div class="modal-body row">
                                    <!-- FILTERING TAB POPUP-->
                                    <div
                                        class="col-sm-12 row"
                                        v-if="!this.schedulerId"
                                    >
                                        <div
                                            style="cursor: pointer"
                                            class="p-3 col-sm-2"
                                            align="center"
                                            :class="{
                                                selected: selectedTab2 === '0',
                                            }"
                                            @click="changeTab2('0')"
                                        >
                                            Seminar
                                        </div>
                                        <div
                                            style="cursor: pointer"
                                            class="p-3 col-sm-2"
                                            align="center"
                                            :class="{
                                                selected: selectedTab2 === '1',
                                            }"
                                            @click="changeTab2('1')"
                                        >
                                            Class
                                        </div>
                                        <div
                                            style="cursor: pointer"
                                            class="p-3 col-sm-2"
                                            align="center"
                                            :class="{
                                                selected: selectedTab2 === '2',
                                            }"
                                            @click="changeTab2('2')"
                                        >
                                            One On One
                                        </div>
                                        <div
                                            style="cursor: pointer"
                                            class="p-3 col-sm-2"
                                            align="center"
                                            :class="{
                                                selected: selectedTab2 === '3',
                                            }"
                                            @click="changeTab2('3')"
                                        >
                                            Event
                                        </div>
                                        <div
                                            style="cursor: pointer"
                                            class="p-3 col-sm-2"
                                            align="center"
                                            :class="{
                                                selected: selectedTab2 === '4',
                                            }"
                                            @click="changeTab2('4')"
                                        >
                                            Marketing Planning
                                        </div>
                                    </div>
                                    <div v-else class="p-3 col-sm-2 selected" align="center">
                                        {{ this.selectedTab2 !== null
                                                ? this.selectedTab2 == 0
                                                    ? "Seminar"
                                                    : this.selectedTab2 == 1
                                                    ? "Class"
                                                    : this.selectedTab2 == 2
                                                    ? "One on One"
                                                    : this.selectedTab2 == 3
                                                    ? "Event"
                                                    : this.selectedTab2 == 4
                                                    ? "Marketing Planning"
                                                    : "-"
                                                : "-"
                                        }}
                                    </div>
                                    <div style="height: 10px"></div>
                                    <div class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450"> Date Start </div>
                                        <input v-model="this.schedulerDateStart" class="form-control" type="datetime-local" placeholder="01/06/2024" />
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Date End</div>
                                        <input v-model="this.schedulerDateEnd" class="form-control" type="datetime-local" placeholder="01/06/2024" />
                                    </div>
                                    <div v-if="this.selectedTab2 == '2'" class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450">Client</div>

                                        <select v-model="this.schedulerClient" class="form-select form-control-primary" name="select" required >
                                            <option value="" disabled selected required >Select Client</option>
                                            <option v-for="item in this.clientAvailable" :key="item.client_id" :value="item.client_id" >{{ item.full_name }}</option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem" >
                                        <div style="font-weight: 450">Title</div>
                                        <input v-model="this.schedulerTitle" class="form-control" type="text" placeholder="Insert Title" />
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">
                                            <span v-if="this.selectedTab2 == '4'">Talent</span><span v-else>Speaker</span>
                                        </div>

                                        <select v-model="this.schedulerSpeaker" class="form-select form-control-primary" name="select" required>
                                            <option value="" disabled selected required >
                                                Select
                                                <span v-if=" this.selectedTab2 == '4' " >Talent</span><span v-else>Speaker</span>
                                            </option>
                                            <option v-for="item in this.speakerAvailable" :key="item.usr_main_id" :value="item.usr_main_id" >
                                                {{ item.first_name + " " + item.last_name + "(" + getStatusLabel(item.usr_status) + ")" }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="py-2 col-12" style="font-size: 1rem">
                                        <div style="font-weight: 450">Location</div>
                                        <input v-model="this.schedulerLocation"  class="form-control" type="text" placeholder="Dewan Gemilang Taman Pasuk"/>
                                    </div>
                                    <div
                                        v-if="this.selectedTab2 != '4'"
                                        class="py-2 col-12"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Details
                                        </div>
                                        <textarea
                                            v-model="this.schedulerDetail"
                                            class="form-control"
                                            type="text"
                                            placeholder="Insert Details"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div
                                    class="col-12 d-flex"
                                    style="justify-content: flex-end"
                                >
                                    <div v-if="this.schedulerId !== null">
                                        <button
                                            @click="
                                                deleteScheduler(
                                                    this.schedulerId
                                                )
                                            "
                                            data-bs-dismiss="modal"
                                            class="btn btn-danger"
                                            type="button"
                                            style="width: 100%"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div style="padding-right: 20px"></div>
                                    <div>
                                        <button
                                            @click="saveSchedulerData()"
                                            data-bs-dismiss="modal"
                                            class="btn btn-color"
                                            type="button"
                                            style="
                                                width: 100%;
                                                color: #fefefe;
                                                background-color: #04318c !important;
                                                border-color: #04318c !important;
                                            "
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    listScheduler,
    schedulerAdd,
    schedulerEdit,
    schedulerDelete,
    listClient,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setting Scheduler",

    components: {},

    data() {
        return {
            searchText: "",
            selectedTab: "",
            selectedTab2: "0",
            clientTableData: null,
            clientTableDataResult: null,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,
            speakerAvailable: null,
            clientAvailable: null,
            schedulerClient: "",

            schedulerId: null,
            schedulerDetail: null,
            schedulerLocation: null,
            schedulerSpeaker: "",
            schedulerTitle: null,
            schedulerBrand: null,
            schedulerName: null,
            schedulerDateStart: null,
            schedulerDateEnd: null,
        };
    },

    beforeMount() {
        this.loadListScheduler();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.clientTableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        getStatusLabel(status) {
            switch (status) {
                case '0':
                    return 'New';
                case '1':
                    return 'Active';
                case '2':
                    return 'Inactive';
                case '3':
                    return 'Suspended';
                default:
                    return 'Unknown';
            }
        },

        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            this.FilterHandling();
        },

        //FILTER CHANGE TAB
        changeTab2(tabProgressName) {
            this.selectedTab2 = tabProgressName;
        },

        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.schedule_status === this.selectedTab
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API
                this.clientTableDataResult = this.clientTableData;
            }

            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.clientTableDataResult =
                        this.clientTableDataResult.filter(
                            (item) =>
                                (item.event_location &&
                                    item.event_location
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.event_detail &&
                                    item.event_detail
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.event_title &&
                                    item.event_title
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab != "") {
                this.clientTableDataResult = this.clientTableData.filter(
                    (item) => item.schedule_status === this.selectedTab
                );
            } else {
                this.clientTableDataResult = this.clientTableData;
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.clientTableDataResult = this.clientTableDataResult.filter(
                    (item) =>
                        (item.event_location &&
                            item.event_location
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.event_detail &&
                            item.event_detail
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.event_title &&
                            item.event_title.toLowerCase().includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.clientTableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult.length > 0) {
                this.clientTableDataResult = this.clientTableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.clientTableDataResult = null;
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        formatDateTime(dateString) {
            // Parse the date string
            var date = new Date(dateString);

            // Extract day, month, year, and time components
            var day = date.getDate();
            var month = date.getMonth() + 1; // Month is zero-based, so we add 1
            var year = date.getFullYear();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();

            // Format month and day with leading zeros if necessary
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;

            // Format the time with leading zeros if necessary
            var time = `${hours}:${minutes}:${seconds}`;

            // Output the result
            return `${day}-${month}-${year} ${time}`;
        },

        clearModalEvent() {
            this.schedulerId = null;
            this.schedulerDetail = null;
            this.schedulerLocation = null;
            this.schedulerSpeaker = "";
            this.schedulerClient = "";
            this.schedulerTitle = null;
            this.schedulerBrand = null;
            this.schedulerName = null;
            this.selectedTab2 = "0";
            this.schedulerDateStart = null;
            this.schedulerDateEnd = null;
        },

        intializeEditEvent(dataTable) {
            console.log(dataTable);
            this.schedulerId = dataTable.scheduler_id;
            this.selectedTab2 = dataTable.schedule_status;
            this.schedulerDetail = dataTable.event_detail;
            this.schedulerLocation = dataTable.event_location;
            this.schedulerSpeaker = dataTable.speaker_id;
            this.schedulerTitle = dataTable.event_title;
            this.schedulerClient = dataTable.client_id;
            this.schedulerDateStart = dataTable.event_time_start;
            this.schedulerDateEnd = dataTable.event_time_end;
        },

        async loadListScheduler() {
            this.tableloader = true;

            try {
                await listClient().then((response) => {
                    this.clientAvailable = response.data.data;
                    console.log(this.clientAvailable);
                });

                await listScheduler().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData = response.data.data;
                        this.speakerAvailable = response.data.speaker;
                        this.speakerAvailable = response.data.speaker.filter(
                            (item) => item.usr_status != '4'
                        );
                        this.tableloader = false;
                        this.currentPage = 1;
                        this.PaginationHandling();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async saveSchedulerData() {
            const dataPass = {
                schedulerId: this.schedulerId,
                eventDetail: this.schedulerDetail,
                eventLocation: this.schedulerLocation,
                speakerId: this.schedulerSpeaker,
                eventTitle: this.schedulerTitle,
                schedulerBrand: this.schedulerBrand,
                schedulerName: this.schedulerName,
                scheduleStatus: this.selectedTab2,
                eventTimeStart: this.schedulerDateStart,
                eventTimeEnd: this.schedulerDateEnd,
            };

            if (this.schedulerId === null) {
                try {
                    await schedulerAdd(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalEvent();
                            this.loadListScheduler();
                            this.notificationSuccess("Data Added!");
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await schedulerEdit(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalEvent();
                            this.loadListScheduler();
                            this.notificationSuccess("Data Saved!");
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },

        async deleteScheduler(id) {
            const dataPass = {
                schedulerId: id,
            };

            try {
                await schedulerDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalEvent();
                        this.loadListScheduler();
                        this.notificationSuccess("Data Deleted!");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
