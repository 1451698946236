<script>
export default {
    name: "Setting",
    data() {
        return {};
    },
    methods: {},
};
</script>

<template>
    <router-link :to="'/users/profile'">
        <i class="icon-settings"></i>
    </router-link>
</template>

<style scoped>
a {
    color: #242934;
}
.icon-settings {
    cursor: pointer;
    padding: auto;
    margin: auto;
    font-size: 19px;
}
</style>
