<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table{
    table-layout: fixed; margin:0px auto 0px auto; 
}
.search-table, td, th{
    border-collapse:collapse;
}
th {
    padding:10px 7px; 
    font-size:15px; 
    color:#52526C; 
    background:#F5F6F9;
}
td {
    padding:5px 10px; 
    height:35px;
}

.search-table-outter { 
    overflow-x: scroll; 
}
th, td {
    min-width: 200px;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <!-- Top Title and button add user -->
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>Permission</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <button
                                data-bs-toggle="modal"
                                data-bs-target="#addPermission"
                                class="p-2"
                                @click="clearModalPermission()"
                                style="
                                    border-radius: 5px;
                                    background-color: #04318c;
                                    color: #fefefe;
                                    width: 44%;
                                    border: none;
                                    display: flex;
                                    justify-content: space-evenly;
                                    align-items: center;
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 10%"
                                    fill="currentColor"
                                    class="bi bi-plus-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                    />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                    />
                                </svg>
                                Create Permission
                            </button>
                        </div>
                    </div>
                </div>

                <!-- TABLE DATA ROLE -->
                <div v-if="this.tableloader1 === false">
                    <div class="card" v-if="this.clientTableData1">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div
                                class="col-2 d-flex"
                                style="
                                    font-size: 1rem;
                                    font-weight: 500;
                                    align-items: center;
                                "
                            >
                                <span>List of Permission</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input
                                        class="form-control"
                                        v-model="inputText"
                                        @input="FilterHandling1(inputText)"
                                        type="text"
                                        placeholder="Search.."
                                    /><vue-feather
                                        class="search-icon"
                                        type="search"
                                    ></vue-feather>
                                </div>
                            </div>
                            <!-- <div
                                class="col-1 d-flex"
                                style="cursor: pointer; align-items: center"
                            >
                                <div
                                    style="
                                        font-size: 1rem;
                                        font-weight: 450;
                                        padding-right: 10px;
                                    "
                                >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#7366FF"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                        />
                                    </svg>
                                </div>
                            </div> -->
                        </div>

                        <!-- DATA Table -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab1 === '',
                                        }"
                                        @click="changeTab1('')"
                                    >
                                        All
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab1 === '0',
                                        }"
                                        @click="changeTab1('0')"
                                    >
                                        Active
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab1 === '1',
                                        }"
                                        @click="changeTab1('1')"
                                    >
                                        Suspended
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab1 === '2',
                                        }"
                                        @click="changeTab1('2')"
                                    >
                                        Deleted
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="search-table-outter wrapper p-4"
                            v-if="this.clientTableDataResult1"
                        >
                            <table class="search-table inner" style="width:100%;">
                                <tr>
                                    <th><div style="text-align: left">#</div></th>
                                    <th><div style="text-align: left;">Role Name</div></th>
                                    <th><div style="text-align: center;"> Permission</div></th>
                                    <th><div style="text-align: center">Status</div></th>
                                    <th><div style="text-align: center"> Action</div></th>
                                </tr>
                                <tr
                                    v-for="(item, index) in this
                                        .clientTableDataResult1"
                                    :key="index"
                                >
                                    <td>
                                        <div style="text-align: left">{{ (currentPage1 - 1) *itemsPerPage + index +1}}.</div>
                                    </td>
                                    <td>
                                        <div style="text-align: left">
                                            {{
                                                item.permission_name !== null
                                                    ? item.permission_name
                                                    : "-"
                                            }}
                                        </div>
                                    </td>
                                    <td style="min-width: 550px">
                                        <div
                                            style="
                                                cursor: pointer;
                                                text-align: center;
                                            "
                                            data-bs-toggle="modal"
                                            data-bs-target="#addPermission"
                                            class="p-2"
                                            @click="
                                                intializeEditPermission(
                                                    item,
                                                    false
                                                )
                                            "
                                        >
                                            view details
                                        </div>
                                    </td>
                                    <td style="min-width: 150px">
                                        <div
                                            style="
                                                display: flex;
                                                justify-content: center;
                                            "
                                        >
                                            <div
                                                v-if="
                                                    item.access_status === '0'
                                                "
                                                class="p-1"
                                                align="center"
                                                style="
                                                    width: 50%;
                                                    background-color: #15aa2c;
                                                    border-radius: 8px;
                                                    color: #fefefe;
                                                "
                                            >
                                                Active
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.access_status === '1'
                                                "
                                                class="p-1"
                                                align="center"
                                                style="
                                                    width: 50%;
                                                    background-color: #ffaa05;
                                                    border-radius: 8px;
                                                    color: #fefefe;
                                                "
                                            >
                                                Suspended
                                            </div>
                                            <div
                                                v-else-if="
                                                    item.access_status === '2'
                                                "
                                                class="p-1"
                                                align="center"
                                                style="
                                                    width: 50%;
                                                    background-color: #ff0000;
                                                    border-radius: 8px;
                                                    color: #fefefe;
                                                "
                                            >
                                                Deleted
                                            </div>
                                        </div>
                                    </td>
                                    <td style="min-width: 100px">
                                        <div
                                            style="
                                                display: flex;
                                                justify-content: center;
                                            "
                                        >
                                            <div style="cursor: pointer">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    @click="
                                                        intializeEditPermission(
                                                            item,
                                                            true
                                                        )
                                                    "
                                                    width="20"
                                                    height="20"
                                                    fill="#7366FF"
                                                    class="bi bi-pen-fill"
                                                    viewBox="0 0 16 16"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#addPermission"
                                                >
                                                    <path
                                                        d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"
                                                    />
                                                </svg>
                                            </div>
                                            <div style="width: 20px"></div>
                                            <div
                                                class="deleteYearCard"
                                                style="cursor: pointer"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    @click="
                                                        deletePermission(
                                                            item.id
                                                        )
                                                    "
                                                    width="20"
                                                    height="20"
                                                    fill="#7366FF"
                                                    class="bi bi-trash-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- EMPTY DATA -->
                        <div v-else align="center" style="height: 300px">
                            <img
                                style="
                                    border-radius: 5px;
                                    width: 240px;
                                    height: 220px;
                                "
                                alt=""
                                src="../../assets/images/no_data_image.png"
                            />
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage1 > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div
                                    class="inactivePage"
                                    style="padding-left: 30px"
                                >
                                    {{
                                        "Page " +
                                        this.currentPage1 +
                                        " out of " +
                                        this.maxPage1
                                    }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div
                                    class="d-flex"
                                    style="
                                        padding-right: 30px;
                                        justify-content: flex-end;
                                    "
                                >
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                this.currentPage1 != '1',
                                            inactiveButtonPage:
                                                this.currentPage1 == '1',
                                        }"
                                        @click="prevPage1()"
                                        style="
                                            width: 30%;
                                            border-top-left-radius: 8px;
                                            border-bottom-left-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Previous
                                    </button>
                                    <div
                                        @click="setFirstPage1()"
                                        v-if="this.currentPage1 != 1"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <div
                                        @click="goToPage1(pageNumber)"
                                        v-for="pageNumber in limitedPages1"
                                        :key="pageNumber"
                                        :class="{
                                            activePage:
                                                currentPage1 === pageNumber,
                                            inactivePage:
                                                currentPage1 !== pageNumber,
                                        }"
                                        class="p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        {{ pageNumber }}
                                    </div>
                                    <div
                                        @click="setLastPage1()"
                                        v-if="
                                            this.currentPage1 != this.maxPage1
                                        "
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                currentPage1 === '1' &&
                                                currentPage1 !== this.maxPage1,
                                            inactiveButtonPage:
                                                currentPage1 === this.maxPage1,
                                        }"
                                        @click="nextPage1()"
                                        style="
                                            width: 30%;
                                            border-top-right-radius: 8px;
                                            border-bottom-right-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- EMPTY DATA DISPLAY -->
                    <div v-else align="center" style="height: 300px">
                        <img
                            style="
                                border-radius: 5px;
                                width: 240px;
                                height: 220px;
                            "
                            alt=""
                            src="../../assets/images/no_data_image.png"
                        />
                        <h6>No Data</h6>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- Top Title and button add user -->
                <div class="card">
                    <div
                        class="p-4 d-flex"
                        style="justify-content: space-between"
                    >
                        <div
                            class="d-flex"
                            style="
                                width: 60%;
                                font-size: 1.2rem;
                                font-weight: 500;
                                align-items: center;
                            "
                        >
                            <span>User</span>
                        </div>
                        <div
                            class="d-flex"
                            style="
                                width: 40%;
                                justify-content: flex-end;
                                align-items: center;
                            "
                        >
                            <button
                                @click="clearModalUser()"
                                data-bs-toggle="modal"
                                data-bs-target="#addUser"
                                class="p-2"
                                style="
                                    border-radius: 5px;
                                    background-color: #04318c;
                                    color: #fefefe;
                                    width: 44%;
                                    border: none;
                                    display: flex;
                                    justify-content: space-evenly;
                                    align-items: center;
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="width: 10%"
                                    fill="currentColor"
                                    class="bi bi-plus-square"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                                    />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                                    />
                                </svg>
                                Add User
                            </button>
                        </div>
                    </div>
                </div>

                <!-- TABLE DATA USER -->
                <div v-if="this.tableloader2 === false">
                    <div class="card" v-if="this.clientTableData2">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div
                                class="col-2 d-flex"
                                style="
                                    font-size: 1rem;
                                    font-weight: 500;
                                    align-items: center;
                                "
                            >
                                <span>List of User</span>
                            </div>
                            <div class="col-9 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input
                                        class="form-control"
                                        v-model="inputText"
                                        @input="FilterHandling2(inputText)"
                                        type="text"
                                        placeholder="Search.."
                                    /><vue-feather
                                        class="search-icon"
                                        type="search"
                                    ></vue-feather>
                                </div>
                            </div>
                            <!-- <div
                                class="col-1 d-flex"
                                style="cursor: pointer; align-items: center"
                            >
                                <div
                                    style="
                                        font-size: 1rem;
                                        font-weight: 450;
                                        padding-right: 10px;
                                    "
                                >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#7366FF"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                        />
                                    </svg>
                                </div>
                            </div> -->
                        </div>

                        <!-- DATA Table -->
                        <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '',
                                        }"
                                        @click="changeTab2('')"
                                    >
                                        All
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '0',
                                        }"
                                        @click="changeTab2('0')"
                                    >
                                        New
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '1',
                                        }"
                                        @click="changeTab2('1')"
                                    >
                                        Active
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '2',
                                        }"
                                        @click="changeTab2('2')"
                                    >
                                        Inactive
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '3',
                                        }"
                                        @click="changeTab2('3')"
                                    >
                                        Suspended
                                    </div>
                                    <div
                                        style="cursor: pointer"
                                        class="p-3 col-sm-2"
                                        align="center"
                                        :class="{
                                            selected: selectedTab2 === '4',
                                        }"
                                        @click="changeTab2('4')"
                                    >
                                        Deleted
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="search-table-outter wrapper p-4"
                            v-if="this.clientTableDataResult2"
                        >
                            <table class="search-table inner" >
                                <tr>
                                    <th style="min-width:30px">#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Permission</th>
                                    <th>Email</th>
                                    <th>Birth Date</th>
                                    <th>No. Phone</th>
                                    <th><div style="text-align: center">Status</div></th>
                                    <th><div style="text-align: center"> Action</div></th>
                                </tr>
                                <tr v-for="(item, index) in this.clientTableDataResult2" :key="index" style="height: 60px">
                                    <td style="min-width:30px"><div style="text-align: left">{{ (currentPage2 - 1) *itemsPerPage + index +1}}.</div></td>
                                    <td>
                                        {{
                                            item.first_name !== null
                                                ? item.first_name
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.last_name !== null
                                                ? item.last_name
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            (result =
                                                this.permissionAvailable.find(
                                                    (item1) =>
                                                        item1.id ===
                                                        item.usr_access
                                                ))
                                                ? result.permission_name
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.usr_email !== ""
                                                ? item.usr_email
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.usr_birth !== '-0001-11-30' ? item.usr_birth.split("-").reverse().join("-") : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.usr_no_phone !== null
                                                ? item.usr_no_phone
                                                : "-"
                                        }}
                                    </td>
                                    <td style="min-width: 150px">
                                        <select :value="item.usr_status" class="form-select form-control-primary" name="select" @change="setStatus(item,$event.target.value)" >
                                            <option value="" disabled selected> Select Status </option>
                                            <option value="0">New</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                            <option value="3">Suspended</option>
                                            <option value="4">Deleted</option>
                                        </select>
                                    </td>
                                    <td style="min-width: 100px">
                                        <div
                                            style="
                                                display: flex;
                                                justify-content: center;
                                            "
                                        >
                                            <div style="cursor: pointer">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    @click="
                                                        intializeEditUser(item)
                                                    "
                                                    width="20"
                                                    height="20"
                                                    fill="#7366FF"
                                                    class="bi bi-pen-fill"
                                                    viewBox="0 0 16 16"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#addUser"
                                                >
                                                    <path
                                                        d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"
                                                    />
                                                </svg>
                                            </div>
                                            <div style="width: 20px"></div>
                                            <div
                                                class="deleteYearCard"
                                                style="cursor: pointer"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    @click="
                                                        deleteUser(
                                                            item.usr_main_id
                                                        )
                                                    "
                                                    width="20"
                                                    height="20"
                                                    fill="#7366FF"
                                                    class="bi bi-trash-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- EMPTY DATA -->
                        <div v-else align="center" style="height: 300px">
                            <img
                                style="
                                    border-radius: 5px;
                                    width: 240px;
                                    height: 220px;
                                "
                                alt=""
                                src="../../assets/images/no_data_image.png"
                            />
                            <h6>No Data</h6>
                        </div>

                        <!-- TABLE PAGINATION -->
                        <div class="row pagination" v-if="maxPage2 > 1">
                            <div class="col-md-7 col-sm-0 p-3">
                                <div
                                    class="inactivePage"
                                    style="padding-left: 30px"
                                >
                                    {{
                                        "Page " +
                                        this.currentPage2 +
                                        " out of " +
                                        this.maxPage2
                                    }}
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-12 p-3">
                                <div
                                    class="d-flex"
                                    style="
                                        padding-right: 30px;
                                        justify-content: flex-end;
                                    "
                                >
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                this.currentPage2 != '1',
                                            inactiveButtonPage:
                                                this.currentPage2 == '1',
                                        }"
                                        @click="prevPage2()"
                                        style="
                                            width: 30%;
                                            border-top-left-radius: 8px;
                                            border-bottom-left-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Previous
                                    </button>
                                    <div
                                        @click="setFirstPage2()"
                                        v-if="this.currentPage2 != 1"
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <div
                                        @click="goToPage2(pageNumber)"
                                        v-for="pageNumber in limitedPages2"
                                        :key="pageNumber"
                                        :class="{
                                            activePage:
                                                currentPage2 === pageNumber,
                                            inactivePage:
                                                currentPage2 !== pageNumber,
                                        }"
                                        class="p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        {{ pageNumber }}
                                    </div>
                                    <div
                                        @click="setLastPage2()"
                                        v-if="
                                            this.currentPage2 != this.maxPage2
                                        "
                                        class="inactivePage p-1"
                                        align="center"
                                        style="
                                            cursor: pointer;
                                            width: 10%;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        ...
                                    </div>
                                    <button
                                        class="p-1"
                                        :class="{
                                            activeButtonPage:
                                                currentPage2 === '1' &&
                                                currentPage2 !== this.maxPage2,
                                            inactiveButtonPage:
                                                currentPage2 === this.maxPage2,
                                        }"
                                        @click="nextPage2()"
                                        style="
                                            width: 30%;
                                            border-top-right-radius: 8px;
                                            border-bottom-right-radius: 8px;
                                            border: 1px solid #dee2e6;
                                        "
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- EMPTY DATA DISPLAY -->
                    <div v-else align="center" style="height: 300px">
                        <img
                            style="
                                border-radius: 5px;
                                width: 240px;
                                height: 220px;
                            "
                            alt=""
                            src="../../assets/images/no_data_image.png"
                        />
                        <h6>No Data</h6>
                    </div>
                </div>

                <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>

                <!-- modal user -->
                <div
                    class="modal fade modal-bookmark"
                    id="addUser"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <span
                                        v-if="
                                            this.permissionUserMainId === null
                                        "
                                        >Add</span
                                    ><span v-else-if="this.permissionUserMainId"
                                        >Update</span
                                    >
                                    User
                                </h5>
                                <button
                                    class="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div
                                style="
                                    max-height: 60vh;
                                    overflow: scroll;
                                    overflow-x: hidden;
                                "
                            >
                                <div class="modal-body row">
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            First Name
                                        </div>
                                        <input
                                            v-model="this.permissionFirstName"
                                            class="form-control"
                                            type="text"
                                            placeholder="Ahmad"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Last Name
                                        </div>
                                        <input
                                            v-model="this.permissionLastName"
                                            class="form-control"
                                            type="text"
                                            placeholder="Ishak"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-12"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Password
                                        </div>
                                        <input
                                            v-model="this.permissionPassword"
                                            class="form-control"
                                            type="password"
                                            placeholder="*******"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-12"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Email
                                        </div>
                                        <input
                                            v-model="this.permissionEmail"
                                            class="form-control"
                                            type="text"
                                            placeholder="youremail@gmail.com"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Birthday
                                        </div>
                                        <input
                                            v-model="this.permissionBrithday"
                                            class="datepicker-here form-control digits"
                                            type="date"
                                            data-language="en"
                                            placeholder="Date"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            No. Phone
                                        </div>
                                        <input
                                            v-model="
                                                this.permissionContactNumber
                                            "
                                            class="form-control"
                                            type="text"
                                            placeholder="0148876776"
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Status
                                        </div>
                                        <select
                                            v-model="this.permissionUserStatus"
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                        >
                                            <option value="" disabled selected>
                                                Select Status
                                            </option>
                                            <option value="0">New</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                            <option value="3">Suspended</option>
                                            <option value="4">Deleted</option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Permission
                                        </div>
                                        <select
                                            v-model="
                                                this.permissionUserPermission
                                            "
                                            class="form-select form-control-primary"
                                            name="select"
                                        >
                                            <option
                                                value=""
                                                disabled
                                                selected
                                                required
                                            >
                                                Select Permission
                                            </option>
                                            <option
                                                v-for="item in this.permissionAvailable"
                                                :key="item.id"
                                                :value="item.id"
                                            >
                                                {{ item.permission_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div
                                    class="col-12 d-flex"
                                    style="justify-content: flex-end"
                                >
                                    <div
                                        v-if="
                                            this.permissionUserMainId !== null
                                        "
                                    >
                                        <button
                                            @click="
                                                deleteUser(
                                                    this.permissionUserMainId
                                                )
                                            "
                                            data-bs-dismiss="modal"
                                            class="btn btn-danger"
                                            type="button"
                                            style="width: 100%"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div style="padding-right: 20px"></div>
                                    <div>
                                        <button
                                            @click="saveUserData()"
                                            data-bs-dismiss="modal"
                                            class="btn btn-color"
                                            type="button"
                                            style="
                                                width: 100%;
                                                color: #fefefe;
                                                background-color: #04318c !important;
                                                border-color: #04318c !important;
                                            "
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modal Permission -->
                <div
                    class="modal fade modal-bookmark"
                    id="addPermission"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <span v-if="this.permissionId === null"
                                        >Create</span
                                    ><span v-else-if="this.editPermissionModal"
                                        >Edit</span
                                    >
                                    Permission
                                </h5>
                                <button
                                    class="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div
                                style="
                                    max-height: 60vh;
                                    overflow: scroll;
                                    overflow-x: hidden;
                                "
                            >
                                <div class="p-4 row">
                                    <div
                                        class="py-2 col-md-7 col-sm-12"
                                        style="font-size: 0.8rem"
                                    >
                                        <div style="font-weight: 450">
                                            Permission Name
                                        </div>
                                        <input
                                            v-model="this.permissionName"
                                            class="form-control py-2"
                                            type="text"
                                            placeholder="Sales"
                                            :disabled="
                                                !this.editPermissionModal
                                            "
                                        />
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Status
                                        </div>
                                        <select
                                            v-model="this.permissionStatus"
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                            :disabled="
                                                !this.editPermissionModal
                                            "
                                        >
                                            <option value="" disabled selected>
                                                Select Status
                                            </option>
                                            <option value="0">Active</option>
                                            <option value="1">Suspended</option>
                                            <option value="2">Deleted</option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Trainer Capability
                                        </div>
                                        <select
                                            v-model="
                                                this.permissionTrainerStatus
                                            "
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                            :disabled="
                                                !this.editPermissionModal
                                            "
                                        >
                                            <option value="" disabled selected>
                                                Select Status
                                            </option>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>
                                    <div
                                        class="py-2 col-6"
                                        style="font-size: 1rem"
                                    >
                                        <div style="font-weight: 450">
                                            Sales Capability
                                        </div>
                                        <select
                                            v-model="
                                                this.permissionSellerStatus
                                            "
                                            class="form-select form-control-primary"
                                            name="select"
                                            required
                                            :disabled="
                                                !this.editPermissionModal
                                            "
                                        >
                                            <option value="" disabled selected>
                                                Select Status
                                            </option>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>
                                    <div class="p-3"></div>
                                    <div class="col-md-12">
                                        <!-- Table Title column -->
                                        <div
                                            class="card p-3"
                                            style="
                                                background-color: #e4e5e7;
                                                border-radius: 8px;
                                                margin-bottom: 0px;
                                            "
                                        >
                                            <div
                                                class="row"
                                                style="
                                                    color: #52526c;
                                                    font-weight: 500;
                                                "
                                            >
                                                <div class="col-6">Module</div>
                                                <div
                                                    class="col-6 d-flex"
                                                    style="
                                                        justify-content: space-evenly;
                                                    "
                                                >
                                                    <div>View</div>
                                                    <div>Add</div>
                                                    <div>Edit</div>
                                                    <div>Delete</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Table Data -->
                                        <div
                                            v-for="(item1, index1) in this
                                                .moduleAvailable"
                                            :key="index1"
                                        >
                                            <div
                                                style="
                                                    padding: 30px 0px 10px 16px;
                                                "
                                            >
                                                <div
                                                    style="
                                                        color: #52526c;
                                                        font-weight: 600;
                                                    "
                                                >
                                                    <div>
                                                        <label
                                                            for="checkbox-role"
                                                        >
                                                            {{
                                                                index1 ===
                                                                "client-management"
                                                                    ? "Client Management"
                                                                    : index1 ===
                                                                      "preference"
                                                                    ? "Setting"
                                                                    : index1 ===
                                                                      "prospect-management"
                                                                    ? "Participant"
                                                                    : index1
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                      index1.slice(
                                                                          1
                                                                      )
                                                            }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style="
                                                    padding-bottom: 5px;
                                                    border-top: 1px solid
                                                        #c5c5c5;
                                                "
                                            ></div>

                                            <!-- List -->
                                            <div
                                                v-for="(item2, index2) in item1"
                                                :key="index2"
                                                class="row p-3"
                                                style="
                                                    color: #52526c;
                                                    font-weight: 400;
                                                "
                                            >
                                                <!-- title -->
                                                <div class="col-6">
                                                    {{ index2 }}
                                                </div>
                                                <!-- checkbox -->
                                                <div
                                                    class="col-6 d-flex"
                                                    style="
                                                        justify-content: space-around;
                                                    "
                                                >
                                                    <div
                                                        class="checkbox checkbox-silver"
                                                    >
                                                        <input
                                                            v-model="
                                                                this
                                                                    .permissionUserSetting[
                                                                    index1 +
                                                                        '/' +
                                                                        index2 +
                                                                        '/' +
                                                                        'view'
                                                                ]
                                                            "
                                                            class="checkbox-silver"
                                                            :id="
                                                                index1 +
                                                                index2 +
                                                                'view'
                                                            "
                                                            type="checkbox"
                                                            :disabled="
                                                                !item2.includes(
                                                                    'view'
                                                                ) ||
                                                                !this
                                                                    .editPermissionModal
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                index1 +
                                                                index2 +
                                                                'view'
                                                            "
                                                        ></label>
                                                    </div>
                                                    <div
                                                        class="checkbox checkbox-silver"
                                                    >
                                                        <input
                                                            v-model="
                                                                this
                                                                    .permissionUserSetting[
                                                                    index1 +
                                                                        '/' +
                                                                        index2 +
                                                                        '/' +
                                                                        'add'
                                                                ]
                                                            "
                                                            class="checkbox-silver"
                                                            :id="
                                                                index1 +
                                                                index2 +
                                                                'add'
                                                            "
                                                            type="checkbox"
                                                            :disabled="
                                                                !item2.includes(
                                                                    'add'
                                                                ) ||
                                                                !this
                                                                    .editPermissionModal
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                index1 +
                                                                index2 +
                                                                'add'
                                                            "
                                                        ></label>
                                                    </div>
                                                    <div
                                                        class="checkbox checkbox-silver"
                                                    >
                                                        <input
                                                            v-model="
                                                                this
                                                                    .permissionUserSetting[
                                                                    index1 +
                                                                        '/' +
                                                                        index2 +
                                                                        '/' +
                                                                        'edit'
                                                                ]
                                                            "
                                                            class="checkbox-silver"
                                                            :id="
                                                                index1 +
                                                                index2 +
                                                                'edit'
                                                            "
                                                            type="checkbox"
                                                            :disabled="
                                                                !item2.includes(
                                                                    'edit'
                                                                ) ||
                                                                !this
                                                                    .editPermissionModal
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                index1 +
                                                                index2 +
                                                                'edit'
                                                            "
                                                        ></label>
                                                    </div>
                                                    <div
                                                        class="checkbox checkbox-silver"
                                                    >
                                                        <input
                                                            v-model="
                                                                this
                                                                    .permissionUserSetting[
                                                                    index1 +
                                                                        '/' +
                                                                        index2 +
                                                                        '/' +
                                                                        'delete'
                                                                ]
                                                            "
                                                            class="checkbox-silver"
                                                            :id="
                                                                index1 +
                                                                index2 +
                                                                'delete'
                                                            "
                                                            type="checkbox"
                                                            :disabled="
                                                                !item2.includes(
                                                                    'delete'
                                                                ) ||
                                                                !this
                                                                    .editPermissionModal
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                index1 +
                                                                index2 +
                                                                'delete'
                                                            "
                                                        ></label>
                                                    </div>

                                                    <div
                                                        style="
                                                            border-bottom: 1px
                                                                solid #ebebeb;
                                                        "
                                                    ></div>
                                                </div>
                                                <!-- line -->
                                                <div
                                                    style="
                                                        padding-top: 16px;
                                                        border-bottom: 1px solid
                                                            #ebebeb;
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div
                                    class="col-12 d-flex"
                                    v-if="this.editPermissionModal"
                                    style="justify-content: flex-end"
                                >
                                    <div v-if="this.permissionId !== null">
                                        <button
                                            @click="
                                                deletePermission(
                                                    this.permissionId
                                                )
                                            "
                                            data-bs-dismiss="modal"
                                            class="btn btn-danger"
                                            type="button"
                                            style="width: 100%"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div style="padding-right: 20px"></div>
                                    <div>
                                        <button
                                            @click="savePermissionData()"
                                            data-bs-dismiss="modal"
                                            class="btn btn-color"
                                            type="button"
                                            style="
                                                width: 100%;
                                                color: #fefefe;
                                                background-color: #04318c !important;
                                                border-color: #04318c !important;
                                            "
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listPermission,
    permissionAdd,
    permissionEdit,
    permissionDelete,
    listUser,
    userAdd,
    userEdit,
    userDelete,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "User Permission",

    data() {
        return {
            itemsPerPage: 5,

            // permission
            clientTableDataResult1: null,
            tableloader1: false,
            currentPage1: 1,
            maxPage1: 1,
            selectedTab1: "",
            indexNumbering1: 1,
            clientTableData1: null,
            searchText1: "",

            moduleAvailable: {},
            permissionAvailable: [],
            permissionUserSetting: [],
            permissionName: "",
            editPermissionModal: false,
            permissionId: null,
            permissionStatus: "",
            permissionTrainerStatus: "",
            permissionSellerStatus: "",

            // user
            clientTableDataResult2: null,
            tableloader2: false,
            currentPage2: 1,
            maxPage2: 1,
            selectedTab2: "",
            indexNumbering2: 1,
            clientTableData2: null,
            searchText2: "",

            permissionUserMainId: null,
            permissionUserDetailId: null,
            permissionUserLoginId: null,
            permissionFirstName: null,
            permissionLastName: null,
            permissionPassword: null,
            permissionEmail: null,
            permissionBrithday: null,
            permissionContactNumber: null,
            permissionUserPermission: "",
            permissionUserStatus: "",
        };
    },

    beforeMount() {
        this.loadListPermission();
    },

    mounted() {},

    created() {},

    computed: {
        limitedPages1() {
            const totalPages = Math.ceil(
                this.clientTableData1.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage1 - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage1 >= threshold) {
                startPage = this.currentPage1 - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage1 + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
        limitedPages2() {
            const totalPages = Math.ceil(
                this.clientTableData2.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage2 - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage2 >= threshold) {
                startPage = this.currentPage2 - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage2 + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        // permission
        initializeArrayPermission() {
            for (let key1 in this.moduleAvailable) {
                let item1 = this.moduleAvailable[key1];
                for (let key2 in item1) {
                    let item2 = item1[key2];
                    item2.forEach((item3) => {
                        this.permissionUserSetting[
                            key1
                                .concat("/")
                                .concat(key2)
                                .concat("/")
                                .concat(item3)
                        ] = false;
                    });
                }
            }
        },

        clearModalPermission() {
            this.initializeArrayPermission();
            this.permissionName = "";
            this.editPermissionModal = true;
            this.permissionId = null;
            this.permissionStatus = "";
            this.permissionTrainerStatus = "";
            this.permissionSellerStatus = "";
        },

        intializeEditPermission(dataTable, edit) {
            this.editPermissionModal = edit;
            this.permissionId = dataTable.id;
            this.permissionStatus = dataTable.permission_status;
            this.permissionTrainerStatus = dataTable.trainer_status;
            this.permissionSellerStatus = dataTable.seller_status;
            this.initializeArrayPermission();
            this.permissionName = dataTable.permission_name;
            for (const [key, value] of Object.entries(
                JSON.parse(dataTable.access_privilege)
            )) {
                if (this.permissionUserSetting.hasOwnProperty(key)) {
                    this.permissionUserSetting[key] = value === "1";
                }
            }
        },

        async loadListPermission() {
            this.tableloader1 = true;
            this.tableloader2 = true;
            try {
                await listPermission().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData1 = response.data.data;
                        this.tableloader1 = false;
                        this.currentPage1 = 1;
                        this.PaginationHandling1();
                        this.FilterHandling1();

                        response.data.permission_module.forEach((item) => {
                            const { module, features, permission } = item;
                            if (!this.moduleAvailable[module]) {
                                this.moduleAvailable[module] = {};
                            }
                            if (!this.moduleAvailable[module][features]) {
                                this.moduleAvailable[module][features] = [];
                            }
                            this.moduleAvailable[module][features].push(
                                permission
                            );
                        });

                        this.permissionAvailable=[];

                        this.clientTableData1.forEach((item) => {
                            this.permissionAvailable.push({
                                id: item.id,
                                permission_name: item.permission_name,
                            });
                        });

                        this.initializeArrayPermission();
                        this.loadListUser();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async savePermissionData() {
            let constructPermissionJson = "{";
            for (const [key, value] of Object.entries(
                this.permissionUserSetting
            )) {
                const convertedValue = value ? 1 : 0;
                constructPermissionJson += `"${key}": "${convertedValue}",`;
            }
            if (constructPermissionJson.endsWith(",")) {
                constructPermissionJson = constructPermissionJson.slice(0, -1);
            }
            constructPermissionJson += "}";

            const dataPass = {
                permissionId: this.permissionId,
                permissionName: this.permissionName,
                permissionPrivilege: constructPermissionJson,
                permissionStatus: this.permissionStatus,
                permissionTrainerStatus: this.permissionTrainerStatus,
                permissionSellerStatus: this.permissionSellerStatus,
            };

            if (this.permissionId === null) {
                try {
                    await permissionAdd(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalPermission();
                            this.loadListPermission();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await permissionEdit(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalPermission();
                            this.loadListPermission();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },

        async deletePermission(id) {
            const dataPass = {
                permissionId: id,
            };

            try {
                await permissionDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalPermission();
                        this.loadListPermission();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        // user
        clearModalUser() {
            this.permissionUserMainId = null;
            this.permissionUserDetailId = null;
            this.permissionUserLoginId = null;
            this.permissionFirstName = null;
            this.permissionLastName = null;
            this.permissionPassword = null;
            this.permissionEmail = null;
            this.permissionBrithday = null;
            this.permissionContactNumber = null;
            this.permissionUserPermission = "";
            this.permissionUserStatus = "";
        },

        intializeEditUser(dataTable) {
            this.permissionUserMainId = dataTable.usr_main_id;
            this.permissionUserDetailId = dataTable.usr_detail_id;
            this.permissionUserLoginId = dataTable.usr_login_id;
            this.permissionFirstName = dataTable.first_name;
            this.permissionLastName = dataTable.last_name;
            this.permissionPassword = "";
            this.permissionEmail = dataTable.usr_email;
            this.permissionBrithday = dataTable.usr_birth;
            this.permissionContactNumber = dataTable.usr_no_phone;
            this.permissionUserPermission = dataTable.usr_access;
            this.permissionUserStatus = dataTable.usr_status;
        },

        async loadListUser() {
            try {
                await listUser().then((response) => {
                    if (response.data.message === "success") {
                        this.clientTableData2 = response.data.data;
                        this.tableloader2 = false;
                        this.currentPage2 = 1;
                        this.PaginationHandling2();
                        this.FilterHandling2();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async setStatus(userDetail,newStatus) {
            console.log(userDetail);
            const dataPass = {
                permissionUserMainId: userDetail.usr_main_id,
                permissionUserDetailId: userDetail.usr_detail_id,
                permissionUserLoginId: userDetail.usr_login_id,
                permissionFirstName: userDetail.first_name,
                permissionLastName: userDetail.last_name,
                permissionPassword: userDetail.permissionPassword,
                permissionEmail: userDetail.usr_email,
                permissionBrithday: userDetail.usr_birth,
                permissionContactNumber: userDetail.usr_no_phone,
                permissionUserPermission: userDetail.usr_access,
                permissionUserStatus: newStatus,
            };

            try {
                await userEdit(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalUser();
                        this.tableloader2 = true;
                        this.loadListUser();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async saveUserData() {
            const dataPass = {
                permissionUserMainId: this.permissionUserMainId,
                permissionUserDetailId: this.permissionUserDetailId,
                permissionUserLoginId: this.permissionUserLoginId,
                permissionFirstName: this.permissionFirstName,
                permissionLastName: this.permissionLastName,
                permissionPassword: this.permissionPassword,
                permissionEmail: this.permissionEmail,
                permissionBrithday: this.permissionBrithday,
                permissionContactNumber: this.permissionContactNumber,
                permissionUserPermission: this.permissionUserPermission,
                permissionUserStatus: this.permissionUserStatus,
            };

            if (this.permissionUserMainId === null) {
                try {
                    await userAdd(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalUser();
                            this.tableloader2 = true;
                            this.loadListUser();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else {
                try {
                    await userEdit(dataPass).then((response) => {
                        if (response.data.message === "success") {
                            this.clearModalUser();
                            this.tableloader2 = true;
                            this.loadListUser();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },

        async deleteUser(id) {
            const dataPass = {
                userId: id,
            };

            try {
                await userDelete(dataPass).then((response) => {
                    if (response.data.message === "success") {
                        this.clearModalPermission();
                        this.loadListPermission();
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //FILTER CHANGE TAB
        changeTab1(tabProgressName) {
            this.selectedTab1 = tabProgressName;
            this.FilterHandling1();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage1() {
            if (this.currentPage1 > 1) {
                this.currentPage1--;
                this.PageIndexNumber1();
                this.PaginationHandling1();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage1() {
            if (this.currentPage1 < this.maxPage1) {
                this.currentPage1++;
                this.PageIndexNumber1();
                this.PaginationHandling1();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage1() {
            this.currentPage1 = 1;
            this.PageIndexNumber1();
            this.PaginationHandling1();
        },

        // SEND TO LAST PAGE
        setLastPage1() {
            this.currentPage1 = this.maxPage1;
            this.PageIndexNumber1();
            this.PaginationHandling1();
        },

        goToPage1(page) {
            this.currentPage1 = page;
            this.PageIndexNumber1();
            this.PaginationHandling1();
        },

        PageIndexNumber1() {
            if (this.currentPage1 == 1) {
                this.indexNumbering1 = 1;
            } else {
                if (this.currentPage1 == 2) {
                    this.indexNumbering1 = this.itemsPerPage;
                } else {
                    this.indexNumbering1 =
                        (this.currentPage1 - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling1(text) {
            console.log(this.selectedTab1);
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab1 != "") {
                this.clientTableDataResult1 = this.clientTableData1.filter(
                    (item) => item.access_status === this.selectedTab1
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API EXCEPT DELETED
                this.clientTableDataResult1 = this.clientTableData1.filter(
                    (item) => item.access_status != '2'
                );
            }

            if (text != undefined) {
                this.searchText1 = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult1 !== null) {
                // Filter based on search text
                if (this.searchText1.trim() !== "") {
                    const searchText = this.searchText1.toLowerCase().trim();
                    this.clientTableDataResult1 =
                        this.clientTableDataResult1.filter(
                            (item) =>
                                item.permission_name &&
                                item.permission_name
                                    .toLowerCase()
                                    .includes(searchText)
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage1 = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage1 - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage1 = Math.ceil(
                this.clientTableDataResult1.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult1.length > 0) {
                this.clientTableDataResult1 = this.clientTableDataResult1.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult1 = null;
            }
        },

        PaginationHandling1() {
            // //FILTER THE SELECTED DATA
            if (this.selectedTab1 != "") {
                this.clientTableDataResult1 = this.clientTableData1.filter(
                    (item) => item.access_status === this.selectedTab1
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API EXCEPT DELETED
                this.clientTableDataResult1 = this.clientTableData1.filter(
                    (item) => item.access_status != '2'
                );
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText1 != "") {
                const searchText = this.searchText1.toLowerCase().trim();
                this.clientTableDataResult1 =
                    this.clientTableDataResult1.filter(
                        (item) =>
                            item.permission_name &&
                            item.permission_name
                                .toLowerCase()
                                .includes(searchText)
                    );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage1 - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage1 = Math.ceil(
                this.clientTableDataResult1.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult1.length > 0) {
                this.clientTableDataResult1 = this.clientTableDataResult1.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult1.forEach((dataObj) => {
                    if (this.currentPage1 > 1) {
                        this.indexNumbering1++;
                    }

                    dataObj.numbering = this.indexNumbering1;
                    if (this.currentPage1 == 1) {
                        this.indexNumbering1++;
                    }
                });
            } else {
                this.clientTableDataResult1 = null;
            }
        },

        //FILTER CHANGE TAB
        changeTab2(tabProgressName) {
            this.selectedTab2 = tabProgressName;
            this.FilterHandling2();
        },

        // PREVIOUS PAGE FUNCTION
        prevPage2() {
            if (this.currentPage2 > 1) {
                this.currentPage2--;
                this.PageIndexNumber2();
                this.PaginationHandling2();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage2() {
            if (this.currentPage2 < this.maxPage2) {
                this.currentPage2++;
                this.PageIndexNumber2();
                this.PaginationHandling2();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage2() {
            this.currentPage2 = 1;
            this.PageIndexNumber2();
            this.PaginationHandling2();
        },

        // SEND TO LAST PAGE
        setLastPage2() {
            this.currentPage2 = this.maxPage2;
            this.PageIndexNumber2();
            this.PaginationHandling2();
        },

        goToPage2(page) {
            this.currentPage2 = page;
            this.PageIndexNumber2();
            this.PaginationHandling2();
        },

        PageIndexNumber2() {
            if (this.currentPage2 == 1) {
                this.indexNumbering2 = 1;
            } else {
                if (this.currentPage2 == 2) {
                    this.indexNumbering2 = this.itemsPerPage;
                } else {
                    this.indexNumbering2 =
                        (this.currentPage2 - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling2(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            if (this.selectedTab2 != "") {
                this.clientTableDataResult2 = this.clientTableData2.filter(
                    (item) => item.usr_status === this.selectedTab2
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API EXCEPT DELETED
                this.clientTableDataResult2 = this.clientTableData2.filter(
                    (item) => item.usr_status != '4'
                );
            }

            if (text != undefined) {
                this.searchText2 = text;
            }

            // Filter based on search text
            if (this.clientTableDataResult2 !== null) {
                // Filter based on search text
                if (this.searchText2.trim() !== "") {
                    const searchText = this.searchText2.toLowerCase().trim();
                    this.clientTableDataResult2 =
                        this.clientTableDataResult2.filter(
                            (item) =>
                                item.permission_name &&
                                item.permission_name
                                    .toLowerCase()
                                    .includes(searchText)
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage2 = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage2 - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage2 = Math.ceil(
                this.clientTableDataResult2.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult2.length > 0) {
                this.clientTableDataResult2 = this.clientTableDataResult2.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.clientTableDataResult2 = null;
            }
        },

        PaginationHandling2() {
            //FILTER THE SELECTED DATA
            if (this.selectedTab2 != "") {
                this.clientTableDataResult2 = this.clientTableData2.filter(
                    (item) => item.access_status === this.selectedTab2
                );
            } else {
                //If FILTER ALL, FETCH ALL DATA FROM API EXCEPT DELETED
                this.clientTableDataResult2 = this.clientTableData2.filter(
                    (item) => item.usr_status != '4'
                );
            }

            // Apply text search filter if searchText is not empty
            if (this.searchText2 != "") {
                const searchText = this.searchText2.toLowerCase().trim();
                this.clientTableDataResult2 =
                    this.clientTableDataResult2.filter(
                        (item) =>
                            item.permission_name &&
                            item.permission_name
                                .toLowerCase()
                                .includes(searchText)
                    );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage2 - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage2 = Math.ceil(
                this.clientTableDataResult2.length / this.itemsPerPage
            );

            // Assign FINAL Value OF PAGE DATA
            if (this.clientTableDataResult2.length > 0) {
                this.clientTableDataResult2 = this.clientTableDataResult2.slice(
                    startIndex,
                    endIndex
                );
                this.clientTableDataResult2.forEach((dataObj) => {
                    if (this.currentPage2 > 1) {
                        this.indexNumbering2++;
                    }

                    dataObj.numbering = this.indexNumbering2;
                    if (this.currentPage2 == 1) {
                        this.indexNumbering2++;
                    }
                });
            } else {
                this.clientTableDataResult2 = null;
            }
        },
    },
};
</script>
